import {MenuItem, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

export const BasicInfoFieldsRenderer = ({fields}) => {
    const renderByType = ({textFieldProps, otherProps}) => ({
        select: () => (
            <TextField
                key={`select_${textFieldProps.name}`}
                variant="outlined"
                margin="dense"
                fullWidth
                {...textFieldProps}
            >
                {otherProps.options
                    ? otherProps.options
                        .map(x => (
                            <MenuItem
                                key={x.value}
                                value={x.value}
                            >
                                {x.title}
                            </MenuItem>
                        ))
                    : <MenuItem/>
                }
            </TextField>
        ),
        date: () => (
            <DatePicker
                key={`select_${textFieldProps.name}`}
                format={'D-MMM-YYYY'}
                maxDate={moment()}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        name: textFieldProps.name,
                        margin: 'dense'
                    },
                    field: {
                        readOnly: true
                    }
                }}
                {...{
                    ...textFieldProps,
                    onChange: value => textFieldProps.onChange({target: {value, name: textFieldProps.name}}, {})
                }}
            />
        ),
        text: () => (
            <TextField
                key={`textField_${textFieldProps.name}`}
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                    style: {overflow: 'visible'},
                }}
                {...textFieldProps}
            />
        )
    })[textFieldProps.type ?? 'text']();

    return <>
        {
            Object
                .values(fields)
                .map(renderByType)
        }
    </>;
};

BasicInfoFieldsRenderer.propTypes = {
    fields: PropTypes.object.isRequired
}