import {useDispatch, useSelector} from 'react-redux';
import {
    loadTablePreferences,
    persistTablePreferences,
    setColumnVisibility,
    setFilters,
    setPagination
} from '../../store/tableSlice';
import {useEffect} from 'react';

const useTableState = key => {
    const dispatch = useDispatch();
    const {pagination, filters, columnVisibility} = useSelector(state => state.table.tables[key]) ||
    {
        pagination: {
            pageIndex: 0,
            pageSize: 20
        },
        filters: {},
        columnVisibility: {}
    };

    useEffect(() => {
        const handleBeforeUnload = () => dispatch(persistTablePreferences(key));

        dispatch(loadTablePreferences(key));

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            handleBeforeUnload();
        };
    }, [dispatch]);

    const onPaginationChange = ({pageIndex, pageSize}) => {
        return dispatch(setPagination({
            key,
            pagination: {pageIndex, pageSize}
        }));
    };

    const onFiltersChange = filters => dispatch(setFilters({key, filters}));

    const onColumnVisibilityChange = columnVisibility => dispatch(setColumnVisibility({key, columnVisibility}));

    return {
        pagination,
        filters,
        columnVisibility,
        onPaginationChange,
        onFiltersChange,
        onColumnVisibilityChange,
    };
};

export default useTableState;
