import React, {useEffect, useRef} from 'react';
import FormRequest from './FormRequest';
import {useParams} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useSubmitPublicFormMutation} from '../../../../api/services/formRequestsService';
import {useGetPublicFormQuery} from '../../../../api/services/formsService';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

const SubmitPublicForm = ({navigateBackRoute}) => {
    const {formId} = useParams();
    const dispatch = useDispatch();
    const contentRef = useRef(null);
    const {data: form, isLoading, error} = useGetPublicFormQuery(formId);
    const [submitPublicForm, {
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useSubmitPublicFormMutation();

    useEffect(() => {
        if (isFormSubmitError) {
            dispatch(showSnackbar({
                message: submitError,
                severity: 'error'
            }));
        }
    }, [isFormSubmitError]);

    const sendHeight = () => {
        const height = Math.max(
            document.documentElement.scrollHeight,
            document.querySelector('.modal')?.scrollHeight ?? 0
        );

        window.parent.postMessage({
            type: 'iframeResizer',
            iframeHeight: height
        }, '*');
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(sendHeight);
        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const FormSubmittedSuccessfullyView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography>Form successfully submitted.</Typography>
        </Box>;

    const handleSubmit = async (data) =>
        await submitPublicForm({
            formId,
            data: JSON.stringify(data),
            isCreateApplicantForm: form.schema?.isCreateApplicantForm
        });

    return <div ref={contentRef}>
        {
            isFormSubmitSuccess
                ? <FormSubmittedSuccessfullyView sx={{color: 'theme.palette.success.main'}}/>
                : <FormRequest
                    publicForm
                    formRequest={form}
                    isLoading={isLoading}
                    error={error}
                    onSubmit={handleSubmit}
                    navigateBackRoute={navigateBackRoute}
                />
        }
    </div>;
};

SubmitPublicForm.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired
};

export default SubmitPublicForm;