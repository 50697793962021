import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Chip, FormControl, InputAdornment, InputLabel, Paper, Popover, styled} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AdmicityGroupFilterTree from './AdmicityGroupFilterTree';

const PaperStyled = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    '&:hover': {
        borderColor: theme.palette.grey[500],
    },
    '&.focused': {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
}));

const AdmicityGroupFilterSelect = ({data, value, sx, onChange}) => {
    const [focused, setFocused] = useState(false);
    const [popoverPosition, setPopoverPosition] = useState({top: 0, left: 0});
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [paths, setPaths] = useState([]);
    const parentRef = useRef(null);
    const anchorEl = useRef(null);

    const handleClick = () => {
        if (parentRef.current) {
            const {top, left, width} = parentRef.current.getBoundingClientRect();
            setPopoverPosition({top, left, width});
            setPopoverOpen(true);
            parentRef.current.focus();
            anchorEl.current = parentRef.current;
        }
    };

    const handleClose = () => {
        setPopoverOpen(false);
        parentRef.current && parentRef.current.focus();
    };

    const handleFilterClear = (e) => {
        e.stopPropagation();

        onChange('');
        setPaths([]);
    };

    const handleFocus = () => setFocused(true);

    const handleBlur = () => {
        if (!popoverOpen) {
            setFocused(false);
        }
    };

    return (
        <FormControl
            required
            sx={{
                width: '60%',
                ...sx
            }}
        >
            <InputLabel
                sx={{
                    backgroundColor: 'white',
                    marginTop: value ? 0 : 1
                }}
                shrink={value !== undefined && value !== ''}
                htmlFor="custom-chip-input"
            >
                Recipients
            </InputLabel>
            <PaperStyled
                ref={parentRef}
                className={focused ? 'focused' : ''}
                sx={{
                    paddingTop: value ? 2 : 4,
                    paddingBottom: value ? 2 : 4,
                    paddingLeft: 2,
                    paddingRight: 1
                }}
                variant={'outlined'}
                onClick={handleClick}
                onFocus={handleFocus}
                onBlur={handleBlur}
                tabIndex={0}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    {value && paths?.map((node, index) => (
                        <Chip
                            key={index}
                            label={node.name}
                            variant="outlined"
                        />
                    ))}
                </Box>
                <InputAdornment position="end">
                    <Tooltip title={'Clear filter'}>
                        <IconButton onClick={handleFilterClear}>
                            <SettingsBackupRestoreIcon
                                color="action"
                                sx={{fontSize: 24, cursor: 'pointer'}}
                            />
                        </IconButton>
                    </Tooltip>
                    {
                        popoverOpen
                            ? <ArrowDropUpIcon color="action" sx={{fontSize: 24, cursor: 'pointer'}}/>
                            : <ArrowDropDownIcon color="action" sx={{fontSize: 24, cursor: 'pointer'}}/>
                    }
                </InputAdornment>
            </PaperStyled>
            <Popover
                open={popoverOpen}
                anchorEl={anchorEl.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: popoverPosition.width,
                            maxHeight: 300
                        }
                    }
                }}
            >
                <AdmicityGroupFilterTree
                    data={data}
                    selected={value}
                    onChange={(nodeId, paths) => {
                        onChange(nodeId);
                        setPaths(paths ?? []);
                    }}

                />
            </Popover>
        </FormControl>
    );
};

AdmicityGroupFilterSelect.defaultProps = {
    sx: {}
};

AdmicityGroupFilterSelect.propTypes = {
    data: PropTypes.array,
    value: PropTypes.string,
    sx: PropTypes.object,
    onChange: PropTypes.func
};

export default AdmicityGroupFilterSelect;