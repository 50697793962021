const FORM_ACCESS_LEVELS = {
    public: 1,
    private: 2
};

const FORM_ACCESS_LEVELS_NAMES = {
    [FORM_ACCESS_LEVELS.public]: 'Public',
    [FORM_ACCESS_LEVELS.private]: 'Private'
};

export {
    FORM_ACCESS_LEVELS,
    FORM_ACCESS_LEVELS_NAMES
};