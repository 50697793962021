import React, {useRef, useState} from 'react';
import {Box, Button, LinearProgress, Menu, MenuItem, Stack, styled, TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import useDynamicHeight from '../utility/hooks/useDynamicHeight';
import {generateUUID} from '../utility/uuidUtil';
import {ENABLE_FILTERS} from '../pages/SchoolManagement/Setup/ContactInvites';
import AdmicityContextMenu from './AdmicityContextMenu';

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '.MuiDataGrid-row': {
        '&.disabled-row': {
            backgroundColor: '#f5f5f5',
            color: '#ccc',
            pointerEvents: 'none',
            '& .MuiCheckbox-root': {
                color: '#ccc',
            }
        },
    },
    '&.MuiDataGrid-root': {
        marginLeft: 0
    },
    width: '100%',
}));

const AdmicityTable = (
    {
        columns,
        data,
        totalItemCount,
        pageNumber: initialPageNumber,
        pageSize: initialPageSize,
        handlePaginationModelChange,
        handleRowSelection,
        handleRowClick,
        rowActions,
        toolbarActions,
        toolbarIconActions,
        noRowsOverlay,
        checkboxSelection,
        rowSelectionModel,
        shouldDisableRow,
        isLoading,
        sx,
        hideFooterPagination,
        handleSortModelChange,
        sortModel,
        sortingMode
    }) => {
    const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [pageNumber, setPageNumber] = useState(initialPageNumber ?? 0);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const parentRef = useRef(null);
    const gridHeight = useDynamicHeight(parentRef);

    const buildData = () => {
        if (columns.some(e => e.operation)) {
            const firstRow = {};
            columns.forEach(e => {
                if (e.operation) {
                    firstRow[e.field] = ENABLE_FILTERS
                    firstRow.id = generateUUID()
                }
            })
            return [firstRow, ...data]
        } else {
            return data;
        }
    }

    const handleCloseContextMenu = () => {
        setContextMenuAnchor(null);
    };

    const handleOpenContextMenu = (event, row) => {
        setSelectedRow(row);
        setContextMenuAnchor(event.currentTarget);
    };

    const onPaginationModelChange = ({page: newPage, pageSize: newPageSize}) => {
        setPageSize(newPageSize);
        setPageNumber(newPage);

        if (handlePaginationModelChange) {
            handlePaginationModelChange(newPage, newPageSize);
        }
    };

    const ToolbarActions = () =>
        toolbarActions?.length > 0 || toolbarIconActions?.length > 0
            ? <GridToolbarContainer
                sx={{
                    marginTop: 1,
                    marginLeft: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box display={'flex'} gap={1}>
                    {
                        toolbarActions?.map((
                            {
                                icon,
                                title,
                                onClick,
                                type,
                                value,
                                options,
                                handleChange,
                                disabled
                            }) => ({
                                button: () => <Button
                                    key={title}
                                    size="small"
                                    variant="outlined"
                                    startIcon={icon}
                                    onClick={onClick}
                                    disabled={disabled}
                                >
                                    {title}
                                </Button>,
                                select: () => <TextField
                                    select
                                    key={title}
                                    label={title}
                                    value={value}
                                    sx={{width: '150px'}}
                                    size="small"
                                    onChange={(event) => handleChange(event)}>
                                    {options.map(opt => <MenuItem
                                        value={opt.value}
                                        key={opt.title}>{opt.title}</MenuItem>)
                                    }
                                </TextField>
                            }[type]?.() || <></>
                        ))
                    }
                </Box>
                <Box>
                    {
                        toolbarIconActions?.map(({icon, title, onClick, menuItems, hideIfEmpty, type}) => ({
                            button: () => <IconButton key={title} onClick={onClick} title={title}>{icon}</IconButton>,
                            select: () => hideIfEmpty && !totalItemCount
                                ? null
                                : <AdmicityContextMenu title={title} menuItems={menuItems} icon={icon}/>
                        }[type]?.() || <></>))
                    }
                </Box>
            </GridToolbarContainer>
            : '';

    const NoRowsOverlay = () => {
        const getStyledIcon = (iconProp) => React.cloneElement(iconProp, {
            sx: {
                color: 'grey.A400',
                fontSize: 72,
                marginBottom: 1
            }
        });

        return <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            {
                noRowsOverlay === undefined || Object.keys(noRowsOverlay).length === 0
                    ? 'No rows'
                    : <>
                        {
                            noRowsOverlay.icon
                                ? getStyledIcon(noRowsOverlay.icon)
                                : ''
                        }
                        {noRowsOverlay?.text}
                    </>
            }
        </Stack>;
    };
    const getColumns = () =>
        rowActions?.length > 0
            ? [...columns, {
                field: '_rowAction',
                headerName: '',
                editable: false,
                sortable: false,
                renderCell: (params) => (
                    <IconButton
                        onClick={(e) => handleOpenContextMenu(e, params.row)}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                )
            }]
            : columns;

    const getRowClassName = (params) =>
        shouldDisableRow(params.row) ? 'disabled-row' : '';

    return (
        <Box
            ref={parentRef}
            flexGrow={1}
        >
            <DataGridStyled
                onSortModelChange={handleSortModelChange}
                sortModel={sortModel}
                sortingMode={sortingMode}
                sx={{
                    height: {
                        xs: gridHeight < 500 ? 500 : gridHeight,
                        sm: gridHeight < 600 ? 600 : gridHeight,
                        md: gridHeight < 600 ? 600 : gridHeight,
                        lg: gridHeight < 600 ? 600 : gridHeight,
                    },
                    ...sx,
                }}
                columns={getColumns()}
                rows={buildData()}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize,
                            page: pageNumber
                        },
                    },
                }}
                rowCount={totalItemCount}
                paginationMode={'server'}
                onPaginationModelChange={onPaginationModelChange}
                pageSizeOptions={[20, 50, 100]}
                checkboxSelection={checkboxSelection}
                onRowSelectionModelChange={handleRowSelection}
                rowSelectionModel={rowSelectionModel}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                autoHeight={false}
                loading={isLoading}
                onRowClick={handleRowClick}
                slots={{
                    toolbar: ToolbarActions,
                    noRowsOverlay: NoRowsOverlay,
                    loadingOverlay: LinearProgress,
                }}
                hideFooter={hideFooterPagination}
                getRowClassName={getRowClassName}
                isRowSelectable={(params) => Object.values(params.row).every(e => e !== ENABLE_FILTERS)}
            />
            {
                rowActions
                    ? <Menu
                        anchorEl={contextMenuAnchor}
                        keepMounted
                        open={Boolean(contextMenuAnchor)}
                        onClose={handleCloseContextMenu}
                    >
                        {rowActions.map((item, index) =>
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    item.action(selectedRow, pageSize, pageNumber);
                                    handleCloseContextMenu();
                                }}
                                disabled={item.disabled && item.disabled(selectedRow)}
                            >{item.label}
                            </MenuItem>)
                        }
                    </Menu>
                    : ''
            }
        </Box>
    );
};

AdmicityTable.defaultProps = {
    checkboxSelection: false,
    shouldDisableRow: () => false,
    sx: {}
};

AdmicityTable.propTypes = {
    options: PropTypes.array,
    columns: PropTypes.array,
    data: PropTypes.array,
    totalItemCount: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
    handlePaginationModelChange: PropTypes.func,
    handleRowSelection: PropTypes.func,
    handleRowClick: PropTypes.func,
    rowActions: PropTypes.array,
    toolbarActions: PropTypes.array,
    toolbarIconActions: PropTypes.array,
    noRowsOverlay: PropTypes.object,
    checkboxSelection: PropTypes.bool,
    rowSelectionModel: PropTypes.array,
    shouldDisableRow: PropTypes.func,
    isLoading: PropTypes.bool,
    sx: PropTypes.object,
    hideFooterPagination: PropTypes.bool,
    handleSortModelChange: PropTypes.func,
    sortModel: PropTypes.array,
    sortingMode: PropTypes.string
};

export default AdmicityTable;