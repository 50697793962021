import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {UploadFile} from '@mui/icons-material';
import {downloadFormDocument, useUploadFormDocumentsMutation} from '../../../../../api/services/filesService';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';

const Image = ({index, onChange, description, isEditMode, attachments, isFormArchived}) => {
    const [uploadFiles] = useUploadFormDocumentsMutation();
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(undefined)

    useEffect(() => {
        if (attachments && attachments.length) {
            downloadFormDocument({documentId: attachments[0].id}).then(r => {
                setPreviewImageUrl(URL.createObjectURL(r.blob))
            })
        }
        return () => URL.revokeObjectURL(previewImageUrl)
    }, [attachments]);

    const handleFilesUpload = async () => {
        setIsUploading(true);
        const uploadedFiles = await uploadFiles(selectedFiles);
        onChange({index, files: [uploadedFiles.data[0]]});
        handleDialogClose();
    };

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
    };

    const handleFilesSelect = (files) => {
        setSelectedFiles(files);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };
    return (
        <Box>
            {isEditMode
                ? <>
                    <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFile/>}
                        onClick={() => setOpenAddDocumentsDialog(true)}
                        disabled={isFormArchived}
                    >
                        Choose Image
                    </Button>

                    {previewImageUrl &&
                        <Box py={1} maxWidth={'100%'} display={'flex'} justifyContent={'center'}>
                            <Box component="img" src={previewImageUrl} sx={{maxWidth: '100%', height: 'auto'}}/>
                        </Box>
                    }

                    <TextField sx={{mt: 2}} fullWidth label={!description ? 'Write instructions for user' : null}
                               InputLabelProps={{
                                   shrink: false,
                               }}
                               disabled={isFormArchived}
                               multiline value={description}
                               onChange={e => onChange({index, description: e.target.value})}/>

                    <AdmicityDialog
                        handleClose={handleDialogClose}
                        title={'Add documents'}
                        open={openAddDocumentsDialog}
                        actions={[
                            {label: 'Cancel', onClick: handleDialogClose},
                            {
                                label: 'Upload',
                                onClick: handleFilesUpload,
                                disabled: (isUploading || selectedFiles.size === 0) || isUploadSizeExceedsLimit,
                                startIcon: isUploading ? <CircularProgress color="inherit" size={16}/> : undefined
                            }
                        ]}
                        maxWidth={'md'}
                    >
                        <Uploader
                            totalUploadSizeInMb={25}
                            onFilesSelect={handleFilesSelect}
                            onFileDelete={handleFileDeleteFromUploadContext}
                            onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                            value={selectedFiles}
                            readonly={isUploading}
                            singleFile={true}
                            fileTypes={{'image/*': []}}
                            allowedFileTypesLabel={'Allowed file types: Images'}
                        />
                    </AdmicityDialog>
                </>
                : <>
                    <Typography>{description}</Typography>
                    {previewImageUrl &&
                        <Box py={1} key={`file-${previewImageUrl}`} maxWidth={'100%'}>
                            <Box component="img" src={previewImageUrl} sx={{maxWidth: '100%', height: 'auto'}}/>
                        </Box>
                    }
                </>
            }
        </Box>
    );
};

Image.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    attachments: PropTypes.array
};

export default Image;