import { useState, useLayoutEffect } from 'react';

const debounce = (func, delay) => {
    let timer;
    return () => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => func(), delay);
    };
};

const useDynamicHeight = (parentRef) => {
    const [height, setHeight] = useState(0);

    const updateHeight = () => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setHeight(parentHeight);
        }
    };

    const debounceFunc = debounce(updateHeight, 200);

    useLayoutEffect(() => {

        updateHeight();
        window.addEventListener('resize', debounceFunc);

        return () => window.removeEventListener('resize', debounceFunc);
    }, [parentRef]);

    return height;
};

export default useDynamicHeight;
