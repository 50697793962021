import React, {useRef, useState} from 'react';
import {Box, Button, Divider, Drawer, Stack, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import AdmicityGroupFilterTree from './AdmicityGroupFilterTree';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Typography from '@mui/material/Typography';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import IconButton from '@mui/material/IconButton';
import useDynamicHeight from '../utility/hooks/useDynamicHeight';

const groupFiltersDrawerWidth = 350;

const AdmicityDrawer = ({filters, defaultSelected, onApply}) => {
    const theme = useTheme();
    const [openGroupFilters, setOpenGroupFilters] = useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [filterId, setFilterId] = useState(undefined);
    const parentRef = useRef(null);
    const freeSpaceHeight = useDynamicHeight(parentRef);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setOpenGroupFilters(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setOpenGroupFilters(!openGroupFilters);
        }
    };

    const handleFilterChange = (nodeId, paths) => {
        setFilterId(nodeId);
        onApply(nodeId, paths);
        handleDrawerClose();
    };

    return (
        <Box
            ref={parentRef}
            position="relative"
        >
            <Box sx={{
                display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                    lg: 'none',
                    xl: 'none',
                }
            }}>
                <div>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleDrawerToggle}
                        startIcon={<FilterListRoundedIcon/>}
                        sx={{marginRight: 2, marginBottom: 2}}
                    >
                        Group Filters
                    </Button>
                </div>
                <Drawer
                    variant="temporary"
                    open={openGroupFilters}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{keepMounted: true}}
                    sx={{
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'block',
                            lg: 'none',
                            xl: 'none'
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: {
                                xs: '100%',
                                sm: '70%',
                                md: '50%'
                            }
                        }
                    }}
                >
                    <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
                        <Stack
                            margin={2}
                            direction="row"
                            alignItems="center"
                        >
                            <IconButton onClick={() => handleDrawerClose()}>
                                <ArrowBackRoundedIcon/>
                            </IconButton>
                            <Typography marginLeft={1} color="text.secondary">Group Filters</Typography>
                        </Stack>
                        <Divider/>
                        <AdmicityGroupFilterTree
                            expandMinLevel={1}
                            data={filters}
                            selected={filterId || defaultSelected}
                            onChange={handleFilterChange}
                        />
                    </Box>
                    <Divider/>
                </Drawer>
            </Box>
            <Box sx={{
                width: groupFiltersDrawerWidth,
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                    xl: 'block',
                }
            }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none',
                            lg: 'block',
                            xl: 'block',
                        },
                        '& .MuiDrawer-paper': {
                            display: 'flex',
                            boxSizing: 'border-box',
                            flexDirection: 'column',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 1,
                            position: 'relative',
                            width: '100%',
                            maxHeight: `${freeSpaceHeight}px`
                        },
                    }}
                    open
                >
                    <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
                        <AdmicityGroupFilterTree
                            expandMinLevel={1}
                            data={filters}
                            selected={filterId || defaultSelected}
                            onChange={handleFilterChange}
                        />
                    </Box>
                    <Divider/>
                </Drawer>
            </Box>
        </Box>
    );
};

AdmicityDrawer.propTypes = {
    filters: PropTypes.array,
    onApply: PropTypes.func,
    defaultSelected: PropTypes.string
};

export default AdmicityDrawer;