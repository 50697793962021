import React, {useEffect} from 'react';
import FormRequest from './FormRequest';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useGetFormRequestQuery, useSubmitFormMutation} from '../../../../api/services/formRequestsService';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import useUser from '../../../../utility/hooks/useUser';
import PropTypes from 'prop-types';

const SubmitPrivateForm = ({navigateBackRoute}) => {
    const {user} = useUser();
    const {formRequestId} = useParams();
    const dispatch = useDispatch();
    const {
        data: formRequest,
        isLoading,
        isFetching,
        error,
        refetch: refetchFormRequest
    } = useGetFormRequestQuery({requestId: formRequestId, role: user.role});
    const [submitForm, {
        isLoading: isFormSubmitLoading,
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useSubmitFormMutation();

    useEffect(() => {
        if (isFormSubmitError || isFormSubmitSuccess) {
            if (isFormSubmitSuccess) {
                refetchFormRequest();
            }
            showNotification(isFormSubmitSuccess);
        }
    }, [isFormSubmitSuccess, isFormSubmitError]);

    const showNotification = success => success !== undefined && dispatch(
        {
            true: () => showSnackbar({
                message: `${formRequest.formName} submitted successfully`,
                severity: 'success'
            }),
            false: () => showSnackbar({
                message: submitError,
                severity: 'error'
            })
        }[success]()
    );

    const handleSubmit = async (data) =>
        await submitForm({
            id: formRequestId,
            data: JSON.stringify(data)
        });

    return <FormRequest
        formRequest={formRequest}
        isLoading={isLoading || isFetching || isFormSubmitLoading}
        error={error}
        onSubmit={handleSubmit}
        navigateBackRoute={navigateBackRoute}
    />;
};

SubmitPrivateForm.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired
};
export default SubmitPrivateForm;