import React from 'react';
import {Box, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';

const TextBox = ({index, id, value, onChange, description, isEditMode, enableValidation, isFormArchived}) => {
    return (
        <Box>
            {isEditMode
                ? <>
                    <TextField sx={{my: 1}} fullWidth value={description} multiline
                               onChange={e => onChange({index, description: e.target.value})}
                               label={!description ? 'Write a question for a detailed text response' : null}
                               disabled={isFormArchived}
                               InputLabelProps={{
                                   shrink: false,
                               }}/>
                    <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange} isFormArchived={isFormArchived}/>
                </>
                : <>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    <TextField fullWidth value={value} required={enableValidation} multiline
                               onChange={e => onChange({id, value: e.target.value})}/>
                </>
            }
        </Box>
    );
};

TextBox.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool
};

export default TextBox;