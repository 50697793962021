import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel} from '@mui/material';
import React from 'react';

const RequiredCheckbox = ({enableValidation, index, onChange, text = null, isFormArchived}) =>
    <FormControlLabel
        sx={{height: '40px'}}
        control={
            <Checkbox
                disabled={isFormArchived}
                checked={enableValidation}
                onChange={e => onChange({index, enableValidation: e.target.checked})}
            />
        }
        label={text ? text : 'Required?'}
    />

RequiredCheckbox.propTypes = {
    index: PropTypes.number,
    enableValidation: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    onChange: PropTypes.func,
    text: PropTypes.string
};

export default RequiredCheckbox;