import React from 'react';
import PropTypes from 'prop-types';
import SingleLine from './SingleLine';
import TextBox from './TextBox';
import CheckboxQuestion from './CheckboxQuestion';
import AdditionalNotes from './AdditionalNotes';
import MultipleChoice from './MultipleChoice';
import SingleChoice from './SingleChoice';
import Attachment from './Attachment';
import Signature from './Signature';
import BoxGrid from './BoxGrid';
import Dropdown from './Dropdown';
import LinearScale from './LinearScale';
import Image from './Image';

const ComponentBuilder = ({
                              index,
                              readOnly,
                              previewMode,
                              handleChangeEdit,
                              handleChangeSubmit,
                              submitData,
                              data,
                              submitMode,
                              field,
                              isFormArchived,
                              placeholderValues,
                              accessLevel,
                              relatedComponent
                          }) => {
    switch (field.type) {
        case 'singleText':
            return <SingleLine onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                               index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                               description={field.description} enableValidation={field.enableValidation}
                               isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                               readonly={readOnly}
            />
        case 'textBox':
            return <TextBox onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.description} enableValidation={field.enableValidation}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
            />
        case 'tickBoxGrid':
            return <BoxGrid onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.description} enableValidation={field.enableValidation}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived} rows={field.rows}
                            columns={field.columns} readOnly={readOnly} type={field.type}
            />
        case 'multipleChoiceGrid':
            return <BoxGrid onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                            index={index} id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                            description={field.description} enableValidation={field.enableValidation}
                            isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived} rows={field.rows}
                            columns={field.columns} readOnly={readOnly} type={field.type}
            />
        case 'checkboxQuestion':
            return <CheckboxQuestion onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                     index={index} id={field.id}
                                     value={readOnly ? data[field.id] : submitData[field.id]}
                                     description={field.description} enableValidation={field.enableValidation}
                                     isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
            />
        case 'additionalNotes':
            return <AdditionalNotes onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                    index={index} id={field.id} readOnly={readOnly} accessLevel={accessLevel}
                                    description={field.description} enableValidation={field.enableValidation}
                                    isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                    placeholderValues={readOnly ? data[field.id] : placeholderValues}
            />
        case 'multipleChoice':
            return <MultipleChoice onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                   index={index} id={field.id} options={field.options}
                                   value={readOnly ? data[field.id] : submitData[field.id]}
                                   description={field.description} enableValidation={field.enableValidation}
                                   isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                   relatedComponent={relatedComponent}
            />
        case 'singleChoice':
            return <SingleChoice onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                 index={index} id={field.id} options={field.options}
                                 value={readOnly ? data[field.id] : submitData[field.id]}
                                 description={field.description} enableValidation={field.enableValidation}
                                 isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                 relatedComponent={relatedComponent}
            />
        case 'linearScale':
            return <LinearScale onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                                index={index} id={field.id} startFrom={field.startFrom} endAt={field.endAt}
                                startLabel={field.startLabel} endLabel={field.endLabel}
                                value={readOnly ? data[field.id] : submitData[field.id]}
                                description={field.description} enableValidation={field.enableValidation}
                                isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                                isSubmitted={readOnly && submitMode}
            />
        case 'dropdown':
            return <Dropdown onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                             index={index} id={field.id} options={field.options}
                             value={readOnly ? data[field.id] : submitData[field.id]}
                             description={field.description} enableValidation={field.enableValidation}
                             isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
                             relatedComponent={relatedComponent}
            />
        case 'attachment':
            return <Attachment onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                               index={index} description={field.description} attachments={field.attachments}
                               isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
            />
        case 'image':
            return <Image onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                               index={index} description={field.description} attachments={field.attachments}
                               isEditMode={!submitMode && !previewMode} isFormArchived={isFormArchived}
            />
        case 'signature':
            return <Signature onChange={!submitMode && !previewMode ? handleChangeEdit : handleChangeSubmit}
                              id={field.id} value={readOnly ? data[field.id] : submitData[field.id]}
                              isEditMode={!submitMode && !previewMode} isSubmitted={readOnly && submitMode}
            />
        default:
            return null;
    }
};

ComponentBuilder.propTypes = {
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    previewMode: PropTypes.bool,
    handleChangeEdit: PropTypes.func,
    handleChangeSubmit: PropTypes.func,
    submitData: PropTypes.object,
    data: PropTypes.object,
    submitMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    field: PropTypes.object,
    validationErrors: PropTypes.object,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    relatedComponent: PropTypes.object
};

export default ComponentBuilder;