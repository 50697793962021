const FORM_STATUSES = {
    inactive: 'Inactive',
    active: 'Active',
    archived: 'Archived',
    deleted: 'Deleted',
    expired: 'Expired'
};

const FORM_STATUSES_NAMES = {
    [FORM_STATUSES.inactive]: 'Inactive',
    [FORM_STATUSES.active]: 'Active',
    [FORM_STATUSES.archived]: 'Archived',
    [FORM_STATUSES.expired]: 'Expired',
};

export {
    FORM_STATUSES,
    FORM_STATUSES_NAMES
};