import React from 'react';
import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import EditMultiFieldsBuilder from './Shared/EditMultiFieldsBuilder';

const SingleChoice = ({
                          index,
                          id,
                          value,
                          onChange,
                          description,
                          isEditMode,
                          enableValidation,
                          options,
                          isFormArchived,
                          relatedComponent
                      }) => {
    return (
        <Box>
            {isEditMode
                ? <EditMultiFieldsBuilder onChange={onChange} enableValidation={enableValidation}
                                          description={description} index={index} options={options}
                                          isFormArchived={isFormArchived} relatedComponent={relatedComponent}/>
                : <Box key={`${description}-${value}`}>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    <RadioGroup
                        value={value}
                        onChange={e => onChange({
                            id,
                            radioValue: e.target.value
                        })}
                    >
                        {options.map((option) =>
                            <FormControlLabel
                                key={`${description}-option-${option.index}`}
                                sx={{
                                    '& .MuiTypography-root': {
                                        wordBreak: 'break-word',
                                        maxWidth: '800px',
                                    }
                                }}
                                value={option.id}
                                control={<Radio/>}
                                label={option.description}
                            />
                        )}
                    </RadioGroup>
                </Box>
            }
        </Box>
    );
};

SingleChoice.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    relatedComponent: PropTypes.object
};

export default SingleChoice;