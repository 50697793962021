import React from 'react';
import {Box, Stack, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {generateUUID} from '../../../utility/uuidUtil';
import IconButton from '@mui/material/IconButton';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const getIFrameHtml = (formId, frameId) => `
<iframe src="${window.origin}/forms-request/${formId}/?embedded=true"
                id='${frameId}'
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                width="100%"
        >
        </iframe>
<script>
    function iframeResizeListener(event) {
        if (event.data && event.data.type === 'iframeResizer' && event.data.iframeHeight) {
            let iframe = document.getElementById('${frameId}');
            iframe.style.height = event.data.iframeHeight + 'px';
        }
    }
    window.addEventListener('message', iframeResizeListener, false);
</script>`

const getIFrame = (formId) => getIFrameHtml(formId, generateUUID())
    .replace(/[\n\r\t]+|\s\s+/g, ' ')
    .trim();

const PublicFormSettings = ({formId}) => {
    const iframe = getIFrame(formId);

    const copyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(iframe);
        } catch (e) {
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            padding={2}
            sx={{
                width: {
                    xs: '100%',
                    sm: '75%',
                    md: '50%',
                    lg: '50%',
                },
            }}
        >
            <Typography variant="subtitle1">Embed Widget</Typography>
            <Stack direction="row" alignItems="center">
                <TextField
                    fullWidth
                    sx={{
                        marginRight: 1
                    }}
                    readonly
                    value={iframe}
                    label="Embed Code"
                    variant="outlined"
                    margin="normal"
                />
                <Tooltip title={'Copy to clipboard'}>
                    <IconButton sx={{margin: 'auto'}} onClick={copyToClipBoard}>
                        <ContentCopyRoundedIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>
        </Box>
    );
};

PublicFormSettings.propTypes = {
    formId: PropTypes.number
}

export default PublicFormSettings;