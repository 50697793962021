import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Card, CardContent, styled} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import {
    confirmProfile,
    resetUpdatingStatus,
    retrieveBasicInfo,
    retrieveContactRelations,
    retrieveCountries,
    retrieveGroupsForBasicDetails,
    retrieveProfile,
    updateBasicInfo,
    updateStudentByParent
} from '../../ProfilesSlice';
import {showSnackbar} from '../../../../../AppLayout/ApplicationSlice';
import ProfileStatus from '../../Components/ProfileStatus';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useParams} from 'react-router-dom';
import {useConfirm} from 'material-ui-confirm';
import useUser from '../../../../../utility/hooks/useUser';
import {formatDate} from '../../../../../utility/dateUtil';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import PropTypes from 'prop-types';
import {
    isValidUPN,
    validateCountry,
    validateEmail,
    validateFieldLength,
    validatePostcode
} from '../../../../../utility/validationUtil';
import useTextFields from '../../../../../utility/hooks/useTextFields';
import moment from 'moment/moment';
import {ROLES} from '../../../../../constants/roles';
import {BasicInfoFieldsRenderer} from './BasicInfoFieldsRenderer';

const AddressSectionFields = styled(Grid)({
    display: 'flex', flexDirection: 'column', gap: '10px'
});

const ActionButtonsBox = styled(Box)(({theme}) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'end',
    paddingTop: theme.spacing(2),
}));

const ActionButton = styled(Button)({
    width: '100px'
});

const checkTextFieldsAreValid = fields =>
    !Object.values(fields)
        .some(({textFieldProps: props}) => props.error || (props.value === '' && props.required));

const BasicInfoTab = ({readonly}) => {
    const {user} = useUser();
    const [isEditMode, setIsEditMode] = useState(false);
    const {studentId} = useParams();
    const {
        selectedProfile,
        updatingSuccessful,
        countries,
        houseGroups,
        yearGroups,
        formGroups,
        boarderGroups,
        currentSchool
    } = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const profileData = selectedProfile.basicInfo;
    const userHasParentRole = user.role === ROLES.PARENT;

    const studentInfoTextFields = useMemo(() =>
        profileData
            ? [
                {
                    id: 'profile_forename',
                    initialValue: profileData.forename,
                    label: 'Forename',
                    name: 'forename',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 60),
                },
                {
                    id: 'profile_middleName',
                    initialValue: profileData.middleName,
                    label: 'Middle Name',
                    name: 'middleName',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 60)
                },
                {
                    id: 'profile_surname',
                    initialValue: profileData.legalSurname,
                    label: 'Surname',
                    name: 'legalSurname',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 60)
                },
                {
                    id: 'profile_preferredForename',
                    initialValue: profileData.chosenName,
                    label: 'Preferred Forename',
                    name: 'chosenName',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 60)
                },
                {
                    id: 'profile_preferredSurname',
                    initialValue: profileData.surname,
                    label: 'Preferred Surname',
                    name: 'surname',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 60)
                },
                {
                    id: 'profile_dateOfBirth',
                    initialValue: moment(profileData.dateOfBirth),
                    label: 'Date Of Birth',
                    name: 'dateOfBirth',
                    type: 'date',
                    required: false,
                    disabled: !(isEditMode && !userHasParentRole),
                    minDate: moment(new Date('1970-01-01')),
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_email',
                    initialValue: profileData.email,
                    label: 'Email',
                    name: 'email',
                    required: false,
                    disabled: !(isEditMode && !userHasParentRole),
                    validate: validateEmail,
                    nullableOnEmpty: true
                }
            ]
            : [], [profileData, isEditMode]);

    const educationInfoTextFields = useMemo(() =>
        profileData
            ? [
                selectedProfile.isApplicant
                    ? {
                        id: 'profile_intakeGroup',
                        label: 'Intake Group',
                        name: 'intakeId',
                        type: 'select',
                        select: true,
                        disabled: !(isEditMode && !userHasParentRole),
                        initialValue: profileData.intakeId,
                        options: yearGroups.map(element => ({value: element.id, title: element.name})),
                        nullableOnEmpty: true
                    }
                    : {
                        id: 'profile_yearGroup',
                        label: 'Year Group',
                        name: 'yearOfEntryId',
                        type: 'select',
                        select: true,
                        disabled: !(isEditMode && !userHasParentRole),
                        initialValue: profileData.yearOfEntryId,
                        options: yearGroups.map(element => ({value: element.id, title: element.name})),
                        nullableOnEmpty: true
                    },
                ...[
                    {
                        id: 'profile_form',
                        label: 'Form',
                        name: 'formId',
                        type: 'select',
                        select: true,
                        required: false,
                        parentEdit: false,
                        disabled: !(isEditMode && !userHasParentRole),
                        initialValue: profileData.formId,
                        options: formGroups.map(element => ({value: element.id, title: element.name})),
                        nullableOnEmpty: true
                    },
                    ...(currentSchool.hideHouseGroups
                        ? []
                        : [
                            {
                                id: 'profile_house',
                                label: 'House',
                                name: 'houseId',
                                type: 'select',
                                select: true,
                                required: false,
                                parentEdit: false,
                                disabled: !(isEditMode && !userHasParentRole),
                                initialValue: profileData.houseId,
                                options: houseGroups.map(element => ({value: element.id, title: element.name})),
                                nullableOnEmpty: true
                            }
                        ]),
                    ...(currentSchool.hideBoarderGroups
                        ? []
                        : [
                            {
                                id: 'profile_boarderStatus',
                                label: 'Boarder Status',
                                name: 'boarderId',
                                type: 'select',
                                select: true,
                                required: false,
                                parentEdit: false,
                                disabled: !(isEditMode && !userHasParentRole),
                                initialValue: profileData.boarderId,
                                options: boarderGroups.map(element => ({value: element.id, title: element.name})),
                                nullableOnEmpty: true
                            }
                        ]),
                    {
                        id: 'profile_upn',
                        label: 'UPN',
                        name: 'upn',
                        required: false,
                        disabled: !(isEditMode && !userHasParentRole),
                        initialValue: profileData.upn,
                        validate: (value) => ({
                            isValid: isValidUPN(value),
                            message: 'UPN\'s must use the format Annnnnnnnnnnn. The letters I,O and S are not allowed. Temporary UPN\'s have a trailing letter.'
                        }),
                    }
                ]
            ]
            : [], [profileData, isEditMode, currentSchool, yearGroups, formGroups, houseGroups, boarderGroups, userHasParentRole]);

    const addressTextFields = useMemo(() =>
        profileData
            ? [
                {
                    id: 'profile_apartment',
                    label: 'Apartment',
                    name: 'apartment',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.apartment,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_houseNumber',
                    label: 'House Number',
                    name: 'houseNumber',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.houseNumber,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_street',
                    label: 'Street',
                    name: 'street',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.street,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_district',
                    label: 'District',
                    name: 'district',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.district,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_town',
                    label: 'Town',
                    name: 'town',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.town,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_houseName',
                    label: 'House Name',
                    name: 'houseName',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.houseName,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_county',
                    label: 'County',
                    name: 'county',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value) => validateFieldLength(value, 40),
                    initialValue: profileData.county,
                    dependencies: ['countryCode', 'postcode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_postcode',
                    label: 'Postcode',
                    name: 'postcode',
                    required: false,
                    disabled: !isEditMode,
                    validate: (value, fields) => validatePostcode(value, fields.countryCode?.textFieldProps.value),
                    initialValue: profileData.postcode,
                    dependencies: ['countryCode'],
                    nullableOnEmpty: true
                },
                {
                    id: 'profile_country',
                    label: 'Country',
                    name: 'countryCode',
                    type: 'select',
                    select: true,
                    required: false,
                    disabled: !isEditMode,
                    validate: (value, fields) => validateCountry(value, fields),
                    options: countries.map(element => ({value: element.code, title: element.name})),
                    initialValue: profileData.countryCode,
                    dependencies: ['postcode'],
                    nullableOnEmpty: true
                }
            ]
            : [], [profileData, isEditMode]);

    const {
        fields: studentInfoFields,
        getFieldValues: getStudentFieldValues
    } = useTextFields(studentInfoTextFields);
    const {
        fields: educationInfoFields,
        getFieldValues: getEducationFieldValues
    } = useTextFields(educationInfoTextFields);
    const {
        fields: addressFields,
        getFieldValues: getAddressFieldValues
    } = useTextFields(addressTextFields);
    const areAllTextFieldsValid =
        checkTextFieldsAreValid(studentInfoFields) &&
        checkTextFieldsAreValid(educationInfoFields) &&
        checkTextFieldsAreValid(addressFields);

    useEffect(() => {
        dispatch(retrieveCountries());
        dispatch(retrieveBasicInfo({studentId}));
        dispatch(retrieveContactRelations());
        dispatch(retrieveProfile({studentId})).then((res) => {
            dispatch(retrieveGroupsForBasicDetails({isApplicant: res.payload?.isApplicant}));
        });
    }, []);

    useEffect(() => {
        showNotification(updatingSuccessful);
        if (updatingSuccessful) {
            dispatch(resetUpdatingStatus());
            dispatch(retrieveBasicInfo({studentId: selectedProfile.id}));
            setIsEditMode(false);
        }

        return () => {
            dispatch(resetUpdatingStatus());
        };
    }, [updatingSuccessful]);

    const showNotification = success => success !== undefined && dispatch({
        true: () => showSnackbar({
            message: 'Basic student information updated successfully', severity: 'success'
        }),
        false: () => showSnackbar({
            message: 'Failed to update basic student information', severity: 'error'
        })
    }[success]());

    const handleSave = () => {
        confirm({
            title: 'Confirmation',
            description: `I confirm that the information in the basic profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
            confirmationText: 'Confirm'
        })
            .then(() => {
                const studentDetails = {
                    ...profileData,
                    ...getStudentFieldValues(),
                    ...getEducationFieldValues(),
                    ...getAddressFieldValues(),
                };

                if (user.role === ROLES.PARENT) {
                    dispatch(updateStudentByParent({
                        studentId: selectedProfile.id,
                        id: studentDetails.id,
                        studentDetails
                    }));
                } else {
                    dispatch(updateBasicInfo({
                        studentId: selectedProfile.id,
                        id: studentDetails.id,
                        studentDetails
                    }));
                }
            })
            .catch(() => {
            });
    };

    const handleConfirm = () => {
        confirm({
            title: 'Confirmation',
            description: `I confirm that the information in the basic profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
            confirmationText: 'Confirm'
        })
            .then(() => {
                dispatch(confirmProfile({
                    studentId: selectedProfile.id,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.Basic
                }));
            })
            .catch(() => {
            });
    };

    const handleCancel = () => {
        setIsEditMode(false);
    };

    return (<>
        {profileData && <>
            <Box
                display="flex"
                flexDirection={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row'
                }}
                gap={2}
            >
                <Box flex={1}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems={'start'}
                            >
                                <Avatar
                                    variant="square"
                                    sx={{
                                        width: 120,
                                        height: 120,
                                        marginBottom: 2
                                    }}
                                    alt={`${selectedProfile.name} ${selectedProfile.surname}`}
                                    src={selectedProfile.photo && `data:image/jpeg;base64,${selectedProfile.photo}`}
                                />
                                {selectedProfile.isApplicant
                                    ? <ProfileStatus status={selectedProfile.status}
                                                     statusCategory={selectedProfile.statusCategory}
                                                     isStudentProfile={true}/>
                                    : <div></div>
                                }
                            </Box>
                            <BasicInfoFieldsRenderer fields={studentInfoFields}/>
                        </CardContent>
                    </Card>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={2}
                    gap={2}
                >
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" fontWeight="bold">Education information</Typography>
                            <BasicInfoFieldsRenderer fields={educationInfoFields}/>
                        </CardContent>
                    </Card>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6" fontWeight="bold">Home Address</Typography>
                            <Grid container sx={{height: '100%'}} spacing={2}>
                                {
                                    Object.values(
                                        Object.entries(addressFields)
                                            .reduce((acc, [name, field]) => {
                                                if (['apartment', 'houseNumber', 'street', 'district', 'town'].includes(field.textFieldProps.name)) {
                                                    acc.firstSection[name] = field;
                                                } else {
                                                    acc.secondSection[name] = field;
                                                }
                                                return acc;
                                            }, {firstSection: {}, secondSection: {}})
                                    ).map((fields, index) => (
                                        <AddressSectionFields
                                            key={`addressField_${index}`}
                                            item
                                            xs={12}
                                            sm={6}
                                        >
                                            <BasicInfoFieldsRenderer fields={fields}/>
                                        </AddressSectionFields>
                                    ))
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <ActionButtonsBox>
                {
                    profileData.editedAt && profileData.editedBy && <Typography mt={1}>Confirmed
                        at {formatDate(profileData.editedAt)} by {profileData.editedBy}</Typography>
                }
                {isEditMode
                    ? <>
                        <ActionButton
                            variant="outlined"
                            onClick={handleCancel}
                        >Cancel
                        </ActionButton>
                        <ActionButton
                            disabled={!areAllTextFieldsValid}
                            variant="contained"
                            onClick={handleSave}
                        >Save
                        </ActionButton>
                    </>
                    : <>
                        {!profileData.editedAt &&
                            <ActionButton variant="contained" onClick={handleConfirm}>Confirm</ActionButton>}
                        {!readonly &&
                            <ActionButton variant="contained" onClick={() => {
                                setIsEditMode(true);
                            }}>Edit</ActionButton>}
                    </>}
            </ActionButtonsBox>
        </>}
    </>);
};

BasicInfoTab.propTypes = {
    readonly: PropTypes.bool
};

export default BasicInfoTab;
