import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Paper} from '@mui/material';
import {validateEmail} from '../../utility/validationUtil';
import AdmicityForm from '../../shared-components/AdmicityForm';
import api from '../../axios';
import {useNavigate} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Typography from '@mui/material/Typography';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);

    useEffect(() => {
        return () => {
            setResetPasswordError('');
            setIsEmailSentSuccess(false);
        };
    }, []);

    const textFields = [
        {
            label: 'Email',
            name: 'email',
            validate: (value) => validateEmail(value)
        }
    ];

    const handleSubmit = async (payload) => {
        try {
            await api.post('/account/recovery-password', {...payload});
            setIsEmailSentSuccess(true);
        } catch (e) {
            const errorMessages = {
                404: 'A user with this email not exists.',
                423: 'Reset email already sent. Check your email.'
            };

            setResetPasswordError(errorMessages[e.response.status] ?? 'Something went wrong. Please try again later.');

            throw e;
        }
    };

    const SentEmailSuccessView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography variant="h6" textAlign="center" gutterBottom>
                A password reset email has been sent.
            </Typography>
            <Typography textAlign="center" gutterBottom>
                Please follow the instructions in the email to reset your password.
            </Typography>
            <Button sx={{marginTop: 1}} variant="contained" onClick={() => navigate('/login')}>
                Back to Login Page
            </Button>
        </Box>;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            <Container maxWidth="xs" key={isEmailSentSuccess}>
                {
                    isEmailSentSuccess
                        ? <SentEmailSuccessView/>
                        : <Paper
                            variant="outlined"
                            sx={{p: 3, display: 'flex', flexDirection: 'column'}}
                        >
                            <AdmicityForm
                                title="Please enter your account email to input to send confirmation email"
                                textFields={textFields}
                                handleSubmit={handleSubmit}
                                handleChange={() => setResetPasswordError('')}
                                buttonText="Reset your password"
                                errorCaption={resetPasswordError}
                            />
                            <Button
                                variant={'text'}
                                sx={{textTransform: 'none'}}
                                onClick={() => navigate('/login')}>
                                Back to Sign In
                            </Button>
                        </Paper>
                }
            </Container>
        </Box>
    );
};

export default ForgotPasswordPage;