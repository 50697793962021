import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Box, Divider, ListItemIcon, Menu, MenuItem, styled} from '@mui/material';
import logo from '../img/logo.png';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Logout} from '@mui/icons-material';

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgb(250, 250, 251)',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: 0,
    padding: 8
});

const Header = ({displayActions, handleLogout}) => {
    const account = useSelector(state => state.application.account);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const shouldOpenAccountMenu = Boolean(anchorEl);

    const openAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountMenuClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <StyledAppBar position="sticky">
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <img src={logo} alt="Admicity logo" style={{height: 50}}/>
                {
                    displayActions
                        ? <>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'block',
                                            lg: 'block',
                                        },
                                    }}
                                    color="inherit"
                                    variant="p"
                                    component="h4"
                                >
                                    {`${account.firstName} ${account.lastName}`}
                                </Typography>
                                <IconButton color="inherit" sx={{p: 1}} onClick={openAccountMenu}>
                                    <Avatar alt="My Avatar" src={account.schoolName}/>
                                </IconButton>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={shouldOpenAccountMenu}
                                onClose={handleAccountMenuClose}
                                onClick={handleAccountMenuClose}
                                slotProps={{
                                    paper: {
                                        sx: {
                                            minWidth: 300,
                                            maxWidth: 350
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'block',
                                            md: 'none',
                                            lg: 'none',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            paddingLeft: 2,
                                            paddingRight: 2,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            wordWrap: 'break-word'
                                        }}
                                        color="inherit"
                                        variant="body1"
                                    >
                                        {`${account.firstName} ${account.lastName}`}
                                    </Typography>
                                    <Divider/>
                                </Box>
                                <MenuItem onClick={() => {
                                    handleLogout();
                                    handleAccountMenuClose();
                                }}>
                                    <ListItemIcon><Logout fontSize="small"/></ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                        : ''
                }
            </Box>
        </StyledAppBar>
    </>);
};

Header.propTypes = {
    displayActions: PropTypes.bool,
    handleLogout: PropTypes.func,
};

Header.defaultProps = {
    displayActions: true
};

export default Header;