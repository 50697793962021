import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {styled} from '@mui/material';

const getColorByStatusCategory = (theme, statusCategory) => {
    switch (statusCategory) {
        case 'Applied':
            return '#3498db';
        case 'Offered':
            return '#f39c12';
        case 'Accepted':
            return '#27ae60';
        case 'Admitted':
            return '#ffff50';
        case 'Rejected':
            return '#e74c3c';
        case 'Reserved':
            return '#7f8c8d';
        case 'Withdrawl':
            return '#7f8c8d';
        default:
            return theme.palette.grey[500];
    }
};

const StatusContainer = styled('div')(({theme, statusCategory}) => ({
    padding: theme.spacing(0.5),
    borderRadius: 100,
    color: theme.palette.common.white,
    backgroundColor: getColorByStatusCategory(theme, statusCategory),
    width: '60px',
    height: '15px',
    textAlign: 'center'
}));

const StatusLabel = styled(Typography)(() => ({
    fontSize: 12
}));

const ProfileStatusContainer = styled('div')(({theme, statusCategory}) => ({
    padding: theme.spacing(0.5),
    borderRadius: 100,
    color: theme.palette.common.white,
    backgroundColor: getColorByStatusCategory(theme, statusCategory),
    width: 180,
    textAlign: 'center'
}));

const ProfileStatus = ({status, statusCategory, isStudentProfile}) => {
    return (<> {!isStudentProfile
        ? <StatusContainer statusCategory={statusCategory}>
            <StatusLabel variant="subtitle2">
                {status}
            </StatusLabel>
        </StatusContainer>
        : <ProfileStatusContainer statusCategory={statusCategory}>
            <Typography variant="h6">
                {status}
            </Typography>
        </ProfileStatusContainer>}</>)
}

ProfileStatus.propTypes = {
    status: PropTypes.string,
    statusCategory: PropTypes.string,
    isStudentProfile: PropTypes.bool.isRequired
};
export default ProfileStatus;