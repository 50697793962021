import React from 'react';
import ContentPasteOffRoundedIcon from '@mui/icons-material/ContentPasteOffRounded';
import {useNavigate} from 'react-router-dom';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {getFormRequestStatusInfo} from '../common/common';
import {useGetFormRequestsByParentQuery} from '../../../../api/services/formRequestsService';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {formatDate} from '../../../../utility/dateUtil';
import withPaginationV2 from '../../../../hoc/pagination/withPaginationV2';

/* eslint-disable react/prop-types */
const tableProps = {
    columns: [
        {
            accessorKey: 'formName',
            header: 'Form Name'
        },
        {
            accessorKey: 'student.forename',
            header: 'First name'
        },
        {
            accessorKey: 'student.surname',
            header: 'Last name'
        },
        {
            accessorKey: 'status',
            header: 'Status',
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>,
        },
        {
            accessorKey: 'expirationDate',
            header: 'Expiration Date',
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
        },
        {
            accessorKey: 'editedBy',
            header: 'Submitted By'
        },
        {
            accessorKey: 'editedAt',
            header: 'Submitted At',
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
        },
    ]
};
/* eslint-enable react/prop-types */
const Table = withPaginationV2(AdmicityTableV2, {func: useGetFormRequestsByParentQuery});

const RequestAndResponses = () => {
    const navigate = useNavigate();

    return (
        <Table
            columns={tableProps.columns}
            tableProps={{
                enableSorting: false,
                enableFilters: false,
                enableFullScreenToggle: false
            }}
            onRowClick={(params) => navigate(`${params.id}`)}
            noRowsOverlay={{
                icon: <ContentPasteOffRoundedIcon/>,
                text: 'No requests and responses yet'
            }}
        />
    );
};

export default RequestAndResponses;