import {Outlet, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {resetTableState} from '../../../../store/tableSlice';
import {getRouteMatchPath} from '../../../../utility/routeUtil';
import routes from '../../../../routes/routes';

const FormResponsesPage = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const path = getRouteMatchPath(routes, pathname);

    useEffect(() => {
        return () => {
            dispatch(resetTableState(path));
        };
    }, []);

    return <>
        <Outlet context={{key: path}}/>
    </>;
};

export default FormResponsesPage;