import {useDispatch} from 'react-redux';
import {showSnackbar} from '../../AppLayout/ApplicationSlice';

const useNotification = () => {
    const dispatch = useDispatch();
    const defaults = {
        successMessage: 'Operation completed successfully',
        errorMessage: 'Operation failed'
    };

    const showErrorNotification = (message = defaults.errorMessage) =>
        dispatch(showSnackbar({
            message,
            severity: 'error'
        }));

    const showSuccessNotification = (message = defaults.successMessage) =>
        dispatch(showSnackbar({
            message,
            severity: 'success'
        }));

    return {showErrorNotification, showSuccessNotification};
};

export default useNotification;