import EthnicityFields from './EthnicityFields';
import PermissionCheck from '../../Components/PermissionCheck';
import React from 'react';
import Contacts from './Contacts';

const ContactsAndEthnicityTab = () => {
    return (
        <>
            <PermissionCheck
                permissionModule="StudentContacts"
                render={(readonly) => <Contacts readonly={readonly}/>}
            />
            <PermissionCheck
                permissionModule="StudentEthnicity"
                render={(readonly) => <EthnicityFields readonly={readonly}/>}
            />
        </>
    );
}

export default ContactsAndEthnicityTab;