import React, {useEffect, useState} from 'react';
import {Alert, Box, colors, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileRounded';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

const UploadComponent = (
    {
        totalUploadSizeInMb,
        onFilesSelect,
        onFileDelete,
        value,
        onUploadLimitExceed = () => {
        },
        readonly = false,
        singleFile = false,
        fileTypes = {
            'image/*': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/zip': [],
            'application/x-zip-compressed': [],
            'application/vnd.ms-powerpoint': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
        },
        allowedFileTypesLabel = 'Allowed file types: Images, Word (.doc, .docx), PDFs, Excel (.xls, .xlsx), Presentation (.ppt, pptx), .zip'
    }) => {
    const maxSize = totalUploadSizeInMb * 1024 * 1024;
    const [shouldShowLimitExceedAlert, showLimitExceedAlert] = useState(false);
    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop: onFilesSelect,
        accept: fileTypes,
        maxSize,
        multiple: !singleFile
    });

    useEffect(() => {
        if (value) {
            const totalSize = value.reduce((acc, file) => acc + file.size, 0);
            const isExceed = totalSize > maxSize;
            showLimitExceedAlert(isExceed);
            onUploadLimitExceed(isExceed);
        }
    }, [value]);

    const handleFileDelete = (file) => onFileDelete(file.name);

    return (
        <Box
            display="flex"
            flexDirection="column"
            height={'100%'}
            overflowY="hidden"
        >
            <Box
                {...getRootProps()}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    height: 250,
                    border: '1.5px dashed',
                    borderColor: isDragActive ? colors.blue[400] : 'grey.A400',
                    borderRadius: 2,
                    backgroundColor: isDragActive ? colors.blue[50] : 'background.paper',
                    cursor: 'pointer',
                    '&:hover': {
                        borderColor: colors.blue[400]
                    },
                }}
            >
                <input {...getInputProps()} />
                <UploadFileOutlinedIcon sx={{fontSize: 36, color: 'grey.A400', margin: 1}}/>
                <Typography variant="body1" gutterBottom>
                    Click to upload or Drag and Drop
                </Typography>
                <Typography variant="caption" sx={{mb: 2}}>
                    Max total file size: {totalUploadSizeInMb.toFixed(1)}MB
                </Typography>
                <Typography variant="caption" sx={{mb: 2, ml: 2, mr: 2}}>
                    {allowedFileTypesLabel}
                </Typography>
            </Box>
            {
                shouldShowLimitExceedAlert
                    ? <Alert sx={{marginTop: 2}} severity="error">The total size of the files
                        exceeds {totalUploadSizeInMb.toFixed(1)}MB.</Alert>
                    : ''
            }

            <List sx={{
                maxHeight: {xs: '100%', sm: '100%', md: 250, lg: 250},
                marginTop: 2,
                overflowY: 'scroll',
                scrollbarWidth: 'thin'
            }} dense>
                {
                    value.map(file => <ListItem
                        key={file.name}
                        sx={{
                            border: '1px solid',
                            borderColor: 'grey.A200',
                            borderRadius: 2,
                            marginBottom: 1
                        }}
                        secondaryAction={
                            <IconButton
                                disabled={readonly}
                                edge="end"
                                sx={{
                                    ':hover': {
                                        color: 'error.light'
                                    }
                                }}
                                onClick={() => handleFileDelete(file)}
                            >
                                <DeleteIcon fontSize={'small'}/>
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            <UploadFileOutlinedIcon
                                sx={{
                                    color: 'primary.main'
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={file.name}
                            secondary={`${Math.round(file.size / 1024)} KB`}
                        />
                    </ListItem>)
                }
            </List>
        </Box>
    );
};

UploadComponent.propTypes = {
    totalUploadSizeInMb: PropTypes.number.isRequired,
    onFilesSelect: PropTypes.func,
    onFileDelete: PropTypes.func,
    value: PropTypes.array,
    onUploadLimitExceed: PropTypes.func,
    readonly: PropTypes.bool,
    singleFile: PropTypes.bool,
    fileTypes: PropTypes.object,
    allowedFileTypesLabel: PropTypes.string
};

export default UploadComponent;
