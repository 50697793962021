import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Paper} from '@mui/material';
import {validatePassword} from '../../utility/validationUtil';
import AdmicityForm from '../../shared-components/AdmicityForm';
import api from '../../axios';
import {useNavigate, useParams} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Typography from '@mui/material/Typography';

const ResetPasswordPage = () => {
    const {requestId} = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [isPasswordChangedSuccess, setIsPasswordChangedSuccess] = useState(false);

    useEffect(() => {
        return () => {
            setPassword('');
            setResetPasswordError('');
            setIsPasswordChangedSuccess(false);
        };
    }, []);

    const textFields = [
        {
            label: 'Password',
            type: 'password',
            name: 'password',
            validate: (password) => {
                setPassword(password);
                return validatePassword(password);
            }
        },
        {
            label: 'Confirm Password',
            type: 'password',
            validate: (confirmPassword) => {
                const isMatch = password === confirmPassword;
                return {
                    isValid: isMatch,
                    message: isMatch ? '' : 'Passwords must match'
                };
            }
        }
    ];

    const handleSubmit = async (payload) => {
        try {
            await api.post(`/account/reset/${requestId}`, {...payload, requestId});
            setIsPasswordChangedSuccess(true);
        } catch (e) {
            const errorMessages = {
                410: 'Reset password request expired.',
            };

            setResetPasswordError(errorMessages[e.response.status] ?? 'Something went wrong. Please try again later.');

            throw e;
        }
    };

    const RegistrationSuccessView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography textAlign="center" gutterBottom>
                Your password has been changed successfully. You can now log in using your new credentials.
            </Typography>
            <Button sx={{marginTop: 1}} variant="contained" onClick={() => navigate('/login')}>
                Back to Login Page
            </Button>
        </Box>;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            <Container maxWidth="xs">
                {
                    isPasswordChangedSuccess
                        ? <RegistrationSuccessView/>
                        : <Paper
                            variant="outlined"
                            sx={{p: 3, display: 'flex', flexDirection: 'column'}}
                        >
                            <AdmicityForm
                                title="Reset Password"
                                textFields={textFields}
                                handleSubmit={handleSubmit}
                                handleChange={() => setResetPasswordError('')}
                                buttonText="Update"
                                errorCaption={resetPasswordError}
                            />
                        </Paper>
                }
            </Container>
        </Box>
    );
};

export default ResetPasswordPage;