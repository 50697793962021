import React, {useEffect, useState} from 'react';
import {Box, Button, Stack, TextField} from '@mui/material';
import useTextField from '../../../../utility/hooks/useTextField';
import AdmicityTable from '../../../../shared-components/AdmicityTable';
import {formatDate} from '../../../../utility/dateUtil';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import MisIntegrationSection from './MisIntegrationSection';
import {useDispatch, useSelector} from 'react-redux';
import {
    createToken,
    getDataSourceProperties,
    getTokens,
    resetState,
    revokeToken,
    setupDataSourceProperties,
    updateDataSourceProperties,
    updateToken
} from './MisIntegrationSlice';
import KeyOffRoundedIcon from '@mui/icons-material/KeyOffRounded';
import MisIntegrationCreateTokenDialog from './MisIntegrationCreateTokenDialog';
import {validateFieldLength} from '../../../../utility/validationUtil';
import MisIntegrationUpdateTokenDialog from './MisIntegrationUpdateTokenDialog';
import {TOKEN_STATUSES, TOKEN_STATUSES_NAMES} from '../../../../constants/tokenStatuses';

const tableProps = {
    columns: [
        {
            field: 'name',
            headerName: 'Token Name',
            editable: false,
            sortable: false,
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            sortable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            editable: false,
            valueGetter: (params) => formatDate(params.row?.createdAt),
            sortable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            editable: false,
            sortable: false,
            minWidth: 150,
            flex: 1,
        }
    ],
    pageSize: 10
};

const MisIntegration = () => {
    const {datasourceProperties, tokens, isLoadingTokens} = useSelector(state => state.misIntegration);
    const dispatch = useDispatch();
    const serverField = useTextField({
        initialValue: datasourceProperties?.databaseServer ?? '',
        validate: (value) => validateFieldLength(value, 128)
    });
    const nameField = useTextField({
        initialValue: datasourceProperties?.databaseName ?? '',
        validate: (value) => validateFieldLength(value, 128)
    });
    const usernameField = useTextField({
        initialValue: datasourceProperties?.username ?? '',
        validate: (value) => validateFieldLength(value, 128)
    });
    const passwordField = useTextField({
        initialValue: '',
        validate: (value) => validateFieldLength(value, 128)
    });
    const [openCreateTokenDialog, setOpenCreateTokenDialog] = useState(false);
    const [openUpdateTokenDialog, setOpenUpdateTokenDialog] = useState(false);
    const [selectedTokenId, setSelectedTokenId] = useState([]);

    const handleCreateTokenDialogOpen = () => setOpenCreateTokenDialog(true);

    const handleCreateTokenDialogClose = () => setOpenCreateTokenDialog(false);

    const handleUpdateTokenDialogOpen = () => setOpenUpdateTokenDialog(true);

    const handleUpdateTokenDialogClose = () => setOpenUpdateTokenDialog(false);

    const isPropertiesWereCreatedBefore = Object.keys(datasourceProperties ?? {}).length > 0;

    const isDatasourcePropertiesFormValid = () =>
        (!serverField.error && serverField.value) &&
        (!nameField.error && nameField.value) &&
        (!usernameField.error && usernameField.value) &&
        (isPropertiesWereCreatedBefore || (!passwordField.error && passwordField.value));

    const handleDatasourcePropertiesSave = () => {
        dispatch({
            true: updateDataSourceProperties,
            false: setupDataSourceProperties
        }[isPropertiesWereCreatedBefore](
            {
                databaseServer: serverField.value,
                databaseName: nameField.value,
                username: usernameField.value,
                password: passwordField.value
            }
        ));

        passwordField.reset();
    };

    useEffect(() => {
        dispatch(getDataSourceProperties());
        dispatch(getTokens());

        return () => {
            dispatch(resetState());
        };
    }, []);

    const handleTokenCreate = (body) => dispatch(createToken(body));

    const handleTokenUpdate = (body) => {
        dispatch(updateToken(body));
        setSelectedTokenId([]);
    };

    const handleTokenRevoke = () => {
        dispatch(revokeToken());
        setSelectedTokenId([]);
    };

    const handleRowSelect = (ids) => {
        const id = ids.length > 1 ? [ids[ids.length - 1]] : ids;
        setSelectedTokenId(id);
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            flexGrow={1}
            height={'100%'}
            sx={{
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                    xl: 'row',
                }
            }}
        >
            <MisIntegrationSection
                flex={2}
                title={'SIMS Credentials'}
                sx={{
                    padding: {
                        xs: '16px 0px',
                        sm: '16px 0px',
                        md: '16px 0px',
                        lg: '16px 16px 0px 0px',
                        xl: '16px 16px 0px 0px',
                    }
                }}
            >
                <Box>
                    <TextField
                        required
                        fullWidth
                        label="DB Server"
                        variant="outlined"
                        {...serverField}
                    />
                    <TextField
                        required
                        fullWidth
                        label="DB Name"
                        variant="outlined"
                        margin="normal"
                        {...nameField}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        sx={{
                            marginRight: 2
                        }}
                        {...usernameField}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        type="password"
                        {...passwordField}
                    />
                    <Stack sx={{marginTop: 1}} direction="row" spacing={2} justifyContent="start">
                        <Button
                            variant="contained"
                            disabled={!isDatasourcePropertiesFormValid()}
                            onClick={handleDatasourcePropertiesSave}
                        >Save
                        </Button>
                    </Stack>
                </Box>
            </MisIntegrationSection>
            <MisIntegrationSection
                flex={3}
                title={'Admicity Application Token'}
                sx={{
                    padding: {
                        xs: '16px 0px',
                        sm: '16px 0px'
                    }
                }}
            >
                <AdmicityTable
                    data={tokens}
                    columns={tableProps.columns}
                    pageSize={tableProps.pageSize}
                    checkboxSelection={true}
                    handleRowSelection={handleRowSelect}
                    rowSelectionModel={selectedTokenId}
                    shouldDisableRow={(row) => row.status === TOKEN_STATUSES_NAMES[TOKEN_STATUSES.revoked]}
                    isLoading={isLoadingTokens}
                    hideFooterPagination
                    toolbarActions={[
                        {
                            type: 'button',
                            title: 'New Token',
                            icon: <AddOutlinedIcon/>,
                            onClick: handleCreateTokenDialogOpen
                        },
                        ...(selectedTokenId?.length > 0
                                ? [
                                    {
                                        title: 'Revoke',
                                        type: 'button',
                                        icon: <UndoIcon/>,
                                        onClick: handleTokenRevoke
                                    },
                                    {
                                        title: 'Edit',
                                        type: 'button',
                                        icon: <CreateOutlinedIcon/>,
                                        onClick: handleUpdateTokenDialogOpen
                                    }
                                ]
                                : []
                        )
                    ]}
                    noRowsOverlay={{
                        icon: <KeyOffRoundedIcon/>,
                        text: <>
                            No access tokens has been added
                            <Button
                                sx={{
                                    marginTop: 1
                                }}
                                variant="outlined"
                                startIcon={<AddOutlinedIcon/>}
                                size={'small'}
                                onClick={handleCreateTokenDialogOpen}
                            >
                                New Token
                            </Button>
                        </>
                    }}
                />
            </MisIntegrationSection>
            <MisIntegrationCreateTokenDialog
                open={openCreateTokenDialog}
                onTokenCreate={handleTokenCreate}
                onClose={handleCreateTokenDialogClose}
            />
            {
                selectedTokenId.length > 0
                    ? <MisIntegrationUpdateTokenDialog
                        open={openUpdateTokenDialog}
                        model={tokens.find(t => t.id === selectedTokenId[0])}
                        onTokenUpdate={handleTokenUpdate}
                        onClose={handleUpdateTokenDialogClose}
                    />
                    : ''
            }
        </Box>
    );
};

export default MisIntegration;