import React, {useState} from 'react';
import {Box, MenuItem} from '@mui/material';
import AdmicityTable from '../../../shared-components/AdmicityTable';
import {useGetContactInvitesQuery, useResendInvitesMutation} from '../../../api/services/schoolManagementService';
import withPagination from '../../../hoc/pagination/withPagination';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import INVITE_STATUSES from '../../../constants/inviteStatuses';
import AdmicitySearchField from '../../../shared-components/AdmicitySearchField';
import FilterOperations from '../../../constants/filterOperations';
import {formatDate} from '../../../utility/dateUtil';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {downloadContactInvitesCsvReport, downloadContactInvitesXlsxReport} from '../../../api/services/filesService';
import useFileDownloader from '../../../utility/hooks/useFileDownloader';
import AdmicityStatus from '../../../shared-components/AdmicityStatus';
import {CONTACT_INVITE_STATUSES} from '../../../constants/ContactInviteStatuses';

const quickFilterOptions = [
    {value: 0, title: 'All'},
    {value: 1, title: 'Applicants'},
    {value: 2, title: 'Students'}
];

const getInviteStatusInfo = (status) => ({
    label: status,
    color: {
        [CONTACT_INVITE_STATUSES.sent]: 'blueGrey',
        [CONTACT_INVITE_STATUSES.completed]: 'green',
        [CONTACT_INVITE_STATUSES.expired]: 'red',
        [CONTACT_INVITE_STATUSES.failed]: 'red'
    }[status] ?? 'grey'
});

const defaultSorting = {field: 'firstInviteCreatedDate', sort: 'asc'};
export const ENABLE_FILTERS = 'ENABLE_FILTERS';

const ContactInvites = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState([defaultSorting]);
    const [quickFilter, setQuickFilter] = useState(0);
    const [search, setSearch] = useState({});
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [resendInvites] = useResendInvitesMutation();
    const downloadFile = useFileDownloader();

    const Table = withPagination(AdmicityTable, useGetContactInvitesQuery, {
        filters,
        profileFilter: quickFilter,
        sorting: sorting[0] ?? defaultSorting,
        sortingMode: 'server'
    });

    const tableProps = {
        columns: [
            {
                field: 'studentName',
                headerName: 'Student Name',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.CONTAINS,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search.studentName?.value || ''}
                                           applyFilters={applyFilters}
                                           clearFilter={clearFilters}/>
                    : params.value
            },
            {
                field: 'firstContactName',
                headerName: 'First Contact Name',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.CONTAINS,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.firstContactName?.value}
                                           applyFilters={applyFilters}
                                           clearFilter={clearFilters}/>
                    : params.value
            },
            {
                field: 'firstContactEmail',
                headerName: 'First Contact Email',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.CONTAINS,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.firstContactEmail?.value}
                                           applyFilters={applyFilters}
                                           clearFilter={clearFilters}/>
                    : params.value
            },
            {
                field: 'firstInviteCreatedDate',
                headerName: 'Invite Date',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.DATE_RANGE,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.firstInviteCreatedDate}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : !params.value ? '' : formatDate(new Date(params.value))
            },
            {
                field: 'firstContactRegistrationDate',
                headerName: 'Registration Date',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.DATE_RANGE,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.firstContactRegistrationDate}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : !params.value ? '' : formatDate(new Date(params.value))
            },
            {
                field: 'firstContactInviteStatus',
                headerName: 'Status',
                editable: false,
                sortable: true,
                minWidth: 150,
                operation: FilterOperations.MULTISELECT,
                options: INVITE_STATUSES.map(element => ({
                    value: element === 'Empty' ? null : element,
                    title: element
                })),
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.firstContactInviteStatus?.value ?? []}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : <AdmicityStatus {...getInviteStatusInfo(params.value)}/>,
            },
            {
                field: 'secondContactName',
                headerName: 'Second Contact Name',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.CONTAINS,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.secondContactName?.value}
                                           applyFilters={applyFilters}
                                           clearFilter={clearFilters}/>
                    : params.value
            },
            {
                field: 'secondContactEmail',
                headerName: 'Second Contact Email',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.CONTAINS,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.secondContactEmail?.value}
                                           applyFilters={applyFilters}
                                           clearFilter={clearFilters}/>
                    : params.value
            },
            {
                field: 'secondInviteCreatedDate',
                headerName: 'Invite Date',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.DATE_RANGE,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.secondInviteCreatedDate}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : !params.value ? '' : formatDate(new Date(params.value))
            },
            {
                field: 'secondContactRegistrationDate',
                headerName: 'Registration Date',
                editable: false,
                sortable: true,
                minWidth: 200,
                operation: FilterOperations.DATE_RANGE,
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.secondContactRegistrationDate}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : !params.value ? '' : formatDate(new Date(params.value))
            },
            {
                field: 'secondContactInviteStatus',
                headerName: 'Status',
                editable: false,
                sortable: true,
                minWidth: 150,
                operation: FilterOperations.MULTISELECT,
                options: INVITE_STATUSES.map(element => ({
                    value: element === 'Empty' ? null : element,
                    title: element
                })),
                renderCell: (params) => params.value === ENABLE_FILTERS
                    ? <AdmicitySearchField column={params.colDef} updateFilters={updateFilters}
                                           initialValue={search?.secondContactInviteStatus?.value ?? []}
                                           applyFilters={applyFilters} clearFilter={clearFilters}/>
                    : <AdmicityStatus {...getInviteStatusInfo(params.value)}/>,
            },
        ],
    };

    const buildFilters = (data) => {
        let result = [];
        for (let key in data) {
            let newObj = {
                propertyName: key,
                operation: data[key].operation,
                value: data[key].value,
                startDateRange: data[key].startDateRange,
                endDateRange: data[key].endDateRange
            };
            result.push(newObj);
        }
        return result;
    };

    const applyFilters = ({field, value, operation, startDateRange, endDateRange}) => {
        let result = buildFilters(search);

        if (field) {
            let newFilter = result.find(e => e.propertyName === field);
            if (newFilter) {
                newFilter.value = value;
            } else {
                result.push({
                    propertyName: field,
                    operation,
                    value,
                    startDateRange,
                    endDateRange
                });
            }
        }

        setFilters(result);
    };

    const updateFilters = ({field, value, operation, startDateRange, endDateRange}) => {
        setSearch(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value,
                operation,
                startDateRange,
                endDateRange
            },
        }));
    };

    const clearFilters = (field) => {
        let newSearch = JSON.parse(JSON.stringify(search));
        delete newSearch[field];
        setSearch(newSearch);
        setFilters(buildFilters(newSearch));
    };

    const onChangeQuickFilters = async (value) => {
        setQuickFilter(value);
    };

    const handleSortModelChange = (model) => {
        setSorting(model);
    };

    const handleRowSelect = (ids) => {
        setSelectedStudentIds(ids);
    };

    const sendInvites = () => {
        resendInvites(selectedStudentIds);
        setSelectedStudentIds([]);
    };

    const generateCsv = async () =>
        await downloadFile(
            downloadContactInvitesCsvReport,
            {filters, profileFilter: quickFilter, sorting: sorting[0] ?? defaultSorting});

    const generateXlsx = async () =>
        await downloadFile(
            downloadContactInvitesXlsxReport,
            {filters, profileFilter: quickFilter, sorting: sorting[0] ?? defaultSorting});

    return (
        <Box
            sx={{
                padding: {
                    xs: '16px 0px',
                    sm: '16px 0px'
                },
            }}
        >
            <Table
                columns={tableProps.columns}
                handleSortModelChange={handleSortModelChange}
                sortModel={sorting}
                checkboxSelection
                handleRowSelection={handleRowSelect}
                rowSelectionModel={selectedStudentIds}
                noRowsOverlay={{
                    icon: <PersonOffRoundedIcon/>,
                    text: 'No rows have been founded'
                }}
                toolbarIconActions={
                    [{
                        icon: <FileDownloadRoundedIcon/>,
                        title: 'Download report',
                        hideIfEmpty: true,
                        type: 'select',
                        menuItems: [
                            <MenuItem key={'actionContextMenu-csv'} onClick={generateCsv}>Download CSV</MenuItem>,
                            <MenuItem key={'actionContextMenu-xlsx'} onClick={generateXlsx}>Download XLSX</MenuItem>
                        ]
                    }]
                }
                toolbarActions={[
                    {
                        type: 'select',
                        title: 'Profile Type',
                        value: quickFilter,
                        handleChange: (event) => onChangeQuickFilters(event.target.value),
                        options: quickFilterOptions
                    },
                    ...(selectedStudentIds?.length > 0
                            ? [
                                {
                                    title: 'Resend Invite',
                                    type: 'button',
                                    icon: <SendIcon/>,
                                    onClick: () => sendInvites()
                                },
                            ]
                            : []
                    )
                ]}
                sortingMode="server"
            />
        </Box>
    );
};

export default ContactInvites;