import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

const BoxGrid = ({
                         index,
                         id,
                         value,
                         onChange,
                         description,
                         isEditMode,
                         enableValidation,
                         isFormArchived,
                         rows,
                         columns,
                         readOnly,
                         type
                     }) => {

    const handleToggle = (row, column) => {
        const updatedSelections = {...value};
        if (!updatedSelections[row.id]) {
            updatedSelections[row.id] = [];
        }
        if (updatedSelections[row.id].includes(column.id)) {
            updatedSelections[row.id] = updatedSelections[row.id].filter(item => item !== column.id);
        } else {
            updatedSelections[row.id].push(column.id);
        }
        onChange({id, value: updatedSelections});
    };

    const handleToggleRadio = (row, column) => {
        const updatedSelections = {...value};
        updatedSelections[row.id] = column.id;
        onChange({id, value: updatedSelections});
    };

    const isChecked = (row, column) => {
        return value && value[row.id]?.includes(column.id);
    };

    const isCheckedRadio = (row, column) => {
        return value && value[row.id] === column.id;
    };

    const buildEditableList = (data, listKey) =>
        <>
            {data.map((el, optIndex) => {
                return <Box display={'flex'} key={optIndex} alignItems={'center'}
                            justifyContent={'space-between'} width={'90%'}>
                    <Box display={'flex'} alignItems={'center'} gap={1} width={'90%'}>
                        <Typography>{optIndex + 1}.</Typography>
                        <TextField variant={'standard'} value={el.title} fullWidth onChange={e => onChange({
                            index,
                            option: e.target.value,
                            optionId: optIndex,
                            listKey
                        })}></TextField>
                    </Box>
                    <DeleteIcon
                        onClick={() => isFormArchived ? {} : onChange({index, isRemove: true, optionId: optIndex, listKey})}
                        disabled={isFormArchived} cursor={isFormArchived ? '' : 'pointer'}/>
                </Box>
            })}
            <Button onClick={() => onChange({index, isAdd: true, listKey})} startIcon={<AddIcon/>} cursor={'pointer'}
                    disabled={isFormArchived}>
                Add option
            </Button>
        </>

    return (
        <Box>
            {isEditMode
                ? <>
                    <TextField sx={{my: 1}} fullWidth value={description} multiline
                               onChange={e => onChange({index, description: e.target.value})}
                               label={!description ? 'Write a question' : null}
                               disabled={isFormArchived}
                               InputLabelProps={{
                                   shrink: false,
                               }}/>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box width={'100%'}>
                            <Typography fontWeight={'bold'}>Rows</Typography>
                            {buildEditableList(rows, 'rows')}
                        </Box>
                        <Box width={'100%'}>
                            <Typography fontWeight={'bold'}>Columns</Typography>
                            {buildEditableList(columns, 'columns')}
                        </Box>
                    </Box>
                    <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                      isFormArchived={isFormArchived}/>
                </>
                : <>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    {columns.map((column, index) => (
                                        <TableCell key={index} align="center">{column.title}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        {columns.map((column, columnIndex) => (
                                            <TableCell key={columnIndex} align="center">
                                                {type === 'tickBoxGrid'
                                                    ? <Checkbox
                                                        disabled={readOnly}
                                                        checked={isChecked(row, column)}
                                                        onChange={() => handleToggle(row, column)}
                                                    />
                                                    : <Radio disabled={readOnly}
                                                             key={`radioButton-${rowIndex}-${columnIndex}-${value && value[row.id] ? value[row.id] : ''}`}
                                                             checked={isCheckedRadio(row, column)}
                                                             onChange={() => handleToggleRadio(row, column)}
                                                    />
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Box>
    );
};

BoxGrid.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.array,
    columns: PropTypes.array,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.string
};

export default BoxGrid;