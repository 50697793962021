import React, {useEffect} from 'react';
import SchoolSetupForm from '../../SchoolManagement/Setup/SchoolSetupForm';
import SchoolMembersSetup from '../../SchoolManagement/Setup/SchoolMembersSetup';
import AdmicityTabs from '../../../shared-components/AdmicityTabs';
import MisIntegration from './MisIntegration/MisIntegration';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {useGetSchoolQuery, useUpdateSchoolMutation} from '../../../api/services/schoolManagementService';
import {showDetailedSnackbar, showSnackbar} from '../../../AppLayout/ApplicationSlice';
import {useDispatch} from 'react-redux';
import useUser from '../../../utility/hooks/useUser';
import AccessSettings from './AccessSettings/AccessSettings';
import ContactInvites from '../../SchoolManagement/Setup/ContactInvites';

const SchoolSettings = () => {
    const {user} = useUser();
    const dispatch = useDispatch();
    const {
        data: schoolInfo = {
            name: '',
            dfeNumber: '',
            notes: '',
            domain: ''
        },
        isError,
        refetch: refetchSchoolInfo
    } = useGetSchoolQuery(user.schoolId, {skip: !user.schoolId});
    const [saveSchool, {
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useUpdateSchoolMutation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isError) {
            dispatch(showSnackbar({message: 'Failed retrieve school info', severity: 'error'}));
        }
    }, [isError]);

    useEffect(() => {
        if (isFormSubmitError || isFormSubmitSuccess) {
            if (isFormSubmitSuccess) {
                dispatch(showSnackbar({
                    message: 'School saved successfully.',
                    severity: 'success'
                }));
                refetchSchoolInfo();
            }
            if (isFormSubmitError) {
                if (submitError.data.validationErrors) {
                    dispatch(showDetailedSnackbar({
                        severity: 'error',
                        title: 'Failed to save school.',
                        messages: submitError.data.validationErrors.map(e => e.errorMessage)
                    }));
                } else {
                    dispatch(showSnackbar({
                        message: 'Something went wrong.',
                        severity: 'error'
                    }));
                }
            }
        }
    }, [isFormSubmitSuccess, isFormSubmitError]);

    const handleSaveForm = async (payload) => {
        await saveSchool(payload);
    };

    const tabs = [
        {
            label: 'General',
            component: <SchoolSetupForm editMode schoolInfo={schoolInfo} onSubmit={handleSaveForm}/>
        },
        {
            label: 'MIS Integration',
            component: <MisIntegration/>
        },
        {
            label: 'Users',
            component: <SchoolMembersSetup schoolInfo={schoolInfo}/>
        },
        {
            label: 'Parent Invitations',
            component: <ContactInvites/>
        },
        {
            label: 'Manage access',
            component: <AccessSettings/>
        }
    ];

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            minHeight={'100%'}
        >
            <AdmicityTabs tabs={tabs} variant={isSmallScreen ? 'scrollable' : 'standard'}/>
        </Box>
    );
};

export default SchoolSettings;