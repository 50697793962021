import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    paginationModel: {pageNumber: 0, pageSize: 20}
};

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPaginationModel: (state, action) => {
            state.paginationModel = action.payload;
        },
        resetPaginationState: (state) => {
            state.paginationModel = initialState.paginationModel;
        },
    }
});

export const {setPaginationModel, resetPaginationState} = paginationSlice.actions;

export default paginationSlice.reducer;
