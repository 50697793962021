import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, CircularProgress} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import {useConfirm} from 'material-ui-confirm';
import {ROLES} from '../../../../../constants/roles';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import {grey} from '@mui/material/colors';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import {validateEmail, validateFieldLength} from '../../../../../utility/validationUtil';
import AdmicityForm from '../../../../../shared-components/AdmicityForm';
import {
    useAddContactMutation,
    useDeleteContactMutation,
    useGetContactsQuery,
    useManageConfirmMutation,
    useUpdateContactMutation
} from '../../../../../api/services/profilesService';
import useUser from '../../../../../utility/hooks/useUser';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import {formatDate} from '../../../../../utility/dateUtil';
import Typography from '@mui/material/Typography';
import {
    retrieveContactRelations,
    retrieveProfile
} from '../../ProfilesSlice';
import AdmicityTableV2 from '../../../../../shared-components/Table/V2/AdmicityTableV2';
import IconButton from '@mui/material/IconButton';
import useNotification from '../../../../../utility/hooks/useNotification';

/* eslint-disable react/prop-types */
const hiddenCellFormater = (cell, user) => {
    if (user.role === ROLES.PARENT && cell.row.original.userId !== user.id) {
        return <span style={{color: grey[500]}}>hidden</span>;
    }

    const value = cell.getValue();

    if (typeof value === 'boolean') {
        return value
            ? (<CheckIcon fontSize="small"/>)
            : (<CancelIcon fontSize="small" color="disabled"/>);
    }

    return cell.getValue();
};
/* eslint-enable react/prop-types */

const Contacts = ({readonly}) => {
    const {
        selectedProfile,
        contactRelations,
        currentSchool,
    } = useSelector(state => state.profilesInfo);
    const {studentId} = useParams();
    const [openCreateContactDialog, setOpenCreateContactDialog] = useState(false);
    const [openEditContactDialog, setOpenEditContactDialog] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
    const [isChildFormValid, setIsChildFormValid] = useState(false);
    const formRef = useRef(null);
    const {user} = useUser();
    const confirm = useConfirm();
    const dispatch = useDispatch();

    const [addContact, {isLoading: isAddContactInProgress}] = useAddContactMutation();
    const [updateContact, {isLoading: isUpdateContactInProgress}] = useUpdateContactMutation();
    const [deleteContact] = useDeleteContactMutation();
    const [manageConfirm] = useManageConfirmMutation();
    const userHasParentRole = user.role === ROLES.PARENT;
    const {showErrorNotification} = useNotification();

    const {
        data: contacts = {options: []},
        isLoading: isContactsLoading,
    } = useGetContactsQuery({studentId, role: user.role});

    useEffect(() => {
        dispatch(retrieveContactRelations());
    }, []);

    /* eslint-disable react/prop-types */
    const columnsV2 = useMemo(() =>
        [
            {
                accessorKey: 'forename',
                header: 'Forename'
            },
            {
                accessorKey: 'surname',
                header: 'Surname'
            },
            {
                accessorKey: 'phoneNumber',
                header: 'Phone Number',
                Cell: ({cell}) => hiddenCellFormater(cell, user)
            },
            {
                accessorKey: 'email',
                header: 'Email',
                Cell: ({cell}) => hiddenCellFormater(cell, user)
            },
            {
                accessorKey: 'relationType',
                header: 'Relation Type',
                Cell: ({cell}) => userHasParentRole && cell.row.original.userId !== user.id
                    ? <span style={{color: grey[500]}}>hidden</span>
                    : cell.getValue()
            },
            {
                accessorKey: 'priority',
                header: 'Priority',
                Cell: ({cell}) => userHasParentRole && cell.row.original.userId !== user.id
                    ? <span style={{color: grey[500]}}>hidden</span>
                    : cell.getValue()
            },
            {
                accessorKey: 'legalOrder',
                header: 'Legal Order',
                Cell: ({cell}) => hiddenCellFormater(cell, user)
            },
            {
                accessorKey: 'parentalResponsibility',
                header: 'Parental Responsibility',
                Cell: ({cell}) => hiddenCellFormater(cell, user)
            }
        ], [user]);
    /* eslint-enable react/prop-types */

    const handleEditClick = (contact) => {
        setSelectedContact(contact);
        setOpenEditContactDialog(true);
    };

    const handleDeleteClick = async (contact) => {
        await deleteContact({studentId, contactId: contact.id});
    };

    const handleDialogClose = () => {
        setOpenCreateContactDialog(false);
        setOpenEditContactDialog(false);
        setSelectedContact({});
    };

    const handleSaveContact = async (payload) => {
        confirm({
            title: 'Confirmation',
            description: `I confirm that the information in the contact section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
            confirmationText: 'Confirm'
        })
            .then(async () => {
                if (openCreateContactDialog) {
                    await addContact({
                        studentId,
                        role: user.role,
                        body: {studentId, ...payload}
                    }).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
                } else {
                    await updateContact({
                        studentId,
                        role: user.role,
                        body: {studentId, contact: {...selectedContact, ...payload}}
                    }).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
                }

                setSelectedContact({});
                handleDialogClose();
            })
            .catch(() => {
            });
    };

    const handleSaveContactByParent = async payload =>
        confirm({
            description: 'Please check the new contact information you have provided carefully, as it will import directly into the school’s information management system and you will not be able to view or amend the information in your Admicity parent account after it has been submitted.',
            confirmationText: 'Confirm',
            title: 'Confirmation',
        })
            .then(async () => {
                if (openCreateContactDialog) {
                    await addContact({
                        studentId,
                        role: user.role,
                        body: {studentId, ...payload}
                    }).then(
                        (result) => {
                            if (result.error) {
                                showErrorNotification(result.error.data?.validationErrors.map(x => x.errorMessage));
                                return;
                            }
                            setSelectedContact({});
                            handleDialogClose();
                            dispatch(retrieveProfile({studentId: selectedProfile.id}));
                        }
                    );
                } else {
                    await updateContact({
                        studentId,
                        role: user.role,
                        body: {studentId, contact: {...selectedContact, ...payload}}
                    }).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
                    setSelectedContact({});
                    handleDialogClose();
                }
            })
            .catch(() => {
            });

    const handleConfirm = () => {
        confirm({
            title: 'Confirmation',
            description: `I confirm that the information in the contact section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
            confirmationText: 'Confirm'
        })
            .then(async () => {
                await manageConfirm({studentId: selectedProfile.id, type: STUDENT_PROFILE_CONFIRMATION_TYPES.Contacts})
                    .then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
            })
            .catch(() => {
            });
    };

    const validateUniqueEmail = (value, existingContacts, currentEmail) => {
        const emailValidationResults = validateEmail(value);

        if (!emailValidationResults.isValid || value === currentEmail) {
            return emailValidationResults;
        }
        /* eslint-disable */
        const existingContactWithEmail = existingContacts.find(contact =>
            contact.email?.trim().toUpperCase() === value.trim().toUpperCase()
        );
        /* eslint-enable */

        const isValid = !existingContactWithEmail;
        const message = isValid ? '' : 'Contact with the same email already exist';

        return {isValid, message};
    };

    const addOrEditContactFormTextFields = useMemo(() => {
        const textFields = [
            {
                label: 'Forename',
                name: 'forename',
                validate: (value) => validateFieldLength(value, 128)
            },
            {
                label: 'Surname',
                name: 'surname',
                validate: (value) => validateFieldLength(value, 128)
            },
            {
                label: 'Email',
                name: 'email',
                type: 'email',
                validate: (value) => validateUniqueEmail(value, contacts.contacts, selectedContact.email),
                onChangeWarningMessage: 'Changing email will result in losing access to student profile. Account registration process will be triggered for new email.'
            },
            {
                label: 'Phone Number',
                name: 'phoneNumber',
                validate: (value) => validateFieldLength(value, 20, false),
                required: false
            },
            {
                label: 'Relation Type',
                name: 'relationType',
                select: true,
                options: contactRelations.map(element => ({
                    value: element.description,
                    title: element.description
                }))
            },
            {
                label: 'Priority',
                name: 'priority',
                validate: (value) => {
                    let errorMessage = '';

                    if (!value || value.length === 0) {
                        errorMessage = 'This field is required.';
                    } else if (!/^-?[0-9]+$/.test(value)) {
                        errorMessage = 'Only numbers are allowed.';
                    } else if (parseInt(value) <= 0) {
                        errorMessage = 'The Priority cannot be less than 1';
                    } else if (parseInt(value) > 10) {
                        errorMessage = 'The Priority cannot be more than 10';
                    }

                    return {
                        isValid: errorMessage === '',
                        message: errorMessage,
                    };
                },
                disabled: userHasParentRole
            }
        ];

        if (openCreateContactDialog) {
            return userHasParentRole
                ? textFields.filter(x => x.name !== 'priority')
                : textFields;
        }

        if (openEditContactDialog) {
            return userHasParentRole
                ? textFields
                    .filter(x => x.name !== 'priority' && x.name !== 'relationType' && x.name !== 'surname' && x.name !== 'forename')
                    .map(x => ({...x, initialValue: selectedContact[x.name] ?? ''}))
                : textFields.map(x => ({...x, initialValue: selectedContact[x.name] ?? ''}));
        }

        return [];
    }, [openCreateContactDialog, openEditContactDialog]);

    const addOrEditContactFormOptions = useMemo(() => {
        const options = [
            {
                label: 'Legal Order',
                name: 'legalOrder',
            },
            {
                label: 'Parental Responsibility',
                name: 'parentalResponsibility',
            }
        ];

        if (openCreateContactDialog) {
            return userHasParentRole
                ? []
                : options;
        }

        if (openEditContactDialog) {
            return userHasParentRole
                ? []
                : options.map(x => ({...x, initialValue: selectedContact[x.name] ?? ''}));
        }

        return [];
    }, [openCreateContactDialog, openEditContactDialog]);

    return (
        isContactsLoading
            ? ''
            : <>
                <Box
                    marginTop={1}
                    marginBottom={1}
                >
                    {!readonly
                        ? <Button
                            color="primary"
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon/>}
                            onClick={() => setOpenCreateContactDialog(true)}>
                            Add contact
                        </Button>
                        : <></>
                    }
                    {!contacts?.contactsConfirmedAt
                        ? <Button sx={{mb: 2, my: 2, ml: 1}} variant="contained"
                                  onClick={handleConfirm}>Confirm</Button>
                        : <Typography sx={{mb: 2, mt: 2, ml: 1, display: 'inline-block'}}>Confirmed
                            at {formatDate(contacts?.contactsConfirmedAt)} by {contacts?.contactsConfirmedBy}</Typography>
                    }
                </Box>
                <AdmicityTableV2
                    columns={columnsV2}
                    data={contacts.contacts ?? []}
                    noRowsOverlay={{
                        icon: <ContactEmergencyRoundedIcon/>,
                        text: 'No contacts has been added'
                    }}
                    tableProps={{
                        enableSorting: false,
                        enableColumnResizing: false,
                        enableBottomToolbar: false,
                        enableTopToolbar: false,
                        enableRowActions: !readonly,
                        muiTableContainerProps: {
                            sx: {
                                maxHeight: 500,
                                minHeight: 'auto'
                            }
                        },
                        muiTableBodyRowProps: {hover: false},
                        renderRowActions: ({row}) =>
                            (
                                <Box
                                    display="flex"
                                    flexWrap="nowrap"
                                    gap={1}
                                >
                                    {
                                        (
                                            !readonly &&
                                            (user.role === ROLES.MEMBER_OF_STAFF || user.role === ROLES.SCHOOL_ADMIN)
                                                ? [
                                                    {
                                                        icon: <EditIcon/>,
                                                        onClick: () => handleEditClick(row.original)
                                                    },
                                                    {
                                                        icon: <DeleteIcon/>,
                                                        onClick: () => handleDeleteClick(row.original)
                                                    }
                                                ]
                                                : !readonly && user.id === row.original.userId
                                                    ? [
                                                        {
                                                            icon: <EditIcon/>,
                                                            onClick: () => handleEditClick(row.original)
                                                        }
                                                    ]
                                                    : []
                                        ).map((action, index) => (
                                            <IconButton
                                                key={`contacts_action_${index}`}
                                                onClick={action.onClick}>
                                                {action.icon}
                                            </IconButton>
                                        ))
                                    }
                                </Box>)
                    }}
                />
                <AdmicityDialog
                    handleClose={handleDialogClose}
                    title={openCreateContactDialog ? 'Add new contact' : 'Edit contact'}
                    open={openCreateContactDialog || openEditContactDialog}
                    actions={[
                        {label: 'Cancel', onClick: handleDialogClose},
                        {
                            label: 'Save',
                            disabled: isAddContactInProgress || isUpdateContactInProgress || !isChildFormValid,
                            startIcon: (isAddContactInProgress || isUpdateContactInProgress)
                                ? <CircularProgress color="inherit" size={16}/>
                                : undefined,
                            onClick: () => {
                                if (typeof formRef.current.requestSubmit === 'function') {
                                    formRef.current.requestSubmit();
                                } else {
                                    formRef.current.dispatchEvent(new Event('submit', {cancelable: true}));
                                }
                            }
                        }
                    ]}
                >
                    <AdmicityForm
                        ref={formRef}
                        textFields={addOrEditContactFormTextFields}
                        options={addOrEditContactFormOptions}
                        handleSubmit={data =>
                            userHasParentRole
                                ? handleSaveContactByParent(data)
                                : handleSaveContact(data)}
                        buttonText="Save"
                        setIsChildFormValid={setIsChildFormValid}
                        actionsContainerProps={{
                            container: {
                                display: 'none'
                            }
                        }}
                        showWarningMessage={!openCreateContactDialog}
                    />
                </AdmicityDialog>
            </>
    );
};

Contacts.propTypes = {
    readonly: PropTypes.bool
};

export default Contacts;
