const ROLES = {
    SUPER_ADMIN: 'SuperAdmin',
    SCHOOL_ADMIN: 'SchoolAdmin',
    MEMBER_OF_STAFF: 'MemberOfStaff',
    PARENT: 'Parent',
    NONE: 'None'
};

const ROLES_LABELS = {
    [ROLES.SUPER_ADMIN]: 'Super Admin',
    [ROLES.SCHOOL_ADMIN]: 'School Admin',
    [ROLES.MEMBER_OF_STAFF]: 'Member Of Staff',
    [ROLES.PARENT]: 'Parent',
    [ROLES.NONE]: 'None'
};

const ROLE_IDS = {
    [ROLES.SUPER_ADMIN]: 1,
    [ROLES.SCHOOL_ADMIN]: 2,
    [ROLES.MEMBER_OF_STAFF]: 3,
    [ROLES.PARENT]: 4
};

const ROLE_BASE_ROUTE = {
    [ROLES.SUPER_ADMIN]: '/management',
    [ROLES.SCHOOL_ADMIN]: '/school',
    [ROLES.MEMBER_OF_STAFF]: '/profiles',
    [ROLES.PARENT]: '/profiles',
    [ROLES.NONE]: '/login'
}

export {
    ROLES,
    ROLE_BASE_ROUTE,
    ROLE_IDS,
    ROLES_LABELS
}