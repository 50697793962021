import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ROLE_BASE_ROUTE, ROLES} from '../constants/roles';
import useUser from '../utility/hooks/useUser';
import {getRefreshToken, getToken} from '../utility/jwtUtil';

const ProtectedRoute = ({children, allowedRoles}) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const userContext = useUser();

    const getNavigationProps = () => {
        if (!getRefreshToken() || !getToken()) {
            return {route: ROLE_BASE_ROUTE[ROLES.NONE], args: {state: {redirectTo: pathname}}};
        }

        if (userContext?.user) {
            if (!allowedRoles.includes(userContext.user.role)) {
                return {route: ROLE_BASE_ROUTE[userContext.user.role], args: {replace: true}};
            }
        }
    };

    useEffect(() => {
        const navProps = getNavigationProps();

        if (navProps) {
            navigate(navProps.route, navProps.args ?? {});
        }
    }, [userContext, pathname]);

    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.object.isRequired,
    allowedRoles: PropTypes.array.isRequired
};

export default ProtectedRoute;
