import {apiService} from '../apiService';
import {axiosBaseQuery} from '../axiosBaseQuery';

const baseQuery = axiosBaseQuery({baseUrl: process.env.REACT_APP_BACKEND_URL}, {responseType: 'blob'});

export const downloadStudentsXlsxReport = (body) =>
    download({
        url: '/student/xlsx',
        method: 'POST',
        data: body
    });

export const downloadStudentsCsvReport = (body) =>
    download({
        url: '/student/csv',
        method: 'POST',
        data: body
    });

export const downloadFormDocument = ({documentId}) =>
    download({
        url: `/forms/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadFormResponsesCsvReport = (body) =>
    download({
        url: `/forms/${body.formId}/requests/csv`,
        method: 'POST',
        data: body
    });

export const downloadFormResponsesXlsxReport = (body) =>
    download({
        url: `/forms/${body.formId}/requests/xlsx`,
        method: 'POST',
        data: body
    });

export const downloadSchoolDocument = ({documentId}) =>
    download({
        url: `/school/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileSenDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/sen/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileEhcpDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/ehcp/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileMedicalConditionDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileMedicalEventDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/event/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileMedicalNoteDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/note/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileVaccinationDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/vaccination/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadContactInvitesCsvReport = (body) =>
    download({
        url: '/school/contact-invites/csv',
        method: 'POST',
        data: body
    });

export const downloadContactInvitesXlsxReport = (body) =>
    download({
        url: '/school/contact-invites/xlsx',
        method: 'POST',
        data: body
    });

const download = async (config) => {
    try {
        const result = await baseQuery(config);
        const filename = result.headers['content-disposition']
            ?.split('filename=')[1]
            ?.split(';')[0]
            ?.replace(/["']/g, '') ?? 'unknown.ext';

        return {
            filename,
            blob: result.data
        };
    } catch (e) {
        return {
            error: e
        };
    }
};

export const filesService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            uploadSchoolDocuments: builder.mutation({
                query: (files) => {
                    const formData = new FormData();

                    files.forEach(file => formData.append('files[]', file));

                    return ({
                        method: 'POST',
                        url: '/school/upload-documents',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: [{type: 'SchoolDocuments', id: 'LIST'}],
            }),
            uploadFormDocuments: builder.mutation({
                query: (files) => {
                    const formData = new FormData();

                    files.forEach(file => formData.append('files[]', file));

                    return ({
                        method: 'POST',
                        url: '/forms/upload-documents',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: ['FormDocument'],
            }),
            deleteSchoolDocument: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/school/delete-document',
                    data: body
                }),
                invalidatesTags: [{type: 'SchoolDocuments', id: 'LIST'}],
            }),
            deleteFormDocument: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/forms/delete-document',
                    data: body
                }),
                invalidatesTags: ['FormDocument'],
            }),
            getSchoolDocuments: builder.query({
                query: (body) => ({
                    url: '/school/documents',
                    method: 'POST',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve documents'}),
                providesTags: (_) => [{type: 'SchoolDocuments', id: 'LIST'}]
            })
        })
});

export const {
    useUploadSchoolDocumentsMutation,
    useUploadFormDocumentsMutation,
    useDeleteSchoolDocumentMutation,
    useDeleteFormDocumentMutation,
    useGetSchoolDocumentsQuery,
} = filesService;
