import React, {useState} from 'react';
import {Menu} from '@mui/material';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';

const AdmicityContextMenu = ({title, icon, menuItems}) => {
    const [actionContextMenuAnchor, setActionContextMenuAnchor] = useState(null);

    const handleCloseActionContextMenu = () => {
        setActionContextMenuAnchor(null);
    };

    const handleOpenActionContextMenu = (event) => {
        setActionContextMenuAnchor(event.currentTarget);
    };

    return (
        <>
            <IconButton aria-label={title} onClick={handleOpenActionContextMenu}>
                {icon}
            </IconButton>
            <Menu anchorEl={actionContextMenuAnchor}
                  open={Boolean(actionContextMenuAnchor)}
                  onClose={handleCloseActionContextMenu}>
                {menuItems}
            </Menu>
        </>
    );
};

AdmicityContextMenu.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    menuItems: PropTypes.array,
};

export default AdmicityContextMenu;