import React from 'react';
import {Box, Button, Container, Typography} from '@mui/material';

export default function NotFoundPage() {
    return (
        <Container maxWidth="sm">
            <Box sx={{my: 12, textAlign: 'center'}}>
                <Typography variant="h3" gutterBottom>
                    404
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Page Not Found
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    The page you are looking for might have been removed, had its name changed, or is temporarily
                    unavailable.
                </Typography>
                <Button variant="contained" color="primary" href="/">
                    Go to Homepage
                </Button>
            </Box>
        </Container>
    );
}
