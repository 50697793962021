import {apiService} from '../apiService';

export const studentService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getDietaryNeeds: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/special-dietary-needs`
                }),
                providesTags: (_) => [{type: 'DietaryNeeds', id: 'LIST'}]
            }),
            manageDietaryNeeds: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/special-dietary-needs`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'DietaryNeeds', id: 'LIST'}]
            }),
            getWelfare: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/welfare`
                }),
                providesTags: (_) => [{type: 'Welfare', id: 'LIST'}]
            }),
            manageWelfare: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/welfare`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Welfare', id: 'LIST'}]
            }),
            getFreeSchoolMeals: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/free-meals`
                }),
                providesTags: (_) => [{type: 'FreeSchoolMeals', id: 'LIST'}]
            }),
            manageFreeSchoolMeals: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/free-meals`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FreeSchoolMeals', id: 'LIST'}]
            }),
            getMedicalInfo: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/medical`
                }),
                providesTags: (_) => ['MedicalInfo']
            }),
            manageMedicalSection: builder.mutation({
                query: ({
                            studentId,
                            medicalConditions,
                            gpDetails,
                            vaccination,
                            deletedVaccinationFileId,
                            selectedConsents,
                            deselectedConsents,
                            isNotApplicable
                        }) => {
                    const formData = new FormData();
                    formData.append('studentId', studentId);

                    if (vaccination !== null) {
                        formData.append('vaccination', vaccination);
                    }

                    if (isNotApplicable !== null) {
                        formData.append('isNotApplicable', isNotApplicable);
                    }

                    if (gpDetails !== null) {
                        formData.append('gpDetails', gpDetails === '' ? 'EMPTY' : gpDetails);
                    }

                    if (deletedVaccinationFileId) {
                        formData.append('deletedVaccinationFileId', deletedVaccinationFileId);
                    }

                    selectedConsents?.forEach((id, index) => {
                        formData.append(`selectedMedicalConsents[${index}]`, id);
                    });

                    deselectedConsents?.forEach((id, index) => {
                        formData.append(`deselectedMedicalConsents[${index}]`, id);
                    });

                    medicalConditions?.forEach((note, index) => {
                        formData.append(`MedicalConditions[${index}].medicalInfoId`, note.medicalInfoId);
                        if (note.medicalConditionId) {
                            formData.append(`MedicalConditions[${index}].medicalConditionId`, note?.id);
                        }

                        if (note.documents) {
                            note.documents.forEach((document, docIndex) => {
                                if (document.id !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Id`, document.id);
                                }
                                if (document.fileId !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].FileId`, document.fileId);
                                }
                                if (document.summary !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Summary`, document.summary);
                                }
                                if (document.note !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].Note`, document.note);
                                }
                                if (document.file !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].File`, document.file);
                                }

                                if (document.isActive !== undefined) {
                                    formData.append(`medicalConditions[${index}].Documents[${docIndex}].IsActive`, document.isActive);
                                }
                            });
                        }

                        formData.append(`MedicalConditions[${index}].isActive`, note.isActive);
                    });

                    return ({
                        method: 'POST',
                        url: `/student/${studentId}/medical`,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: ['MedicalInfo'],
            }),
            getSenNeeds: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/sen`
                }),
                providesTags: (_) => ['SenNeeds']
            }),
            manageSenNeeds: builder.mutation({
                query: ({
                            studentId,
                            senNeeds,
                            senStatus,
                            senNeedInvestigating,
                            addedSenDocuments,
                            deletedSenDocuments,
                            ehcpFile
                        }) => {
                    const formData = new FormData();

                    deletedSenDocuments.forEach((docId, index) => {
                        formData.append(`deletedSenDocuments[${index}]`, docId);
                    });

                    addedSenDocuments.forEach((doc, index) => {
                        formData.append(`addedSenDocuments[${index}].summary`, doc.summary);
                        formData.append(`addedSenDocuments[${index}].file`, doc.file);
                        formData.append(`addedSenDocuments[${index}].fileName`, doc.file.name);
                    });

                    if (ehcpFile) {
                        formData.append('addedEhcpDocument.file', ehcpFile);
                        formData.append('addedEhcpDocument.fileName', ehcpFile.name);
                    }

                    senNeeds.forEach((senNeed, index) => {
                        formData.append(`senNeeds[${index}].isActive`, senNeed.isActive);
                        formData.append(`senNeeds[${index}].name`, senNeed.name);
                        if (senNeed.note) {
                            formData.append(`senNeeds[${index}].note`, senNeed.note);
                        }
                        formData.append(`senNeeds[${index}].senNeedId`, senNeed.senNeedId);

                        if (senNeed.id) {
                            formData.append(`senNeeds[${index}].id`, senNeed.id);
                        }
                    });

                    if (senNeedInvestigating.id) {
                        formData.append('senNeedInvestigating.id', senNeedInvestigating.id);
                    }
                    formData.append('senNeedInvestigating.isChecked', senNeedInvestigating.isChecked);

                    if (senNeedInvestigating.note !== null) {
                        formData.append('senNeedInvestigating.note', senNeedInvestigating.note);
                    }

                    formData.append('senStatus', senStatus);
                    formData.append('studentId', studentId);

                    return {
                        url: `/student/${studentId}/sen`,
                        method: 'POST',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };
                },
                invalidatesTags: ['SenNeeds']
            }),
            getTravelMode: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/travel`
                }),
                providesTags: (_) => [{type: 'Travel', id: 'VALUE'}]
            }),
            updateTravelMode: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/travel`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Travel', id: 'VALUE'}]
            }),
            getConsents: builder.query({
                query: (studentId) => ({
                    url: `/student/${studentId}/consent`
                }),
                providesTags: (_) => [{type: 'Consents', id: 'LIST'}]
            }),
            updateConsents: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/consent`,
                    method: 'PATCH',
                    data: body,
                }),
                invalidatesTags: [{type: 'Consents', id: 'LIST'}]
            })
        }),
});

export const {
    useGetDietaryNeedsQuery,
    useGetWelfareQuery,
    useManageDietaryNeedsMutation,
    useManageWelfareMutation,
    useGetFreeSchoolMealsQuery,
    useManageFreeSchoolMealsMutation,
    useGetMedicalInfoQuery,
    useManageMedicalSectionMutation,
    useGetSenNeedsQuery,
    useManageSenNeedsMutation,
    useGetTravelModeQuery,
    useUpdateTravelModeMutation,
    useGetConsentsQuery,
    useUpdateConsentsMutation
} = studentService;
