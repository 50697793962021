import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {resetPaginationState} from '../../../hoc/pagination/paginationSlice';
import {resetProfileFilters, setFilterId} from './ProfilesSlice';

const ProfileTab = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetPaginationState());
            dispatch(setFilterId(undefined));
            dispatch(resetProfileFilters());
        };
    }, []);

    return <Outlet/>;
};

export default ProfileTab;
