const INVITE_STATUSES = {
    pending: 1,
    completed: 2,
    expired: 3,
    failed: 4
};

const INVITE_STATUSES_NAMES = {
    [INVITE_STATUSES.pending]: 'Pending',
    [INVITE_STATUSES.completed]: 'Completed',
    [INVITE_STATUSES.expired]: 'Expired',
    [INVITE_STATUSES.failed]: 'Failed'
};

export {
    INVITE_STATUSES,
    INVITE_STATUSES_NAMES
};