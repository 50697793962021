/* eslint-disable react/prop-types */

import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {Button, Divider, List, ListItem, ListItemText, Popover, Stack, Switch} from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const CustomShowHideMenuItems = ({table}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'custom-show-hide-menu' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                aria-describedby={id}
                onClick={handleClick}
            ><ViewColumnIcon/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            maxWidth: {
                                md: '50vw',
                                lg: '50vw'
                            },
                            maxHeight: '70vh'
                        }
                    }
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    padding={1}
                >
                    <Button onClick={() => table.toggleAllColumnsVisible(true)}>Show all</Button>
                    <Button onClick={() => table.toggleAllColumnsVisible(false)}>Hide All</Button>
                </Stack>
                <Divider/>
                <List>
                    {table.getAllLeafColumns()
                        .filter(column => column.id !== 'mrt-row-spacer' && column.id !== 'mrt-row-actions')
                        .map(column => (
                            <ListItem key={column.id}>
                                <ListItemText
                                    primary={column.columnDef.header}
                                    primaryTypographyProps={{
                                        sx: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                />
                                <Switch
                                    edge="end"
                                    onChange={column.getToggleVisibilityHandler()}
                                    checked={column.getIsVisible()}
                                />
                            </ListItem>
                        ))}
                </List>
            </Popover>
        </>
    );
};

export default CustomShowHideMenuItems;