import React, {useState} from 'react';
import {Box, Button, CircularProgress, styled, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import {FileDownload, UploadFile} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {
    downloadFormDocument,
    useDeleteFormDocumentMutation,
    useUploadFormDocumentsMutation
} from '../../../../../api/services/filesService';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';

const DownloadIcon = styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const DeleteAttachmentIcon = styled(DeleteIcon)(({theme}) => ({
    color: theme.palette.primary.main
}));

const Attachment = ({index, onChange, description, isEditMode, attachments, isFormArchived}) => {
    const [error, setError] = useState('');
    const [uploadFiles] = useUploadFormDocumentsMutation();
    const [deleteFile] = useDeleteFormDocumentMutation();
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const downloadFile = useFileDownloader();

    const handleFilesUpload = async () => {
        setIsUploading(true);
        let uploadedFiles = await uploadFiles(selectedFiles);
        onChange({index, files: [...attachments, ...uploadedFiles.data]});
        handleDialogClose();
    };

    const deleteAttachment = async (fileId) => {
        try {
            await deleteFile({documentId: fileId});
            onChange({index, files: attachments.filter(file => file.id !== fileId)});

            setError('');
        } catch (error) {
            setError('Something went wrong. Please try again later');
        }
    };

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
    };

    const handleFilesSelect = (files) => {
        const newFiles = files.filter(file => !selectedFiles.some(s => s.name === file.name) && !attachments.some(s => s.name === file.name));
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };

    return (
        <Box>
            {isEditMode
                ? <>
                    <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFile/>}
                        onClick={() => setOpenAddDocumentsDialog(true)}
                        disabled={isFormArchived}
                    >
                        Choose Files
                    </Button>

                    {error && <Typography key={`error-${error}`} color={'error'}>{error}</Typography>}

                    {attachments.map((file) =>
                        <Box py={1} display={'flex'} justifyContent={'space-between'} key={`file-${file.name}`}>
                            <Typography>{file.name}</Typography>
                            <DeleteAttachmentIcon cursor={isFormArchived ? '' : 'pointer'}
                                                  onClick={() => isFormArchived ? {} : deleteAttachment(file.id)}/>
                        </Box>
                    )}

                    <TextField sx={{mt: 2}} fullWidth label={!description ? 'Write instructions for user' : null}
                               InputLabelProps={{
                                   shrink: false,
                               }}
                               disabled={isFormArchived}
                               multiline value={description}
                               onChange={e => onChange({index, description: e.target.value})}/>

                    <AdmicityDialog
                        handleClose={handleDialogClose}
                        title={'Add documents'}
                        open={openAddDocumentsDialog}
                        actions={[
                            {label: 'Cancel', onClick: handleDialogClose},
                            {
                                label: 'Upload',
                                onClick: handleFilesUpload,
                                disabled: (isUploading || selectedFiles.size === 0) || isUploadSizeExceedsLimit,
                                startIcon: isUploading ? <CircularProgress color="inherit" size={16}/> : undefined
                            }
                        ]}
                        maxWidth={'md'}
                    >
                        <Uploader
                            totalUploadSizeInMb={25}
                            onFilesSelect={handleFilesSelect}
                            onFileDelete={handleFileDeleteFromUploadContext}
                            onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                            value={selectedFiles}
                            readonly={isUploading}
                        />
                    </AdmicityDialog>
                </>
                : <>
                    <Typography>{description}</Typography>
                    {attachments?.map((file) =>
                        <Box py={1} display={'flex'} justifyContent={'space-between'} key={`file-${file.name}`}>
                            <Typography fontWeight={'bold'}>{file.name}</Typography>
                            <DownloadIcon
                                onClick={async () => await downloadFile(downloadFormDocument, {documentId: file.id})}/>
                        </Box>
                    )}
                </>
            }
        </Box>
    );
};

Attachment.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    attachments: PropTypes.array
};

export default Attachment;