import {configureStore} from '@reduxjs/toolkit';
import profilesReducer from '../pages/SchoolDashboard/Profiles/ProfilesSlice';
import applicationReducer from '../AppLayout/ApplicationSlice';
import schoolManagementReducer from '../pages/SchoolManagement/SchoolManagementPageSlice';
import misIntegrationReducer from '../pages/SchoolDashboard/SchoolSettings/MisIntegration/MisIntegrationSlice';
import paginationReducer from '../hoc/pagination/paginationSlice';
import {apiService} from '../api/apiService';
import tableReducer from './tableSlice';

export const store = configureStore({
    reducer: {
        profilesInfo: profilesReducer,
        application: applicationReducer,
        schoolManagement: schoolManagementReducer,
        misIntegration: misIntegrationReducer,
        pagination: paginationReducer,
        [apiService.reducerPath]: apiService.reducer,
        table: tableReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiService.middleware)
});