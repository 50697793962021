import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Checkbox, CircularProgress, Container, Divider, styled} from '@mui/material';
import {addDocument, deleteStudentDocument, resetUpdatingStatus, retrieveDocuments} from '../ProfilesSlice';
import SpinnerContainer from '../../../../shared-components/SpinnerContainer';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {FileDownload, UploadFile} from '@mui/icons-material';
import {ROLES} from '../../../../constants/roles';
import Grid from '@mui/material/Grid';
import useUser from '../../../../utility/hooks/useUser';
import {
    downloadProfileDocument,
    downloadSchoolDocument,
    useGetSchoolDocumentsQuery
} from '../../../../api/services/filesService';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import DocumentList from '../Components/DocumentList';

const DocumentFields = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '12px 0px'
});

styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const ProfileDocumentsTab = () => {
    const {user} = useUser();
    const {selectedProfile, isDocumentDataLoading, updatingSuccessful} = useSelector(state => state.profilesInfo);
    const [documents, setDocuments] = useState({
        profileDocuments: [],
        schoolDocuments: []
    });
    const [documentsPaginationModels, setDocumentsPaginationModels] = useState({
        schoolDocuments: {
            pageNumber: 0,
            pageSize: 20,
            hasMore: true
        }
    });
    const {
        data: schoolDocuments,
        isLoading: isSchoolDocumentsLoading
    } = useGetSchoolDocumentsQuery(documentsPaginationModels.schoolDocuments);
    const {studentId} = useParams();
    const dispatch = useDispatch();
    const [isAddMode, setIsAddMode] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [isForParent, setDocumentForParent] = useState(false);

    const downloadFile = useFileDownloader();

    useEffect(() => {
        dispatch(retrieveDocuments({studentId, role: user.role}));
    }, []);

    useEffect(() => {
        if (selectedProfile.documentData) {
            setDocuments(currentDocuments => ({
                ...currentDocuments,
                profileDocuments: selectedProfile.documentData
            }));
        }
    }, [selectedProfile.documentData]);

    useEffect(() => {
        if (schoolDocuments && !isSchoolDocumentsLoading) {
            setDocuments(currentDocuments => ({
                ...currentDocuments,
                schoolDocuments: [...currentDocuments.schoolDocuments, ...schoolDocuments.items]
            }));
            setDocumentsPaginationModels(currentModels => ({
                ...currentModels,
                schoolDocuments: {
                    ...currentModels.schoolDocuments,
                    hasMore: schoolDocuments.items.length > 0
                }
            }));
        }
    }, [schoolDocuments, isSchoolDocumentsLoading]);

    useEffect(() => {
        showNotification(updatingSuccessful);
        if (updatingSuccessful) {
            dispatch(retrieveDocuments({studentId, role: user.role}));
        }
        dispatch(resetUpdatingStatus());
    }, [updatingSuccessful]);

    const showNotification = (success) => success !== undefined && dispatch({
        true: () => showSnackbar({message: 'Document updated successfully', severity: 'success'}),
        false: () => showSnackbar({message: 'Failed to update documents', severity: 'error'})
    }[success]());

    const handleChangeCheckbox = (event) => {
        setDocumentForParent(event.target.checked);
    };

    const addFile = (value) => {
        if (!value) return;
        setNewFile(value);
        setIsAddMode(true);
    };

    const saveNewRow = () => {
        dispatch(addDocument({studentId, isForParent, file: newFile}));
        setNewFile(null);
        setDocumentForParent(false);
        setIsAddMode(false);
    };

    const cancelCreating = () => {
        setNewFile(null);
        setDocumentForParent(false);
        setIsAddMode(false);
    };

    const deleteDocument = (document) => {
        dispatch(deleteStudentDocument({studentId, documentId: document.id}));
    };

    const addNewButtonComponent = () => <>
        {!isAddMode && <Button component="label" variant="contained" startIcon={<UploadFile/>} style={{width: 200}}>
            Add document
            <input type="file" hidden onChange={event => addFile(event.target.files[0])}/>
        </Button>}
        {newFile && isAddMode &&
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Typography>{newFile.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between" alignItems="center">
                    <Grid item xs={12} sm={6} container alignItems="center">
                        <Typography>Available for parent</Typography>
                        <Checkbox checked={isForParent} onChange={event => handleChangeCheckbox(event)}/>
                    </Grid>
                    <Grid item xs={12} sm={6} container display={'flex'} justifyContent={'end'} alignItems="center">
                        <Grid item xs={5} sm={5}>
                            <Button variant="outlined" onClick={() => cancelCreating()} style={{width: '100px'}}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <Button variant="contained" onClick={() => saveNewRow()} style={{width: '100px'}}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        <Divider sx={{marginTop: 2, marginBottom: 2}}/>
    </>;

    const handleScrollSchoolDocumentList = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;

        if (documentsPaginationModels.schoolDocuments.hasMore &&
            scrollHeight - scrollTop === clientHeight &&
            !isSchoolDocumentsLoading
        ) {
            setDocumentsPaginationModels(currentModels => ({
                ...currentModels,
                schoolDocuments: {
                    ...currentModels.schoolDocuments,
                    pageNumber: currentModels.schoolDocuments.pageNumber + 1
                }
            }));
        }
    };

    const documentsMap = [
        {
            sectionName: 'Profiles Documents',
            documents: documents.profileDocuments,
            allowDelete: true,
            onDownload: async documentId => await downloadFile(downloadProfileDocument, {documentId, studentId}),
            onDelete: deleteDocument
        },
        {
            sectionName: 'School Documents',
            documents: documents.schoolDocuments,
            paginationHandler: handleScrollSchoolDocumentList,
            onDownload: async documentId => await downloadFile(downloadSchoolDocument, {documentId})
        }
    ];

    return <>
        {isDocumentDataLoading
            ? <SpinnerContainer>
                <CircularProgress size={70}/>
            </SpinnerContainer>
            : <DocumentFields>
                {user.role !== ROLES.PARENT && addNewButtonComponent()}
                {documentsMap[0].documents?.length === 0 && documentsMap[1].documents?.length === 0 &&
                    <Box display={'flex'} justifyContent={'center'} py={2}>
                        <Typography color={'silver'} variant={'h6'}>No documents has been added</Typography>
                    </Box>}
                <DocumentList documents={documentsMap}/>
            </DocumentFields>
        }
    </>;
};

export default ProfileDocumentsTab;
