import React from 'react';
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import useTextField from '../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../utility/validationUtil';
import {FORM_ACCESS_LEVELS_NAMES} from '../../../constants/formAccessLevels';
import FormBuilder from './FormBuilder/FormBuilder';
import {CalendarIcon, DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Close';

const FormStructure = (props) => {
    const {
        name,
        schema,
        templateId,
        accessLevel,
        expirationDate,
        onExpirationDateChange,
        templates,
        onAccessLevelChange,
        onNameChange,
        handleTemplateChange,
        editMode,
        setSchema,
        isFormArchived,
        formId,
        remindInterval,
        onRemindIntervalChange
    } = props;
    const nameField = useTextField({
        initialValue: name ?? '',
        validate: (value) => validateFieldLength(value, 128),
        onChange: onNameChange
    });

    return (
        <>
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                flexWrap={'wrap'}
                gap={2}
                padding={2}
            >
                {
                    !editMode
                        ? <>
                            <TextField
                                sx={{flex: 1}}
                                select
                                value={templateId}
                                label="Form Template"
                                onChange={handleTemplateChange}
                            >
                                {
                                    templates?.map(({id, name}) => (
                                        <MenuItem
                                            key={id}
                                            value={id}
                                        >{name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <Divider orientation="vertical" variant="middle" flexItem/>
                        </>
                        : ''
                }
                <TextField
                    sx={{
                        flex: 1,
                        '&.MuiFormControl-root': {
                            margin: 0
                        }
                    }}
                    required
                    fullWidth
                    disabled={isFormArchived}
                    label="Form Name"
                    variant="outlined"
                    margin="normal"
                    {...nameField}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <DatePicker
                    label="Expiration Date"
                    value={expirationDate ? moment(expirationDate) : null}
                    onChange={(newValue) => !expirationDate && onExpirationDateChange(newValue)}
                    onOpen={() => onExpirationDateChange(null)}
                    timezone="UTC"
                    disabled={isFormArchived}
                    format={'D-MMM-YYYY'}
                    minDate={moment(new Date())}
                    slots={{
                        openPickerIcon: expirationDate ? CancelIcon : CalendarIcon,
                    }}
                    slotProps={{
                        field: {
                            readOnly: true,
                        },
                    }}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <TextField
                    sx={{margin: 0, flex: !editMode ? 1 : 0.5}}
                    required
                    disabled={isFormArchived}
                    label="Remind interval in hours (0 - Disabled)"
                    variant="outlined"
                    value={remindInterval}
                    onChange={(e) => onRemindIntervalChange(e.target.value)}
                    margin="normal"
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <FormControl
                    required
                    disabled={editMode}
                    sx={{
                        '&.MuiFormControl-root': {
                            marginTop: 0
                        }
                    }}
                >
                    <FormLabel>Access level</FormLabel>
                    <RadioGroup
                        row
                        key={accessLevel}
                        value={accessLevel}
                        onChange={onAccessLevelChange}
                    >
                        {
                            Object.entries(FORM_ACCESS_LEVELS_NAMES).map(([key, value]) => (
                                <FormControlLabel
                                    key={key}
                                    value={key}
                                    control={<Radio size="small"/>}
                                    label={value}
                                />
                            ))
                        }
                    </RadioGroup>
                </FormControl>
            </Stack>
            <Divider/>
            {schema && <FormBuilder
                schema={schema}
                setSchema={setSchema}
                isFormArchived={isFormArchived}
                accessLevel={accessLevel}
                formId={formId}
            />}
        </>
    );
};

FormStructure.propTypes = {
    accessLevel: PropTypes.number,
    onAccessLevelChange: PropTypes.func,
    onNameChange: PropTypes.func,
    schema: PropTypes.object,
    formRef: PropTypes.object,
    name: PropTypes.string,
    templates: PropTypes.object,
    handleTemplateChange: PropTypes.func,
    setSchema: PropTypes.func,
    templateId: PropTypes.number,
    editMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    expirationDate: PropTypes.string,
    remindInterval: PropTypes.number,
    onRemindIntervalChange: PropTypes.func,
    onExpirationDateChange: PropTypes.func,
    formId: PropTypes.number
};

export default FormStructure;