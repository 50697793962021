import React from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const MisIntegrationSection = ({title, flex, children, sx = {}}) => {
    return (
        <Box
            sx={{...sx}}
            display="flex"
            flexDirection="column"
            flex={flex ?? 0}
        >
            <Typography sx={{marginBottom: 2}} variant="subtitle1">{title}</Typography>
            {children}
        </Box>
    );
};

MisIntegrationSection.propTypes = {
    title: PropTypes.string,
    flex: PropTypes.number,
    children: PropTypes.node,
    sx: PropTypes.object
};

export default MisIntegrationSection;