import {jwtDecode} from 'jwt-decode';
import {ROLES} from '../constants/roles';
import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('jwtHeaderPayload');

export const getRefreshToken = () => Cookies.get('refreshToken');

export const getRole = () => {
    const token = getToken();

    try {
        return !token ? ROLES.NONE : (jwtDecode(token).role ?? ROLES.NONE);
    } catch (e) {
        console.error(e);
    }

    return ROLES.NONE;
};
