import React from 'react';
import Typography from '@mui/material/Typography';
import {List, ListItem, ListItemIcon, ListItemText, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import PropTypes from 'prop-types';

const DocumentList = ({documents, readonly}) => {
    return (
        documents.map(x => x.documents.length > 0
            ? (
                <>
                    {
                        x.sectionName
                            ? <Typography
                                key={`label_${x.sectionName}`}
                                variant="subtitle1"
                                color="text.secondary"
                                fontWeight={600}>{x.sectionName}
                            </Typography>
                            : ''
                    }
                    <List
                        key={`list_${x.sectionName}`}
                        sx={{
                            maxHeight: 400,
                            overflowY: 'auto',
                            padding: 0
                        }}
                        onScroll={x.paginationHandler}
                    >
                        {
                            x.documents.map((document, index) => <ListItem
                                key={`${x.sectionName}_${index}`}
                                secondaryAction={
                                    <Stack direction="row" spacing={1}>
                                        {
                                            document.id &&
                                            <IconButton
                                                edge="end"
                                                sx={{
                                                    ':hover': {
                                                        color: 'primary.main'
                                                    }
                                                }}
                                                onClick={async () => await x.onDownload(document.id)}
                                            >
                                                <FileDownloadRoundedIcon fontSize={'small'}/>
                                            </IconButton>
                                        }
                                        {
                                            x.allowDelete && !readonly
                                                ? <IconButton
                                                    edge="end"
                                                    sx={{
                                                        ':hover': {
                                                            color: 'error.light'
                                                        }
                                                    }}
                                                    onClick={() => x.onDelete(document)}
                                                >
                                                    <DeleteOutlineRoundedIcon fontSize={'small'}/>
                                                </IconButton>
                                                : ''
                                        }
                                    </Stack>
                                }
                            >
                                <ListItemIcon>
                                    <InsertDriveFileRoundedIcon sx={{color: 'primary.main'}}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={document.name}
                                    secondary={
                                        <div>
                                            {document.isForParent ? <div>Available for parent</div> : ''}
                                            {document.summary ? <div>{document.summary}</div> : ''}
                                        </div>
                                    }
                                    primaryTypographyProps={{
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: 'calc(100% - 60px)'
                                        }
                                    }}
                                    secondaryTypographyProps={{
                                        style: {
                                            maxWidth: 'calc(100% - 60px)'
                                        }
                                    }}
                                />
                            </ListItem>)
                        }
                    </List>
                </>
            )
            : ''
        )
    );
};

DocumentList.propTypes = {
    documents: PropTypes.array,
    readonly: PropTypes.bool
};

export default DocumentList;