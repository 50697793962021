import {NativeSelect} from '@mui/material';
import PropTypes from 'prop-types';

const AdmicitySelect = ({options, value, onChange, defaultValue = true, widthValue = '50%', disabled}) => {
    return (
        <NativeSelect
            defaultValue={value}
            onChange={onChange}
            sx={{width: widthValue}}
            disabled={disabled}
        >
            {defaultValue && <option key="" value={null}></option>}
            {options && options.map(option =>
                <option key={option.title} value={option.value} disabled={option.disabled}>{option.title}</option>)}
        </NativeSelect>
    );
};

AdmicitySelect.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.bool,
    widthValue: PropTypes.string,
    disabled: PropTypes.bool,
};
export default AdmicitySelect;