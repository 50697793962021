import React from 'react';
import {TextField} from '@mui/material';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import useTextField from '../../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../../utility/validationUtil';
import PropTypes from 'prop-types';

const MisIntegrationUpdateTokenDialog = ({open, model, onTokenUpdate, onClose}) => {
    const newTokenField = useTextField({
        initialValue: model.name ?? '',
        validate: (value) => validateFieldLength(value, 250)
    });

    const handleDialogClose = () => {
        onClose();
        newTokenField.reset();
    };

    const handleTokenUpdate = () => {
        onTokenUpdate({
            id: model.id,
            name: newTokenField.value
        });
        onClose();
    };

    return (
        <AdmicityDialog
            handleClose={handleDialogClose}
            title={'Edit'}
            open={open}
            actions={[
                {label: 'Cancel', onClick: handleDialogClose},
                {
                    label: 'Save',
                    disabled: newTokenField.error || !newTokenField.value,
                    onClick: handleTokenUpdate
                }
            ]}
        >
            <TextField
                autoFocus
                required
                margin="dense"
                label="Token Name"
                fullWidth
                variant="standard"
                {...newTokenField}
            />
        </AdmicityDialog>
    );
};

MisIntegrationUpdateTokenDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    model: PropTypes.object.isRequired,
    onTokenUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default MisIntegrationUpdateTokenDialog;