import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Button} from '@mui/material';
import AdmicityTable from '../../../shared-components/AdmicityTable';
import {formatDate} from '../../../utility/dateUtil';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {retrieveSchoolList} from '../SchoolManagementPageSlice';
import DomainDisabledRoundedIcon from '@mui/icons-material/DomainDisabledRounded';

const tableProps = {
    columns: [
        {
            field: 'name',
            headerName: 'School',
            editable: false,
            sortable: false,
            flex: 1
        },
        {
            field: 'dfeNumber',
            headerName: 'Dfe Number',
            editable: false,
            sortable: false,
            flex: 1
        },
        {
            field: 'domain',
            headerName: 'School Web domain',
            editable: false,
            sortable: false,
            flex: 1
        },
        {
            field: 'misIntegration',
            headerName: 'MIS Integration',
            editable: false,
            sortable: false,
            flex: 1
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            editable: false,
            sortable: false,
            valueGetter: (params) => formatDate(params.row?.createdAt),
            flex: 1
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            editable: false,
            sortable: false,
            flex: 1
        }
    ],
    pageSize: 10
};

const SchoolManagementDashboard = () => {
    const schoolInfo = useSelector(state => state.schoolManagement.schoolList);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveSchoolList({pageNumber: 0, pageSize: tableProps.pageSize}));
    }, [dispatch]);

    const handlePaginationModelChange = (pageNumber, pageSize) => {
        dispatch(retrieveSchoolList({pageNumber, pageSize}));
    };

    const editSchool = (selectedRow) => {
        navigate(`edit/${selectedRow.id}`,
            {
                state: {
                    id: selectedRow.id,
                    name: selectedRow.name
                }
            }
        );
    };

    return (
        <>
            <div>
                <Typography variant="h5" py={3}>School Workspaces</Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate('new')}
                    sx={{mb: 3}}
                    startIcon={<AddCircleOutlineIcon/>}
                >
                    Set up a new school
                </Button>
            </div>
            <AdmicityTable
                isLoading={schoolInfo.isLoading}
                data={schoolInfo.items}
                columns={tableProps.columns}
                totalItemCount={schoolInfo.totalCount}
                pageSize={tableProps.pageSize}
                handlePaginationModelChange={handlePaginationModelChange}
                noRowsOverlay={{
                    icon: <DomainDisabledRoundedIcon/>,
                    text: 'No schools has been added'
                }}
                rowActions={[
                    {
                        label: 'Settings',
                        action: editSchool
                    }
                ]}/>
        </>
    );
};

export default SchoolManagementDashboard;