import React, {useEffect, useState} from 'react';
import AdmicityTable from '../../../shared-components/AdmicityTable';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdmicityDialog from '../../../shared-components/AdmicityDialog';
import useTextField from '../../../utility/hooks/useTextField';
import {ROLE_IDS, ROLES, ROLES_LABELS} from '../../../constants/roles';
import {validateEmail} from '../../../utility/validationUtil';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import {formatDateTime} from '../../../utility/dateUtil';
import AdmicityStatus from '../../../shared-components/AdmicityStatus';
import {INVITE_STATUSES, INVITE_STATUSES_NAMES} from '../../../constants/registrationInviteStatuses';
import {
    useGetRegistrationInvitesQuery,
    useSendRegistrationInviteMutation
} from '../../../api/services/schoolManagementService';
import withPagination from '../../../hoc/pagination/withPagination';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useUser from '../../../utility/hooks/useUser';

const getInviteStatusInfo = (status) => ({
    label: INVITE_STATUSES_NAMES[status] ?? 'unknown status',
    color: {
        [INVITE_STATUSES.pending]: 'blueGrey',
        [INVITE_STATUSES.completed]: 'green',
        [INVITE_STATUSES.expired]: 'red',
        [INVITE_STATUSES.failed]: 'red'
    }[status] ?? 'grey'
});

const tableProps = {
    columns: [
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            sortable: false,
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => params.row.status && <AdmicityStatus {...getInviteStatusInfo(params.row.status)}/>,
            editable: false,
            sortable: false,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'expires',
            headerName: 'Expires At',
            valueGetter: (params) => formatDateTime(params.row?.expires),
            editable: false,
            sortable: false,
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'completedDate',
            headerName: 'Completed At',
            valueGetter: (params) => params?.row.completedDate && formatDateTime(params.row.completedDate),
            editable: false,
            sortable: false,
            minWidth: 200,
            flex: 1,
        }
    ],
    pageSize: 10
};

const SchoolMembersSetup = ({schoolInfo}) => {
    const {user} = useUser();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [role, setRole] = useState('');
    const emailField = useTextField({
        initialValue: '',
        validate: validateEmail
    });
    const [sendRegistrationInvite, {
        isError: isDeleteFormAssignmentError,
        isSuccess: isDeleteFormAssignmentSuccess,
        error: sendInviteErrors,
    }] = useSendRegistrationInviteMutation();

    const roles = {
        [ROLES.SUPER_ADMIN]: [ROLES.SCHOOL_ADMIN],
        [ROLES.SCHOOL_ADMIN]: [
            ROLES.PARENT,
            ROLES.MEMBER_OF_STAFF,
            ROLES.SCHOOL_ADMIN
        ],
        [ROLES.NONE]: []
    }[user.role];

    const Table = withPagination(AdmicityTable, useGetRegistrationInvitesQuery, {schoolId: schoolInfo.id});

    useEffect(() => {
        if (isDeleteFormAssignmentError || isDeleteFormAssignmentSuccess) {
            if (isDeleteFormAssignmentError) {
                setIsSubmitted(false);
            }
            if (isDeleteFormAssignmentSuccess) {
                handleDialogClose();
            }
        }
    }, [isDeleteFormAssignmentSuccess, isDeleteFormAssignmentError]);

    const sendInvite = async () => {
        setIsSubmitted(true);

        await sendRegistrationInvite({
            email: emailField.value,
            role: ROLE_IDS[role],
            schoolId: schoolInfo.id
        });
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setIsSubmitted(false);
        setRole('');
        emailField.reset();
    };

    const isFormValid = (!emailField.error && emailField.value !== '') && role !== '';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <div>
                <Button
                    variant="contained"
                    sx={{mb: 2, mt: 2}}
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={() => setOpenDialog(true)}
                >
                    Add user
                </Button>
            </div>
            <Table
                columns={tableProps.columns}
                noRowsOverlay={{
                    icon: <PersonOffRoundedIcon/>,
                    text: 'No invites has been added'
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={'Add user'}
                open={openDialog}
                actions={[
                    {label: 'Cancel', onClick: handleDialogClose},
                    {
                        label: 'Send invite',
                        onClick: sendInvite,
                        disabled: isSubmitted || !isFormValid,
                        startIcon: isSubmitted ? <CircularProgress color="inherit" size={16}/> : undefined
                    }
                ]}
            >
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    {...emailField}
                />
                <FormControl
                    required
                    fullWidth
                    variant="standard"
                    margin="dense"
                >
                    <InputLabel id="role-select">Role</InputLabel>
                    <Select
                        labelId="role-select"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        label="Role"
                    >
                        {
                            roles.map(value => (
                                <MenuItem key={value} value={value}>{ROLES_LABELS[value]}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {
                    sendInviteErrors && sendInviteErrors.length !== 0
                        ? <Alert
                            sx={{marginTop: 2}}
                            severity="error"
                        >
                            <AlertTitle>Error</AlertTitle>
                            {
                                sendInviteErrors.length === 1
                                    ? <Typography>{sendInviteErrors[0]}</Typography>
                                    : <ul style={{paddingLeft: 0, listStylePosition: 'inside', margin: 0}}>
                                        {
                                            sendInviteErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))
                                        }
                                    </ul>
                            }
                        </Alert>
                        : ''
                }
            </AdmicityDialog>
        </Box>
    );
};

SchoolMembersSetup.propTypes = {
    schoolInfo: PropTypes.object
};

export default SchoolMembersSetup;