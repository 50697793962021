import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {resetUpdatingStatus, retrieveEthnicity, retrieveEthnicityLookups, updateEthnicity} from '../../ProfilesSlice';
import {showSnackbar} from '../../../../../AppLayout/ApplicationSlice';
import {useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AdmicitySelect from '../../../../../shared-components/AdmicitySelect';
import Typography from '@mui/material/Typography';
import PassportFields from './PassportFields';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import PropTypes from 'prop-types';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';

const buildSelectOptions = (list) => {
    return list.map(element => {
        return {key: element.code, value: element.code, title: element.description};
    });
};

const buildAsylymStatusOptions = (list, passports) => {
    return passports?.some(p => p.nationCode === 'GBR')
        ? [{
            key: 'disabledAsylym',
            value: undefined,
            title: 'You can\'t select Asylym Status for profile with nationality of United Kingdom',
            disabled: true
        }]
        : [{key: '', value: null, title: ''}, ...buildSelectOptions(list)]
};

const isEngAdditionalLanguageOptions = [
    {
        code: 'false',
        description: 'No'
    },
    {
        code: 'true',
        description: 'Yes'
    },
];

const EthnicityFields = ({readonly}) => {
    const {
        selectedProfile,
        isEthnicityDataLoading,
        isEthnicityLookupsLoading,
        updatingSuccessful,
        ethnicityLookups,
        currentSchool
    } = useSelector(state => state.profilesInfo);
    const {studentId} = useParams();
    const [ethnicity, setEthnicity] = useState({});
    const [ethnicityLookup, setEthnicityLookup] = useState({});
    const [isEdited, setIsEdited] = useState(false);
    const dispatch = useDispatch();
    const [passports, setPassports] = useState([]);
    const [deletedPassportIds, setDeletedPassportIds] = useState([]);

    useEffect(() => {
        dispatch(retrieveEthnicityLookups()).then(() => {
            dispatch(retrieveEthnicity({studentId}));
        });
    }, []);

    useEffect(() => {
        if (selectedProfile.ethnicityData) {
            setPassports(selectedProfile.ethnicityData.nationalityPassport);
            setEthnicity(selectedProfile.ethnicityData);
        }
    }, [selectedProfile.ethnicityData]);

    useEffect(() => {
        if (ethnicityLookups.ethnicities && ethnicityLookups.ethnicities.length > 0) {
            setEthnicityLookup(ethnicityLookups);
        }
    }, [ethnicityLookups]);

    useEffect(() => {
        showNotification(updatingSuccessful);

        if (updatingSuccessful) {
            dispatch(retrieveEthnicity({studentId}));
        }
        dispatch(resetUpdatingStatus());
    }, [updatingSuccessful]);

    const columns = [
        {
            description: 'What is your child’s ethnicity?',
            value: ethnicity.ethnicityCode,
            options: buildSelectOptions(ethnicityLookup.ethnicities ?? []),
            key: 'ethnicityCode',
            required: true
        },
        {
            description: 'What is your child’s religion?',
            value: ethnicity.religionCode,
            options: buildSelectOptions(ethnicityLookup.religions ?? []),
            key: 'religionCode',
            required: true
        },
        {
            description: 'What was the first language your child was exposed to?',
            value: ethnicity.firstLanguageCode,
            options: buildSelectOptions(ethnicityLookup.languages ?? []),
            key: 'firstLanguageCode',
            required: true
        },
        {
            description: 'What language does your child speak at home?',
            value: ethnicity.homeLanguageCode,
            options: buildSelectOptions(ethnicityLookup.languages ?? []),
            key: 'homeLanguageCode',
            required: true
        },
        {
            description: 'Does your child speak English as an additional language?',
            value: ethnicity.isEnglishAdditional,
            options: buildSelectOptions(isEngAdditionalLanguageOptions),
            key: 'isEnglishAdditional',
            required: true
        },
        {
            description: 'Does your child have refugee or asylum status?',
            value: ethnicity.asylymStatusCode,
            options: buildAsylymStatusOptions(ethnicityLookup.asylymStatuses ?? [], passports),
            key: 'asylymStatusCode',
        },
        {
            description: 'What is your child’s country of birth?',
            value: ethnicity.countryOfBirthCode,
            options: buildSelectOptions(ethnicityLookup.nations ?? []),
            key: 'countryOfBirthCode',
            required: true
        },
        {
            description: 'What is your child’s nationality?',
            value: ethnicity.nationalIdentityCode,
            options: buildSelectOptions(ethnicityLookup.nationalIdentities ?? []),
            key: 'nationalIdentityCode',
            required: true
        },
    ];

    const showNotification = (success) => success !== undefined && dispatch({
        true: () => showSnackbar({
            message: 'Ethnicity updated successfully', severity: 'success'
        }),
        false: () => showSnackbar({
            message: 'Failed to update ethnicity', severity: 'error'
        })
    }[success]());

    const handleSave = () => {
        const newPassports = passports.filter(e => e.isNew);
        const editedPassports = passports.filter(e => e.isEdited);

        dispatch(updateEthnicity({
            ethnicity: isEdited ? ethnicity : null,
            studentId,
            newPassports,
            editedPassports,
            deletedPassportIds
        }));
        setIsEdited(false);
        setDeletedPassportIds([]);
    };

    const handleChangeValue = (value, key) => {
        let updatedEthnicity = JSON.parse(JSON.stringify(ethnicity));
        if (key !== 'isEnglishAdditional') {
            updatedEthnicity[key] = value ? value : null;
        } else {
            updatedEthnicity.isEnglishAdditional = value.toString();
        }
        setEthnicity(updatedEthnicity);
        setIsEdited(true);
    };

    const isAllEthnicityFieldsFilled = () => {
        const fields = [
            'countryOfBirthCode',
            'ethnicityCode',
            'firstLanguageCode',
            'homeLanguageCode',
            'religionCode',
            'isEnglishAdditional',
            'nationalIdentityCode'
        ];

        return fields.every(field => ethnicity[field] !== null);
    };

    const hasEditedOrNewPassports = passports?.some(e => e.isEdited || e.isNew);
    const isEthnicityConfirmed = !!selectedProfile.ethnicityData?.confirmedAt;
    const isAnyPassportDeleted = deletedPassportIds.length > 0;

    return (
        <ConfirmationWrapper
            readonly={readonly}
            confirmData={{
                confirmMessage: `I confirm that the information in the cultural profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                confirmedAt: selectedProfile.ethnicityData?.confirmedAt,
                confirmedBy: selectedProfile.ethnicityData?.confirmedBy,
                enableSaveButton: isAllEthnicityFieldsFilled() &&
                    (isEdited || hasEditedOrNewPassports || isAnyPassportDeleted),
                enableConfirmButton: !isEdited && !hasEditedOrNewPassports &&
                    !isAnyPassportDeleted && !isEthnicityConfirmed && isAllEthnicityFieldsFilled(),
                studentId,
                type: STUDENT_PROFILE_CONFIRMATION_TYPES.Cultural,
                handleSave,
                onConfirmSuccess: async () => dispatch(retrieveEthnicity({studentId}))
            }}
        >
            <Typography variant="h6" py={1}>CULTURAL INFORMATION REQUIRED BY THE DEPARTMENT FOR
                EDUCATION</Typography>
            <Typography variant="body1">All questions in this section must be responded to, with the exception of
                Asylum Seeker/Refugee which should not be answered if it does not apply. If you do not wish to
                provide answers to any of these questions, please select ‘Refused’ from the list of
                options.</Typography>
            <Grid container direction="row" justify="center" alignItems="center" spacing={4} py={3}>
                {columns && columns.map(column =>
                    <Grid item xs={12} sm={6}
                          key={`${column.key}_${column.value}_${isEthnicityDataLoading}_${isEthnicityLookupsLoading}`}>
                        <Box>
                            <Typography fontWeight="bold"
                                        variant="body1">{column.description} {!column.required ? '' : '*'}</Typography>
                            <AdmicitySelect
                                value={column.value}
                                options={column.options}
                                widthValue={'100%'}
                                defaultValue={column.value === null && column.key !== 'asylymStatusCode'}
                                onChange={event => handleChangeValue(event.target.value, column.key)}
                                disabled={readonly}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
            <PassportFields
                readonly={readonly}
                passports={passports}
                setPassports={setPassports}
                countries={ethnicityLookups.nations}
                setDeletedPassportIds={setDeletedPassportIds}
                deletedPassportIds={deletedPassportIds}
                asylymStatus={ethnicity.asylymStatusCode}
            />
        </ConfirmationWrapper>
    );
};

EthnicityFields.propTypes = {
    readonly: PropTypes.bool
};

export default EthnicityFields;
