/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import {
    getAllLeafColumnDefs,
    MaterialReactTable,
    MRT_ToggleFiltersButton,
    MRT_ToggleFullScreenButton,
    useMaterialReactTable
} from 'material-react-table';
import React, {useEffect, useState} from 'react';
import {Badge, MenuItem, Stack, Typography} from '@mui/material';
import {CustomActionsColumn} from './components/actions/CustomActionsColumn';
import FILTER_OPERATIONS from '../../../constants/filterOperations';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import IconButton from '@mui/material/IconButton';
import AdmicityIconMenu from '../../AdmicityIconMenu';
import CustomShowHideMenuItems from './components/actions/CustomShowHideMenuItems';

const baseTableProps = {
    muiTablePaperProps: {
        variant: 'outlined',
        elevation: 0,
        sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        }
    },
    muiTableContainerProps: {
        sx: {
            flex: '1 1 0'
        }
    },
    muiTableHeadCellProps: {
        sx: {
            justifyContent: 'space-between',
            '& .Mui-TableHeadCell-Content-Wrapper': {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '5',
                whiteSpace: 'pre-wrap'
            }
        }
    },
    muiTableFooterProps: {
        sx: {
            outline: 'none'
        }
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableFilterMatchHighlighting: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableColumnResizing: true,
    enablePagination: true,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableColumnActions: false
};

const getFilterFns = (columns, table) => Object.assign(
    {},
    ...getAllLeafColumnDefs(columns).map((col) =>
        ({
            [col.accessorKey]:
                typeof col.filterFn === 'function'
                    ? col.filterFn.name || 'custom'
                    : col.filterFn ||
                    (table.initialState?.columnFilterFns?.[col.accessorKey]) || FILTER_OPERATIONS.CONTAINS,
        }))
);

const countFiltersWithoutDefaults = (filters) =>
    filters.reduce((count, filter) => {
        if (!filter.value) {
            return count;
        }

        if (Array.isArray(filter.value)) {
            return filter.value.length !== 0 ? count + 1 : count;
        } else if (typeof filter.value === 'object' && filter.value != null) {
            return Object.keys(filter.value).length !== 0 ? count + 1 : count;
        } else if (typeof filter.value === 'string') {
            return filter.value.trim() !== '' ? count + 1 : count;
        } else {
            return count + 1;
        }
    }, 0);

export const AdmicityTableV2 = (
    {
        initialState = {
            columnFilters: []
        },
        data,
        columns,
        isLoading,
        isRefetching,
        totalItemCount,
        pagination,
        columnVisibility,
        tableProps,
        rowActions,
        noRowsOverlay,
        toolbarIconActions,
        onPaginationModelChange,
        onColumnVisibilityChange,
        onColumnFiltersApply,
        onRowClick
    }) => {
    const [internalPagination, setInternalPagination] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    const [internalColumnVisibility, setInternalColumnVisibility] = useState({});
    const [internalColumnFilters, setInternalColumnFilters] = useState(initialState.columnFilters);
    const [showColumnFilters, setShowColumnFilters] = useState(false);
    const [columnFiltersFns, setColumnFilterFns] = useState({});
    const filtersWithoutDefaults = countFiltersWithoutDefaults(internalColumnFilters);

    const NoRowsOverlay = ({sx}) => {
        const getStyledIcon = (iconProp) => React.cloneElement(iconProp, {
            sx: {
                fontSize: 64,
                marginBottom: 1
            }
        });

        return <Stack
            sx={{
                ...sx,
                color: 'grey.A400'
            }}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            {
                noRowsOverlay === undefined || Object.keys(noRowsOverlay).length === 0
                    ? 'No rows'
                    : <>
                        {
                            noRowsOverlay.icon
                                ? getStyledIcon(noRowsOverlay.icon)
                                : ''
                        }
                        <Typography variant="body1"></Typography>
                        {noRowsOverlay?.text}
                    </>
            }
        </Stack>;
    };

    const handlePaginationChange = updaterOrValue => {
        const old = pagination ?? internalPagination;
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue;
        const func = onPaginationModelChange ?? setInternalPagination;

        func(newValue);
    };

    const handleColumnVisibilityChange = updaterOrValue => {
        const old = columnVisibility ?? internalColumnVisibility;
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue;
        const func = onColumnVisibilityChange ?? setInternalColumnVisibility;

        func(newValue);
    };

    const table = useMaterialReactTable({
        data,
        columns,
        rowCount: totalItemCount,
        state: {
            isLoading,
            showProgressBars: isLoading || isRefetching,
            density: 'comfortable',
            columnPinning: {
                right: ['mrt-row-actions'],
            },
            pagination: pagination ?? internalPagination,
            columnVisibility: columnVisibility ?? internalColumnVisibility,
            columnFilters: internalColumnFilters,
            showColumnFilters,
            columnFilterFns: columnFiltersFns
        },
        enableRowActions: rowActions.length > 0,
        positionActionsColumn: 'last',
        muiPaginationProps: {
            rowsPerPageOptions: [20, 50, 100]
        },
        onPaginationChange: handlePaginationChange,
        onColumnVisibilityChange: handleColumnVisibilityChange,
        onColumnFiltersChange: setInternalColumnFilters,
        onShowColumnFiltersChange: setShowColumnFilters,
        muiTableBodyRowProps: ({row}) => ({
            onClick: (_) => onRowClick
                ? onRowClick(row.original)
                : () => {
                },
            sx: {
                cursor: onRowClick ? 'pointer' : 'default'
            },
        }),
        renderRowActionMenuItems: ({row, closeMenu}) => rowActions.map((
            {
                action,
                label,
                disabled
            }) =>
            <MenuItem
                key={label}
                onClick={() => {
                    action(row.original);
                    closeMenu();
                }}
                disabled={disabled ? disabled(row.original) : false}
            >
                {label}
            </MenuItem>
        ),
        renderEmptyRowsFallback: ({table}) =>
            (
                <NoRowsOverlay
                    sx={{
                        height: Math.max(256,
                            (table.refs.tableHeadRef.current?.offsetHeight ?? 0) -
                            (table.refs.tablePaperRef.current?.offsetHeight ?? 0) -
                            (table.refs.topToolbarRef.current?.offsetHeight ?? 0) -
                            (table.refs.bottomToolbarRef.current?.offsetHeight ?? 0) - 8
                        ),
                        maxWidth: table.refs.tablePaperRef.current?.offsetWidth
                    }}>
                </NoRowsOverlay>
            ),
        renderToolbarInternalActions: ({table}) => (
            <>
                {
                    tableProps.enableFilters === false
                        ? ''
                        : <MRT_ToggleFiltersButton table={table}/>
                }
                <CustomShowHideMenuItems table={table}/>
                <MRT_ToggleFullScreenButton table={table}/>
                {
                    toolbarIconActions?.map(({icon, title, onClick, menuItems, hideIfEmpty, type}) => ({
                        button: () => <IconButton
                            key={title}
                            onClick={onClick}
                            title={title}
                        >{icon}</IconButton>,
                        select: () => hideIfEmpty && !totalItemCount
                            ? null
                            : <AdmicityIconMenu
                                title={title}
                                menuItems={menuItems}
                                icon={icon}
                            />
                    }[type]?.() || <></>))
                }
            </>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    sx: {
                        '&.MuiTableCell-root': {
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
                        },
                    }
                },
                muiTableBodyCellProps: {
                    sx: {
                        '&.MuiTableCell-root': {
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
                        },
                    }
                },
                minSize: 50,
                size: showColumnFilters ? 120 : 50,
                Header: () => <CustomActionsColumn
                    visible={showColumnFilters}
                    filtersCount={filtersWithoutDefaults}
                    onClick={() => {
                        if (onColumnFiltersApply) {
                            onColumnFiltersApply(internalColumnFilters, columnFiltersFns);
                        }
                    }}
                />,
            }
        },
        icons: {
            FilterListIcon: props => (<Badge
                badgeContent={filtersWithoutDefaults}
                color="primary">
                <FilterListRoundedIcon {...props}/>
            </Badge>)
        },
        muiFilterTextFieldProps: {
            SelectProps: {
                MenuProps: {
                    sx: {
                        maxWidth: 300,
                        '& .MuiMenuItem-root': {
                            display: 'block',
                            whiteSpace: 'unset',
                            wordBreak: 'break-all',
                            '& .MuiBox-root': {
                                whiteSpace: 'unset',
                                wordBreak: 'break-all'
                            }
                        },
                    }
                },
            }
        },
        ...baseTableProps,
        ...tableProps
    });

    useEffect(() => {
        if (columns?.length > 0) {
            setColumnFilterFns(getFilterFns(columns, table));
        }
    }, [columns]);

    return <MaterialReactTable table={table}/>;
};

AdmicityTableV2.defaultProps = {
    tableProps: {},
    rowActions: [],
};

export default AdmicityTableV2;
