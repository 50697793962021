import React from 'react';
import {Box, Checkbox, Stack, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';

const CheckboxQuestion = ({index, id, value, onChange, description, isEditMode, enableValidation, isFormArchived}) => {
    return (
        <Box>
            {isEditMode
                ? <>
                    <TextField sx={{my: 1}} fullWidth value={description} multiline
                               onChange={e => onChange({index, description: e.target.value})}
                               label={!description ? 'Write a question for a confirm' : null}
                               disabled={isFormArchived}
                               InputLabelProps={{
                                   shrink: false,
                               }}/>
                    <Stack flexDirection="row" justifyContent="space-between" gap={3}>
                        <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                          isFormArchived={isFormArchived}/>
                    </Stack>
                </>
                : <Box key={`${description}-${value}`} display={'flex'} alignItems={'center'}>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    <Checkbox value={value} checked={value}
                              onChange={e => onChange({id, value: e.target.checked})}/>
                </Box>
            }
        </Box>
    );
};

CheckboxQuestion.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
};

export default CheckboxQuestion;