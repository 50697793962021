import {Badge, Button, Stack, Typography} from '@mui/material';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import React from 'react';
/* eslint-disable react/prop-types */

export const CustomActionsColumn = ({filtersCount, visible, onClick}) =>
    (
        <Stack direction="column" gap={1} paddingBottom={1}>
            <Typography variant="subtitle2" fontWeight={700}>Actions</Typography>
            {
                visible
                    ? <Badge badgeContent={filtersCount} color="primary">
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{
                                textTransform: 'none',
                            }}
                            onClick={onClick}
                            startIcon={<FilterListRoundedIcon fontSize="small"/>}
                        >Apply filters</Button>
                    </Badge>
                    : ''
            }
        </Stack>
    );