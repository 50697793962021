import React, {useEffect, useState} from 'react';
import {Box, InputAdornment, MenuItem, Select, TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import filterOperations from '../constants/filterOperations';
import AdmicityDatePicker from './AdmicityDatePicker';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Close';

const AdmicitySearchField = ({column, updateFilters, initialValue, applyFilters, clearFilter}) => {
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleSearch = () => {
        const filter = {field: column.field, value, operation: column.operation};
        applyFilters(filter);
        updateFilters(filter);
    };

    const removeFilter = () => {
        clearFilter(column.field);
    };

    const handleMultiselectSearch = () => {
        if (value && value.length) {
            const filter = {field: column.field, value, operation: column.operation};
            applyFilters(filter);
            updateFilters(filter);
        } else {
            removeFilter(column.field)
        }
        setOpen(false);
    };

    const handleSelectKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleMultiselectSearch();
            setOpen(!open);
        }
    };

    const handleDateRangeSearch = (dateRange) => {
        const filter = {
            field: column.field,
            startDateRange: dateRange[0].startDate,
            endDateRange: dateRange[0].endDate,
            operation: column.operation
        };
        applyFilters(filter);
        updateFilters(filter);
    };

    return (
        <>
            {column.operation === filterOperations.CONTAINS && (
                <Box display="flex" alignItems="center">
                    <TextField
                        size={'small'}
                        value={value}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                e.stopPropagation();
                            } else if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        onChange={e => setValue(e.target.value)}
                        sx={{
                            '& .MuiInputBase-input': {
                                fontWeight: 400,
                                fontSize: '0.875rem',
                                lineHeight: 1.43,
                                letterSpacing: '0.01071em',
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={initialValue !== value ? handleSearch : removeFilter}
                                        disabled={!value && !initialValue}
                                        sx={{
                                            padding: '0px 0px',
                                        }}
                                    >
                                        {initialValue !== value || (!initialValue) ? <SearchIcon/> : <CancelIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
            {column.operation === filterOperations.MULTISELECT && (
                <Select
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiple
                    value={value ?? []}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={handleMultiselectSearch}
                    onChange={e => setValue(e.target.value)}
                    title={value?.join(' ')}
                    size={'small'}
                    sx={{
                        '& .MuiSelect-select': {
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            lineHeight: 1.43,
                            letterSpacing: '0.01071em',
                        }
                    }}
                >
                    {column.options.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            onKeyDown={handleSelectKeyDown}
                        >
                            {option.title}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {column.operation === filterOperations.DATE_RANGE && (
                <AdmicityDatePicker
                    onChange={handleDateRangeSearch}
                    startDate={value?.startDateRange}
                    endDate={value?.endDateRange}
                    removeFilter={removeFilter}
                />
            )}
        </>
    );
};

AdmicitySearchField.propTypes = {
    column: PropTypes.object,
    initialValue: PropTypes.string,
    updateFilters: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
};
export default AdmicitySearchField;