import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export const determinePossibleOperations = (component) => {
    switch (component?.type) {
        case 'checkboxQuestion':
            return [{value: true, title: 'Checked'}, {value: false, title: 'Not Checked'}]
        case 'dropdown':
            return component.options?.map(o => {
                return {value: o.id, title: o.description}
            })
        case 'singleChoice':
            return component.options?.map(o => {
                return {value: o.id, title: o.description}
            })
        case 'multipleChoice':
            return component.options?.map(o => {
                return {value: o.id, title: o.description}
            })
        default:
            return []
    }
}

const ShowFieldWhen = ({component, handleShowFieldWhen, availableComponents}) => {
    const [selectedComponent, setSelectedComponent] = useState(undefined);
    const [targetState, setTargetState] = useState(undefined);

    useEffect(() => {
        if (component) {
            setSelectedComponent(availableComponents.find(e => e.id === component.relates?.relatesTo));
            setTargetState(component.relates?.targetState)
        }
    }, [component]);

    return (
        <>
            <Typography>Question:</Typography>
            <TextField
                key={selectedComponent?.id}
                select
                value={selectedComponent}
                fullWidth
                onChange={e => {
                    setSelectedComponent(e.target.value);
                    setTargetState(undefined);
                }}
            >
                {availableComponents.map(option => <MenuItem key={option.id} value={option}>
                    {option.label} - {option.description}
                </MenuItem>)}
            </TextField>
            <Typography pt={1}>Equals</Typography>
            <TextField
                key={targetState}
                select
                value={targetState}
                fullWidth
                disabled={!selectedComponent}
                onChange={e => setTargetState(e.target.value)}
            >
                {selectedComponent && determinePossibleOperations(selectedComponent)?.map(operation =>
                    <MenuItem key={operation.value} value={operation.value}>
                        {operation.title}
                    </MenuItem>)}
            </TextField>

            <Box display={'flex'} justifyContent={'end'} pt={2} gap={1}>
                <Button onClick={() => handleShowFieldWhen(component.id, null, null)}>
                    Clear
                </Button>
                <Button variant={'contained'}
                        disabled={!selectedComponent || targetState === undefined}
                        onClick={() => handleShowFieldWhen(component.id, selectedComponent.id, targetState)}>
                    Save
                </Button>
            </Box>

        </>
    )
}

ShowFieldWhen.propTypes = {
    component: PropTypes.object,
    handleShowFieldWhen: PropTypes.func,
    availableComponents: PropTypes.array,
};

export default ShowFieldWhen;