import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    styled,
    Tab,
    Tabs
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import FormStructure from './FormStructure';
import PrivateFormSettings from './PrivateFormSettings';
import PropTypes from 'prop-types';
import {FORM_ACCESS_LEVELS} from '../../../constants/formAccessLevels';
import PublicFormSettings from './PublicFormSettings';
import {
    useCreateFormMutation,
    useGetFormTemplatesQuery,
    useLazyGetFormQuery,
    useUpdateFormMutation,
    useUpdateFormStatusMutation
} from '../../../api/services/formsService';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useNotification from '../../../utility/hooks/useNotification';
import {FORM_STATUSES} from '../../../constants/formStatuses';

const StickyFooter = styled('div')(({theme}) => ({
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid #e0e0e0',
    padding: theme.spacing(2),
    textAlign: 'right'
}));

const FormEditor = ({mode, title}) => {
    const isEditMode = mode === 'edit';
    const {id} = useParams();
    const {showErrorNotification, showSuccessNotification} = useNotification();
    const [formId, setFormId] = useState(null);
    const [schema, setSchema] = useState(undefined);
    const [formStatus, setFormStatus] = useState(undefined);
    const [templateId, setTemplateId] = useState(undefined);
    const [accessLevel, setAccessLevel] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [remindInterval, setRemindInterval] = useState(0);
    const [strictRecipientsFiltering, setStrictRecipientsFiltering] = useState(false);
    const [name, setName] = useState('');
    const [hasRecipients, setHasRecipients] = useState(false);
    const [hasFormQuestionRecipients, setHasFormQuestionRecipients] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const {data: templates} = useGetFormTemplatesQuery(undefined, {skip: isEditMode});
    const [saveForm, {isLoading: isFormSaving}] = isEditMode || formId != null
        ? useUpdateFormMutation()
        : useCreateFormMutation();
    const [updateFormStatus, {isLoading: isPublishingForm}] = useUpdateFormStatusMutation();
    const [getForm] = useLazyGetFormQuery();
    const formRef = useRef(null);

    useEffect(() => {
        if (isEditMode) {
            getForm(id)
                .then(({data: form}) => {
                    setFormId(form.id);
                    setSchema(form.schema);
                    setName(form.name);
                    setFormStatus(form.status);
                    setStrictRecipientsFiltering(form.strictRecipientsFiltering);
                    if (form.accessLevel) {
                        setAccessLevel(form.accessLevel)
                    }
                    setExpirationDate(form.expirationDate)
                    setRemindInterval(form.remindInterval)
                })
                .catch(_ => {
                    showErrorNotification('Failed to retrieve form data');
                });
        }
    }, [mode]);

    const handleTemplateChange = (event) => {
        const templateId = event.target.value;
        setTemplateId(templateId);
        setSchema(templates?.find(template => template.id === templateId)?.schema);
    };

    const handleAccessLevelChange = (event) => setAccessLevel(parseInt(event.target.value));

    const handleExpirationDateChange = (value) => setExpirationDate(value);

    const handleRemindIntervalChange = (value) => {
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue) && intValue >= 0 && intValue <= 1000) {
            setRemindInterval(intValue);
        } else if (value === '') {
            setRemindInterval('');
        }
    }

    const handleNameChange = (event) => setName(event.target.value);

    const handleSaveForm = async () => {

        try {
            if (isEditMode) {
                await saveForm({
                    id: formId,
                    schema,
                    accessLevel,
                    name,
                    strictRecipientsFiltering,
                    expirationDate,
                    remindInterval
                });
            } else {
                const response = await saveForm({
                    templateId,
                    accessLevel,
                    schema,
                    name,
                    strictRecipientsFiltering,
                    expirationDate,
                    remindInterval
                });
                const savedFormId = response.data?.formId;

                if (savedFormId) {
                    setFormId(savedFormId);
                    setActiveStep(activeStep + 1);
                }
            }

            showSuccessNotification('Form saved successfully');
        } catch (e) {
            showErrorNotification('Failed to save form');
        }

    };

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    const navigateBack = () => {
        navigate('/forms');
    };

    const isFormIncomplete = () => !schema || !accessLevel || !name || remindInterval === '';

    const makeFormLive = async () => {
        const result = await updateFormStatus({id: formId});

        if (result.error) {
            showErrorNotification('Failed to publish form');
        } else {
            showSuccessNotification('Form published successfully');
            navigateBack();
        }
    };

    const handleSetStrictRecipientsFiltering = async (value) => {
        if (isEditMode) {
            setStrictRecipientsFiltering(value);
            return;
        }

        try {
            await saveForm({
                id: formId,
                schema,
                accessLevel,
                name,
                strictRecipientsFiltering: value
            });
            setStrictRecipientsFiltering(value);
        } catch (e) {
            showErrorNotification('Failed to update "Must appear in all these groups" option');
        }
    }

    const tabs = [
        {
            label: 'Form Structure',
            component: <FormStructure
                formRef={formRef}
                accessLevel={accessLevel}
                name={name}
                schema={schema}
                templateId={templateId}
                templates={templates}
                onAccessLevelChange={handleAccessLevelChange}
                onNameChange={handleNameChange}
                handleTemplateChange={handleTemplateChange}
                setSchema={setSchema}
                editMode
                expirationDate={expirationDate}
                remindInterval={remindInterval}
                onRemindIntervalChange={handleRemindIntervalChange}
                onExpirationDateChange={handleExpirationDateChange}
                isFormArchived={formStatus === FORM_STATUSES.archived}
                formId={formId}
            />
        },
        formStatus !== FORM_STATUSES.archived && {
            label: 'Form Settings',
            component: accessLevel === FORM_ACCESS_LEVELS.private
                ? <PrivateFormSettings
                    formId={formId}
                    isFormLive={formStatus === 'Active'}
                    strictRecipientsFiltering={strictRecipientsFiltering}
                    onAddRecipient={(recipientsCount) => setHasRecipients(recipientsCount > 0)}
                    onAddFormQuestionRecipient={setHasFormQuestionRecipients}
                    onSetStrictRecipientsFiltering={handleSetStrictRecipientsFiltering}
                />
                : <PublicFormSettings formId={formId}/>
        }
    ];

    const steps = [
        {
            label: 'Form Setup',
            component: <FormStructure
                formRef={formRef}
                accessLevel={accessLevel}
                name={name}
                schema={schema}
                templateId={templateId}
                setSchema={setSchema}
                templates={templates}
                onAccessLevelChange={handleAccessLevelChange}
                onNameChange={handleNameChange}
                handleTemplateChange={handleTemplateChange}
                remindInterval={remindInterval}
                onRemindIntervalChange={handleRemindIntervalChange}
                expirationDate={expirationDate}
                onExpirationDateChange={handleExpirationDateChange}
                isFormArchived={formStatus === FORM_STATUSES.archived}
            />,
            actions: [
                {
                    label: 'Save form',
                    disabled: isFormIncomplete(),
                    onClick: handleSaveForm
                }
            ]
        },
        {
            label: 'Define Recipients / Configure Embedding',
            component: accessLevel === FORM_ACCESS_LEVELS.private
                ? <PrivateFormSettings
                    formId={formId}
                    strictRecipientsFiltering={strictRecipientsFiltering}
                    onAddRecipient={(recipientsCount) => setHasRecipients(recipientsCount > 0)}
                    onAddFormQuestionRecipient={setHasFormQuestionRecipients}
                    onSetStrictRecipientsFiltering={async (value) => {
                        try {
                            await saveForm({
                                id: formId,
                                schema,
                                accessLevel,
                                name,
                                strictRecipientsFiltering: value
                            });
                            setStrictRecipientsFiltering(value);
                        } catch (e) {
                            showErrorNotification('Failed to update "Must appear in all these groups" option');
                        }
                    }}
                />
                : <PublicFormSettings formId={formId}/>,
            actions: [
                {
                    label: 'Next',
                    onClick: () => {
                        setActiveStep(activeStep + 1);
                    }
                }
            ]
        },
        {
            label: 'Go Live (Optional)',
            component: <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign={'center'}
                marginTop={2}
            >
                <Typography variant="h5" gutterBottom>Form Setup Complete!</Typography>
                <Typography gutterBottom>Click below to publish it now and start collecting responses</Typography>
                <div>
                    <Button
                        disabled={accessLevel === FORM_ACCESS_LEVELS.private && (!hasRecipients && !hasFormQuestionRecipients)}
                        onClick={makeFormLive}
                    >Make Form Live
                    </Button>
                </div>
                {
                    accessLevel === FORM_ACCESS_LEVELS.private && (!hasRecipients && !hasFormQuestionRecipients)
                        ? <Alert
                            sx={{
                                maxWidth: 600,
                                marginLeft: 2,
                                marginRight: 2
                            }}
                            severity="warning"
                        >Form cannot be published due to no recipients defined. Please go
                            back and add recipients, or you can configure them later</Alert>
                        : ''
                }
                <Typography gutterBottom marginTop={1}>Or</Typography>
                <div>
                    <Button onClick={navigateBack}>Return to Forms Page</Button>
                </div>
            </Box>,
            actions:
                accessLevel === FORM_ACCESS_LEVELS.private
                    ? [
                        {
                            label: 'Go back',
                            onClick: () => setActiveStep(activeStep - 1)
                        }
                    ]
                    : undefined
        }
    ];

    const getBackdropMessage = () => {
        if (isFormSaving) return 'Saving form';
        if (isPublishingForm) return 'Publishing form';

        return '';
    };

    return (
        <>
            <Box>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={navigateBack}
                >
                    Back
                </Button>
                <Typography
                    variant="h5"
                    py={2}>{title}
                </Typography>
            </Box>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: '100%'
                }}
                variant="outlined"
            >
                {
                    !isEditMode
                        ? <>
                            <Stepper
                                sx={{padding: 2}}
                                activeStep={activeStep}
                            >
                                {steps.map(({label}) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                <Box flexGrow={1}>
                                    {
                                        steps[activeStep].component
                                    }
                                </Box>
                                {
                                    steps[activeStep].actions
                                        ? <StickyFooter>
                                            {
                                                steps[activeStep].actions.map(({label, ...actionProps}) => (
                                                    <Button
                                                        key={label}
                                                        variant="contained"
                                                        {...actionProps}
                                                    >{label}
                                                    </Button>
                                                ))
                                            }
                                        </StickyFooter>
                                        : ''
                                }
                            </Box>
                        </>
                        : <>
                            <Tabs
                                value={currentTabIndex}
                                onChange={handleTabChange}
                                textColor="inherit"
                            >
                                {
                                    tabs.map((tab, index) => <Tab
                                        key={index}
                                        value={index}
                                        label={tab.label}/>)
                                }
                            </Tabs>
                            <Box display="flex" flexDirection="column" flexGrow={1}>
                                <Box flexGrow={1}>
                                    {
                                        tabs[currentTabIndex].component
                                    }
                                </Box>
                                <StickyFooter>
                                    <Button
                                        variant="contained"
                                        disabled={isFormIncomplete()}
                                        onClick={async () => {
                                            await handleSaveForm();
                                            navigateBack();
                                        }}
                                    >Save form
                                    </Button>
                                </StickyFooter>
                            </Box>
                        </>
                }
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={isFormSaving || isPublishingForm}
                >
                    <Stack flexDirection="column" alignItems="center">
                        <CircularProgress size={40}/>
                        <Typography>{getBackdropMessage()}</Typography>
                    </Stack>
                </Backdrop>
            </Paper>
        </>
    );
};

FormEditor.propTypes = {
    mode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default FormEditor;