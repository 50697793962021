import React from 'react';
import {Outlet} from 'react-router-dom';

const SchoolManagementPage = () => {
    return (
        <>
            <Outlet/>
        </>
    );
};

export default SchoolManagementPage;