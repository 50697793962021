import React, {useEffect, useState} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    styled,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {FileDownload, UploadFile} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {useGetMedicalConsentOptionsQuery} from '../../../../api/services/lookupsService';
import {
    useGetMedicalInfoQuery,
    useManageMedicalSectionMutation,
} from '../../../../api/services/studentService';
import {
    downloadProfileMedicalConditionDocument, downloadProfileMedicalEventDocument, downloadProfileMedicalNoteDocument,
    downloadProfileVaccinationDocument
} from '../../../../api/services/filesService';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import {useDispatch, useSelector} from 'react-redux';
import {Item} from './DietaryWelfareEthnicityTab/FreeSchoolMealsFields';
import {retrieveProfile} from '../ProfilesSlice';
import PropTypes from 'prop-types';
import ConfirmationWrapper from '../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../const/StudentProfileConfirmationTypes';
import Uploader from '../../../SchoolManagement/Setup/Uploader';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';

const MedicalFields = styled(Container)({
    display: 'flex', flexDirection: 'column', justifyContent: 'start'
});

const MedicalRow = styled(Box)({
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
});

const DownloadIcon = styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const MedicalInfoTab = ({readonly}) => {
    const [rows, setRows] = useState([]);
    const {studentId} = useParams();
    const [isVaccinationDeleteClicked, setIsVaccinationDeleteClicked] = useState(false);
    const [vaccinationFile, setVaccinationFile] = useState(null);
    const [vaccinationFileToDelete, setVaccinationFileToDelete] = useState(null);
    const [gpDetails, setGpDetails] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [isNotApplicable, setIsNotApplicable] = useState(undefined);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [targetConditionId, setTargetConditionId] = useState(undefined);
    const [totalUploadFilesSize, setTotalUploadFilesSize] = useState(25);

    const [manageMedicalSection] = useManageMedicalSectionMutation();

    const {
        data: medicalInfo = {options: []},
        isLoading: isMedicalInfoLoading,
        refetch: refetchMedicalInfoData
    } = useGetMedicalInfoQuery(studentId);

    const {
        data: medicalConsentOptions = {options: []},
        isLoading: isMedicalConsentOptionsLoading,
        refetch: refetchMedicalConsentsData
    } = useGetMedicalConsentOptionsQuery();

    const refetchData = async () => {
        await refetchMedicalInfoData();
        await refetchMedicalConsentsData();
    };

    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (!isMedicalInfoLoading) {
            setIsNotApplicable(medicalInfo.isNotApplicable);
            setRows(medicalInfo.medicalConditions);
            setGpDetails(medicalInfo.gpInfo);
            setSelectedOptions(medicalInfo.medicalConsentOptions?.options.map(x => x.optionId));
        }
    }, [isMedicalInfoLoading, medicalInfo]);

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
        setTargetConditionId(undefined);
    };

    const handleDocumentsUploaded = async () => {
        setIsUploading(true);
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === targetConditionId);

        if (targetCondition) {
            targetCondition.isEdited = true;

            const newFiles = selectedFiles.map(file => ({
                file,
                summary: '',
                note: '',
                name: file.name,
                isActive: true
            }));

            targetCondition.medicalCondition.documents = [
                ...newFiles,
                ...(targetCondition.medicalCondition.documents || [])
            ];

            setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
                return total + file.size;
            }, 0) / (1024 * 1024))
        }

        setRows(updatedRows);
        setTargetConditionId(undefined);
        handleDialogClose();
    };

    const handleSelectFiles = (files) => {
        const newDocuments = files.filter(file => !selectedFiles.some(s => s.name === file.name));
        setSelectedFiles([...selectedFiles, ...newDocuments]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };

    const updateMedicalCondition = async ({
                                              summary,
                                              note,
                                              isActive,
                                              conditionId,
                                              documentId,
                                              filename,
                                              isDeleteDocument
                                          }) => {
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === conditionId);

        if (!targetCondition) return;

        targetCondition.isEdited = true;

        if (targetCondition.medicalCondition) {
            const conditionDocumentToUpdate = documentId
                ? targetCondition.medicalCondition.documents.find(file => file.id === documentId)
                : targetCondition.medicalCondition.documents.find(file => file.name === filename);

            if (conditionDocumentToUpdate) {
                if (note !== undefined) {
                    conditionDocumentToUpdate.note = note;
                }
                if (summary !== undefined) {
                    conditionDocumentToUpdate.summary = summary;
                }
                if (isDeleteDocument !== undefined) {
                    conditionDocumentToUpdate.isActive = false;

                    if (conditionDocumentToUpdate.file) {
                        setTotalUploadFilesSize(totalUploadFilesSize + (conditionDocumentToUpdate.file.size / 1024 / 1024));
                    }
                }
            }

            if (isActive !== undefined) {
                if (isActive) {
                    targetCondition.medicalCondition.isActive = isActive;
                } else {
                    const originalCondition = medicalInfo.medicalConditions.find(e => e.id === conditionId);

                    setTotalUploadFilesSize(totalUploadFilesSize + targetCondition.medicalCondition.documents.reduce((total, document) => {
                        if (!document.file) {
                            return total;
                        }

                        return total + document.file.size;
                    }, 0) / (1024 * 1024))

                    if (originalCondition?.medicalCondition) {
                        targetCondition.medicalCondition.isActive = isActive;
                    } else {
                        delete targetCondition.isEdited;
                        targetCondition.medicalCondition = null;
                    }
                }
            }
        } else {
            targetCondition.medicalCondition = {isActive: true};
        }
        setRows(updatedRows);
    };

    const handleVaccinationFileUploaded = (file) => {
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 15) {
            setIsFileSizeError(true);
            setVaccinationFile({name: file.name});
        } else {
            setIsFileSizeError(false);
            setVaccinationFile(file);
        }

        if (medicalInfo.vaccination?.fileId) {
            setVaccinationFileToDelete(medicalInfo.vaccination?.fileId);
        }

        setIsVaccinationDeleteClicked(false);
    };

    const handleVaccinationFileDelete = async (fileId) => {
        setIsVaccinationDeleteClicked(true);
        if (vaccinationFile !== null) {
            setVaccinationFile(null);
        } else {
            setVaccinationFileToDelete(fileId);
        }
        setIsFileSizeError(false);
    };

    const handleCheckMedicalConsents = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, optionId]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
    };

    const isNothingChanged = () => {
        const isMedicalRowsInitial = rows.filter(row => row.isEdited).length === 0;
        const isGpDetailsInitial = gpDetails === medicalInfo.gpInfo;
        const isNotApplicableInitial = isNotApplicable === medicalInfo.isNotApplicable;
        const isSelectedOptionsClear = selectedOptions
            .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        const isDeselectedOptionsClear = deselectedOptions
            .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        const isVaccinationFileInitial = vaccinationFile === null && vaccinationFileToDelete === null;
        return isMedicalRowsInitial && isNotApplicableInitial && isGpDetailsInitial && isSelectedOptionsClear && isDeselectedOptionsClear && isVaccinationFileInitial;
    };
    const handleSave = async () => {
        const updatedRows = rows
            .filter(row => row.isEdited)
            .map(row => {
                return {
                    name: row.name,
                    medicalInfoId: row.id,
                    medicalConditionId: row.medicalCondition.id,
                    ...row.medicalCondition
                };
            });

        await manageMedicalSection({
            studentId,
            medicalConditions: updatedRows,
            vaccination: vaccinationFile,
            isNotApplicable,
            gpDetails,
            deletedVaccinationFileId: vaccinationFileToDelete,
            selectedConsents: selectedOptions
                .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)),
            deselectedConsents: deselectedOptions
                .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x))
        }).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
        setVaccinationFile(null);
        setVaccinationFileToDelete(null);
    };

    return (
        isMedicalInfoLoading || isMedicalConsentOptionsLoading
            ? ''
            : <MedicalFields item xs={12} sm={6} px={3}>
                <ConfirmationWrapper
                    readonly={readonly}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: medicalInfo.confirmedAt,
                        confirmedBy: medicalInfo.confirmedBy,
                        enableSaveButton: !isNothingChanged() && !(rows.some(row => row.medicalCondition && row.medicalCondition.documents && row.medicalCondition.documents.some(document => !document.summary))) && !(rows.every(row => !row.medicalCondition || !row.medicalCondition.isActive || selectedOptions.length === 0) && !isNotApplicable),
                        enableConfirmButton: isNothingChanged() && !((rows.every(row => !row.medicalCondition || !row.medicalCondition.isActive) && !isNotApplicable) || selectedOptions.length === 0),
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES.Medical,
                        handleSave,
                        onConfirmSuccess: async () => await refetchData()
                    }}
                >
                    <Box>
                        <Typography variant="h5" py={2}>GP Details</Typography>
                        <Typography variant="body1">Please provide the name and address of your child’s GP</Typography>
                        <TextField
                            multiline
                            fullWidth
                            value={gpDetails}
                            onChange={event => event.target.value.length < 255 && setGpDetails(event.target.value)}
                            disabled={readonly}
                        />
                    </Box>
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography
                        variant="body1"
                    >Do you give consent for the {medicalInfo.schoolName} to administer the
                        following medication to your child during the school day if required?
                    </Typography>
                    {medicalConsentOptions.options?.map((field, index) => (
                        <Box
                            key={index}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography fontWeight={'bold'}>{field.description}</Typography>
                            <Checkbox
                                value={field.id}
                                checked={selectedOptions?.some(id => id === field.id)}
                                onChange={handleCheckMedicalConsents}
                                disabled={readonly}
                            />
                        </Box>
                    ))}
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography variant="body1" sx={{paddingTop: 1}}>Please upload your child’s vaccination record. This can
                        be obtained from your GP surgery.</Typography>
                    <Box py={1} display={'flex'} justifyContent={'space-between'}>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            {
                                readonly
                                    ? ''
                                    : <Button
                                        component="label"
                                        variant="outlined"
                                        startIcon={<UploadFile/>}
                                        sx={{marginRight: 1}}
                                    >
                                        Upload
                                        <input type="file" hidden
                                               onChange={event => handleVaccinationFileUploaded(event.target.files[0])}/>
                                    </Button>
                            }
                            {!isVaccinationDeleteClicked &&
                                <>
                                    <Typography>
                                        {vaccinationFile ? vaccinationFile.name : medicalInfo.vaccination?.fileName}
                                    </Typography>
                                    {medicalInfo.vaccination?.fileId && vaccinationFile === null &&
                                        <IconButton onClick={async () => await downloadFile(
                                            downloadProfileVaccinationDocument,
                                            {
                                                documentId: medicalInfo.vaccination?.fileId,
                                                studentId
                                            })
                                        }>
                                            <DownloadIcon></DownloadIcon>
                                        </IconButton>
                                    }
                                </>
                            }
                        </Box>
                        {
                            readonly
                                ? ''
                                : <Button variant="outlined"
                                          onClick={() => handleVaccinationFileDelete(medicalInfo.vaccination?.fileId)}
                                          sx={{
                                              width: '100px',
                                              visibility: (!medicalInfo.vaccination?.fileId && vaccinationFile === null) || isVaccinationDeleteClicked ? 'hidden' : 'visible'
                                          }}
                                >
                                    Delete
                                </Button>
                        }
                    </Box>
                    {
                        isFileSizeError && <Alert sx={{marginTop: 2}} severity="error">
                            The total size of the file exceeds 15 MB.
                        </Alert>
                    }
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography variant="h5" py={2}>Medical Conditions</Typography>
                    <Item>
                        <Typography fontWeight={'bold'}>I confirm that my child does not have any medical
                            conditions</Typography>
                        <Checkbox
                            value={isNotApplicable}
                            checked={!!isNotApplicable}
                            disabled={readonly || rows.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                            onChange={event => setIsNotApplicable(event.target.checked)}
                        />
                    </Item>
                    {!isNotApplicable && <>
                        <Typography variant="body1">
                            Please complete this section carefully. Information you provide will be used as the school’s
                            formal
                            record of your child’s medical conditions and it is your responsibility to ensure that this
                            information is kept up to date. For any medical condition ticked, please use the text box to
                            provide a summary of your child’s condition and full details of any medication that has been
                            prescribed, and whether they take it at home or will need to be given it during the school day.
                            If
                            there is any documentation that would be helpful for the school to have, e.g. a diagnosis letter
                            or
                            an allergy response plan, please submit this to us securely by using the ‘upload’ button.
                        </Typography>
                        {rows?.map((condition) => {
                                return (
                                    <Box key={condition.name}>
                                        <MedicalRow>
                                            <Typography fontWeight="bold">{condition.name}</Typography>
                                            <Checkbox
                                                checked={!!condition.medicalCondition && condition.medicalCondition.isActive}
                                                onChange={event => updateMedicalCondition({
                                                    isActive: event.target.checked,
                                                    conditionId: condition.id
                                                })}
                                                disabled={readonly}
                                            />
                                        </MedicalRow>
                                        {condition.medicalCondition && condition.medicalCondition.isActive
                                            ? <>

                                                <Box py={1} display="flex" alignItems="center">
                                                    {
                                                        readonly
                                                            ? ''
                                                            : <Button
                                                                variant="contained"
                                                                startIcon={<UploadFile/>}
                                                                onClick={() => {
                                                                    setTargetConditionId(condition.id);
                                                                    setOpenAddDocumentsDialog(true);
                                                                }}
                                                            >
                                                                Upload Documents
                                                            </Button>
                                                    }
                                                </Box>
                                                {
                                                    condition?.medicalCondition?.documents
                                                        ?.filter(e => e.isActive)
                                                        .map(file => {
                                                                return <>
                                                                    <Box key={file.id} display={'flex'}
                                                                         alignItems={'center'} py={1}>
                                                                        <Accordion sx={{width: '100%'}}
                                                                                   defaultExpanded={!file.fileId}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ArrowDropDownIcon/>}>
                                                                                <Box display="flex"
                                                                                     justifyContent="space-between"
                                                                                     alignItems="center"
                                                                                     sx={{
                                                                                         maxWidth: {
                                                                                             xs: '250px',
                                                                                             sm: '395px',
                                                                                             md: '695px',
                                                                                             lg: '995px',
                                                                                             xl: '1050px',
                                                                                         }
                                                                                     }}>
                                                                                    < Typography
                                                                                        sx={{
                                                                                            overflow: 'hidden',
                                                                                            textOverflow: 'ellipsis',
                                                                                            whiteSpace: 'nowrap',
                                                                                            flexGrow: 1,
                                                                                            marginRight: '8px',
                                                                                        }}
                                                                                    >
                                                                                        {file?.name}
                                                                                    </Typography>
                                                                                    {file.fileId && (
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                flexShrink: 0,
                                                                                            }}
                                                                                            onClick={async (e) => {
                                                                                                e.stopPropagation()
                                                                                                await downloadFile(downloadProfileMedicalConditionDocument, {
                                                                                                    documentId: file.fileId,
                                                                                                    studentId
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <DownloadIcon/>
                                                                                        </IconButton>
                                                                                    )}
                                                                                </Box>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label={'Summary'}
                                                                                    required
                                                                                    value={file.summary}
                                                                                    onChange={event => updateMedicalCondition({
                                                                                        summary: event.target.value,
                                                                                        conditionId: condition.id,
                                                                                        documentId: file.id,
                                                                                        filename: file?.name
                                                                                    })}
                                                                                    disabled={readonly}
                                                                                />
                                                                                <TextField
                                                                                    sx={{my: 2}}
                                                                                    multiline
                                                                                    fullWidth
                                                                                    label={'Note'}
                                                                                    value={file.note}
                                                                                    onChange={event => updateMedicalCondition({
                                                                                        note: event.target.value,
                                                                                        conditionId: condition.id,
                                                                                        documentId: file.id,
                                                                                        filename: file?.name
                                                                                    })}
                                                                                    disabled={readonly}
                                                                                />
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                        <IconButton
                                                                            onClick={() => updateMedicalCondition({
                                                                                conditionId: condition.id,
                                                                                documentId: file.id,
                                                                                filename: file?.name,
                                                                                isDeleteDocument: true
                                                                            })}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>

                                                                    </Box>

                                                                </>
                                                            }
                                                        )
                                                }
                                                < AdmicityDialog
                                                    handleClose={handleDialogClose}
                                                    title={'Add Documents'}
                                                    open={openAddDocumentsDialog}
                                                    actions={[
                                                        {label: 'Cancel', onClick: handleDialogClose},
                                                        {
                                                            label: 'Upload',
                                                            onClick: handleDocumentsUploaded,
                                                            disabled: isUploading || selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                                                            startIcon: isUploading
                                                                ? <CircularProgress color="inherit"
                                                                                    size={16}/>
                                                                : undefined
                                                        }
                                                    ]}
                                                    maxWidth={'md'}
                                                >
                                                    <Uploader
                                                        totalUploadSizeInMb={totalUploadFilesSize}
                                                        onFilesSelect={handleSelectFiles}
                                                        onFileDelete={handleFileDeleteFromUploadContext}
                                                        onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                                                        value={selectedFiles}
                                                        readonly={isUploading}
                                                    />
                                                </AdmicityDialog>
                                            </>
                                            : <></>
                                        }
                                    </Box>
                                );
                            }
                        )}
                    </>
                    }
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography variant="h5" py={2}>Medical Events</Typography>
                    {medicalInfo.medicalEvents?.map(event => {
                            return (
                                <Box key={event}>
                                    <MedicalRow>
                                        <Box>
                                            <Typography
                                                fontWeight="bold">{event.eventType === event.description
                                                ? event.eventType
                                                : `${event.eventType} - ${event.description}`}
                                            </Typography>
                                            <Typography>
                                                {event.eventDate && `Date: ${moment(event.eventDate).format('DD-MMM-YYYY')}`}
                                            </Typography>
                                            <Typography>
                                                {event.followUpDate && `Follow Up: ${moment(event.followUpDate).format('DD-MMM-YYYY')}`}
                                            </Typography>
                                        </Box>
                                    </MedicalRow>
                                    {event.documents && <>
                                        {
                                            event.documents
                                                .map(file =>
                                                    <Box key={file.fileId} display={'flex'}
                                                         alignItems={'center'} py={1}>
                                                        <Accordion sx={{width: '100%'}}>
                                                            <AccordionSummary expandIcon={
                                                                <ArrowDropDownIcon/>}>
                                                                <Box display="flex"
                                                                     justifyContent="space-between"
                                                                     alignItems="center"
                                                                     sx={{
                                                                         maxWidth: {
                                                                             xs: '250px',
                                                                             sm: '395px',
                                                                             md: '695px',
                                                                             lg: '995px',
                                                                             xl: '1050px',
                                                                         }
                                                                     }}>
                                                                    <Typography
                                                                        sx={{
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            flexGrow: 1,
                                                                            marginRight: '8px',
                                                                        }}
                                                                    >
                                                                        {file.attachmentName}
                                                                    </Typography>
                                                                    <IconButton
                                                                        sx={{
                                                                            flexShrink: 0,
                                                                        }}
                                                                        onClick={async (e) => {
                                                                            e.stopPropagation()
                                                                            await downloadFile(downloadProfileMedicalEventDocument, {
                                                                                documentId: file.fileId,
                                                                                studentId
                                                                            })
                                                                        }}
                                                                    >
                                                                        <DownloadIcon/>
                                                                    </IconButton>
                                                                </Box>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <TextField
                                                                    fullWidth
                                                                    label={'Summary'}
                                                                    value={file.summary}
                                                                />
                                                                <TextField
                                                                    sx={{my: 2}}
                                                                    multiline
                                                                    fullWidth
                                                                    label={'Note'}
                                                                    value={file.note}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>
                                                )
                                        }
                                    </>
                                    }
                                </Box>
                            )
                        }
                    )}
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <Typography variant="h5" py={2}>Medical Notes</Typography>
                    {medicalInfo.medicalNotes?.map(note =>
                        <>
                            <MedicalRow key={note}>
                                <Typography fontWeight="bold">{note.summary}</Typography>
                                {note.fileId &&
                                    <IconButton
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                        onClick={async (e) => {
                                            e.stopPropagation()
                                            await downloadFile(downloadProfileMedicalNoteDocument, {
                                                documentId: note.fileId, studentId
                                            })
                                        }}
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                }
                            </MedicalRow>
                            <Typography>{note.note}</Typography>
                        </>
                    )}

                </ConfirmationWrapper>
            </MedicalFields>
    );
};

MedicalInfoTab.propTypes = {
    readonly: PropTypes.bool
};

export default MedicalInfoTab;
