import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';

const AdmicityTabs = ({tabs, variant}) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    return (
        <>
            <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                textColor="inherit"
                variant={variant}
            >
                {
                    tabs.map((tab, index) =>
                        <Tab
                            key={index}
                            value={index}
                            label={tab.label}
                        />
                    )
                }
            </Tabs>
            {tabs[currentTabIndex].component}
        </>
    );
};

AdmicityTabs.defaultProps = {
    tabs: [],
    variant: 'standard'
};

AdmicityTabs.propTypes = {
    tabs: PropTypes.array,
    variant: PropTypes.string
};

export default AdmicityTabs;