import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../../../../axios';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import {TOKEN_STATUSES_NAMES} from '../../../../constants/tokenStatuses';

const initialState = {
    datasourceProperties: {},
    tokens: [],
    isLoadingTokens: true
};

export const getDataSourceProperties = createAsyncThunk(
    'getDataSourceProperties',
    async (thunkAPI) => {
        try {
            const response = await api.get('/SIMS/datasource/credentials/basic');

            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to retrieve SIMS credentials.',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const updateDataSourceProperties = createAsyncThunk(
    'updateDataSourceProperties',
    async (body, thunkAPI) => {
        try {
            const response = await api.put('/SIMS/datasource', body);

            thunkAPI.dispatch(showSnackbar({
                message: 'SIMS credentials have been successfully updated.',
                severity: 'success'
            }));

            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to update SIMS credentials.',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const setupDataSourceProperties = createAsyncThunk(
    'setupDataSourceProperties',
    async (body, thunkAPI) => {
        try {
            const response = await api.post('/SIMS/datasource', body);

            thunkAPI.dispatch(showSnackbar({
                message: 'SIMS credentials have been successfully saved.',
                severity: 'success'
            }));
            thunkAPI.dispatch(getDataSourceProperties());

            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to save SIMS credentials',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const getTokens = createAsyncThunk(
    'getTokens',
    async (thunkAPI) => {
        try {
            const response = await api.get('/mis-integration/tokens/list');

            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to retrieve tokens.',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const createToken = createAsyncThunk(
    'createToken',
    async (body, thunkAPI) => {
        try {
            const response = await api.post('/mis-integration/tokens/create', body);

            thunkAPI.dispatch(getTokens());

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.response.data});
        }
    }
);

export const updateToken = createAsyncThunk(
    'updateToken',
    async (body, thunkAPI) => {
        try {
            await api.put('/mis-integration/tokens/update', body);

            thunkAPI.dispatch(getTokens());
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to update token information.',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.response.data});
        }
    }
);

export const revokeToken = createAsyncThunk(
    'revokeToken',
    async (body = {}, thunkAPI) => {
        try {
            await api.post('/mis-integration/tokens/revoke', body);

            thunkAPI.dispatch(getTokens());
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to revoke token.',
                severity: 'error'
            }));

            return thunkAPI.rejectWithValue({error: error.response.data});
        }
    }
);

export const misIntegrationSlice = createSlice({
    name: 'misIntegration',
    initialState,
    reducers: {
        resetState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDataSourceProperties.fulfilled, (state, action) => {
                state.datasourceProperties = {...state.datasourceProperties, ...action.payload};
            })
            .addCase(getTokens.fulfilled, (state, action) => {
                state.tokens = action.payload.tokens.map(item => ({
                    ...item,
                    status: TOKEN_STATUSES_NAMES[item.status] ?? ''
                }));
                state.isLoadingTokens = false;
            })
            .addCase(getTokens.rejected, (state) => {
                state.isLoadingTokens = false;
            });
    },
});

export const {resetState} = misIntegrationSlice.actions;

export default misIntegrationSlice.reducer;