import {generateUUID} from '../../../../utility/uuidUtil';

export const getComponentByType = (type) => {
    switch (type) {
        case 'singleText':
            return {
                type, label: 'Single text line question', description: '', validation: {min: 1, max: 50},
            };
        case 'textBox':
            return {
                type, label: 'Textbox question', description: '',
            };
        case 'checkboxQuestion':
            return {
                type, label: 'Checkbox Question', description: '',
            };
        case 'signature':
            return {
                type, label: 'Signature'
            };
        case 'additionalNotes':
            return {
                type, label: 'Notes', description: '',
            };
        case 'attachment':
            return {
                type, label: 'Attachment', description: '', attachments: [],
            };
        case 'image':
            return {
                type, label: 'Image', description: '', attachments: [],
            };
        case 'multipleChoice':
            return {
                type,
                label: 'Multiple Choice Question',
                description: '',
                options: [{id: generateUUID(), description: ''}, {
                    id: generateUUID(),
                    description: ''
                }, {id: generateUUID(), description: ''}],
            };
        case 'singleChoice':
            return {
                type,
                label: 'Single Choice Question',
                description: '',
                options: [{id: generateUUID(), description: ''}, {
                    id: generateUUID(),
                    description: ''
                }, {id: generateUUID(), description: ''}],
            };
        case 'linearScale':
            return {
                type,
                label: 'Linear Scale',
                description: '',
                startFrom: 1,
                endAt: 5,
                startLabel: '',
                endLabel: ''
            };
        case 'tickBoxGrid':
            return {
                type,
                label: 'Tick Box Grid',
                description: '',
                columns: [{id: generateUUID(), title: ''}],
                rows: [{id: generateUUID(), title: ''}],
            };
        case 'multipleChoiceGrid':
            return {
                type,
                label: 'Multiple Choice Grid',
                description: '',
                columns: [{id: generateUUID(), title: ''}],
                rows: [{id: generateUUID(), title: ''}],
            };
        case 'dropdown':
            return {
                type,
                label: 'Dropdown',
                description: '',
                options: [{id: generateUUID(), description: ''}, {
                    id: generateUUID(),
                    description: ''
                }, {id: generateUUID(), description: ''}],
            };
        default:
            break;
    }
}