import React from 'react';
import {colors, styled} from '@mui/material';
import PropTypes from 'prop-types';

const Span = styled('span')(({theme, color}) => ({
    backgroundColor: colors[color][50],
    color: colors[color][700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    fontWeight: 600,
    fontSize: 12
}));

const AdmicityStatus = ({color, label}) => {
    return <Span color={color}>{label}</Span>
}

AdmicityStatus.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string
};

export default AdmicityStatus;