import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hideSnackbar} from '../AppLayout/ApplicationSlice';
import {Alert, List, ListItem, ListItemText, Snackbar} from '@mui/material';
import NOTIFICATION_TYPE from '../constants/notificationTypes';

const GlobalSnackbar = () => {
    const notification = useSelector((state) => state.application.notification);
    const dispatch = useDispatch();
    const snackbarMessage = {
        [NOTIFICATION_TYPE.STANDARD]: notification.snackbar.message,
        [NOTIFICATION_TYPE.DETAILED]:
            <div>
                {notification.detailedSnackbar.title}
                <List dense>
                    {
                        notification.detailedSnackbar.messages?.map((errorMessage, index) =>
                            <ListItem sx={{p: 0}} key={index}>
                                <ListItemText primary={errorMessage}/>
                            </ListItem>
                        )
                    }
                </List>
            </div>
    }[notification.type];

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(hideSnackbar());
    };

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={notification.duration}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert
                onClose={handleClose}
                severity={notification.severity}
                sx={{width: '100%'}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
};

export default GlobalSnackbar;