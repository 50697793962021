import React, {useEffect} from 'react';
import useTableState from '../../utility/hooks/useTableState';
import useNotification from '../../utility/hooks/useNotification';
import PropTypes from 'prop-types';
import FILTER_OPERATIONS from '../../constants/filterOperations';
import moment from 'moment';
import {useOutletContext} from 'react-router-dom';

const buildColumnFiltersOnApply = (filters, filtersFns) =>
    ({
            filters: filters.map(x => ({
                propertyName: x.id,
                value: moment.isMoment(x.value) ? x.value.format('YYYY-MM-DDTHH:mm:ss') : x.value,
                operation: filtersFns[x.id]
            }))
        }
    );

const withPaginationV2 = (
    Component,
    fetchFunction = {
        func: () => {
        },
        prop: {},
        settings: {}
    },
    overrides = {}
) => {
    function TableHOC(
        {
            initialState = {
                columnFilters: []
            },
            ...rest
        }) {
        const {key} = useOutletContext();
        const {
            pagination,
            filters,
            columnVisibility,
            onFiltersChange,
            onPaginationChange,
            onColumnVisibilityChange
        } = useTableState(key);
        const columnFilters = Object.keys(filters).length > 0
            ? filters
            : initialState.columnFilters.length > 0
                ? buildColumnFiltersOnApply(
                    initialState.columnFilters,
                    rest.columns
                        .filter(c => c.enableColumnFilter !== false || c.enableColumnFilter === undefined)
                        .reduce((fns, c) => {
                            fns[c.accessorKey] = c.filterFn ?? FILTER_OPERATIONS.CONTAINS;

                            return fns;
                        }, {}))
                : filters;
        const {data, isLoading, isFetching, isError, error} = fetchFunction.func({
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageIndex,
            ...columnFilters,
            ...fetchFunction.props
        }, fetchFunction.settings);
        const {showErrorNotification} = useNotification();

        useEffect(() => {
            if (isError) {
                showErrorNotification(error?.message ?? '');
            }
        }, [isError]);

        const handleColumnFiltersApply = (filters, filtersFns) =>
            onFiltersChange(
                overrides.buildFiltersBodyOnApply
                    ? overrides.buildFiltersBodyOnApply(filters, filtersFns)
                    : buildColumnFiltersOnApply(filters, filtersFns)
            );

        const handleColumnVisibilityChange = values => onColumnVisibilityChange({default: values});

        return (
            <Component
                {...rest}
                initialState={{
                    columnFilters: [
                        ...Object.values(columnFilters)
                            .filter(x => x)
                            .flatMap(x => x.map(f => ({id: f.propertyName, value: f.value})))
                    ]
                }}
                isLoading={isLoading}
                isRefetching={isFetching}
                data={data?.items ?? []}
                totalItemCount={data?.totalCount ?? 0}
                pagination={pagination}
                columnVisibility={columnVisibility.default}
                onPaginationModelChange={onPaginationChange}
                onColumnVisibilityChange={handleColumnVisibilityChange}
                onColumnFiltersApply={handleColumnFiltersApply}
            />
        );
    }

    TableHOC.propTypes = {
        initialState: PropTypes.object
    };

    return TableHOC;
};

withPaginationV2.PropTypes = {
    Component: PropTypes.element,
    fetchFunction: PropTypes.shape({
        func: PropTypes.func.isRequired,
        props: PropTypes.object,
        settings: PropTypes.object
    }).isRequired,
    overrides: PropTypes.shape({
        buildFiltersBodyOnApply: PropTypes.func
    })
};

export default withPaginationV2;
