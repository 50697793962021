import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery, useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const AdmicityDialog = ({open, handleClose, title, contentText, children, actions, maxWidth = 'sm'}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <IconButton
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[600],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    {contentText}
                </DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                {actions.map((action, index) => (
                    <Button
                        key={index}
                        fullWidth={fullScreen}
                        onClick={action.onClick}
                        disabled={action.disabled ?? false}
                        startIcon={action.startIcon ?? ''}
                        type="submit"
                    >
                        {action.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

AdmicityDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string,
    children: PropTypes.node,
    actions: PropTypes.array,
    maxWidth: PropTypes.string
};

export default AdmicityDialog;