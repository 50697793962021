const FILTER_OPERATIONS = {
    NONE: 0,
    CONTAINS: 1,
    GREATER_THAN: 2,
    LESS_THAN: 3,
    DATE_RANGE: 4,
    MULTISELECT: 5,
    BETWEEN_INCLUSIVE: 6,
    EQUALS: 7,
};

export default FILTER_OPERATIONS;