import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    createDropdown,
    Editor,
    EditorProvider,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';
import {Box} from '@mui/material';
import {FORM_ACCESS_LEVELS} from '../../../../../../constants/formAccessLevels';
import ColorPicker from './ColorPicker';
import ToggleColorPickerButton from './BtnToogleColorPicker';

const schoolPlaceHolder = [
    ['School Name', 'InsertText', ' @SchoolName ']
];

const placeholders = [
    ['Parent', 'InsertText', ' @Parent '],
    ['Student Forename', 'InsertText', ' @StudentForename '],
    ['Student Full Name', 'InsertText', ' @StudentFullName ']
];

const RichEditor = ({index, value, onChange, isFormLive, accessLevel}) => {
    const [showPicker, setShowPicker] = useState(false);
    const buttonRef = useRef(null);
    const [textColor, setTextColor] = useState('#000000');

    const handlePlaceholderSelect = (placeholder) => {
        const newText = value + placeholder;
        onChange({index, description: newText});
    };

    const PlaceholderDropdown = createDropdown('Placeholders',
        accessLevel === FORM_ACCESS_LEVELS.private
            ? [...schoolPlaceHolder, ...placeholders]
            : schoolPlaceHolder);

    const applyColorCommand = (color) => {
        setTextColor(color);
        document.execCommand('styleWithCSS', false, true);
        document.execCommand('foreColor', false, color);
    };

    return (
        <EditorProvider>
            <Toolbar>
                <BtnUndo/>
                <BtnRedo/>
                <Separator/>
                <BtnBold/>
                <BtnItalic/>
                <BtnUnderline/>
                <BtnStrikeThrough/>
                <ToggleColorPickerButton onClick={() => setShowPicker(!showPicker)} ref={buttonRef} color={textColor}/>
                <Separator/>
                <BtnNumberedList/>
                <BtnBulletList/>
                <Separator/>
                <BtnLink/>
                <BtnClearFormatting/>
                <Separator/>
                <BtnStyles/>
                <Separator/>
                {showPicker &&
                    <ColorPicker
                        applyColorCommand={applyColorCommand}
                        onClose={() => setShowPicker(false)}
                        anchorEl={buttonRef.current}
                    />}
                <PlaceholderDropdown onChange={(value) => handlePlaceholderSelect(value)}/>
            </Toolbar>
            <Box sx={{cursor: 'text'}}>
                <Editor disabled={isFormLive} value={value}
                        onChange={e => onChange({index, description: e.target.value})}/>
            </Box>
        </EditorProvider>
    );
};

RichEditor.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isFormLive: PropTypes.bool.isRequired,
    accessLevel: PropTypes.number.isRequired
};

export default RichEditor;