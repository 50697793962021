import React from 'react';
import {Box, Stack, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';

const SingleLine = ({
                        index,
                        id,
                        value,
                        onChange,
                        description,
                        enableValidation,
                        isEditMode,
                        isFormArchived,
                        readonly
                    }) => {
    return (
        <Box>
            {isEditMode
                ? <>
                    <TextField sx={{my: 1}} fullWidth multiline value={description}
                               onChange={e => onChange({index, description: e.target.value})}
                               label={!description ? 'Write a question for a short answer' : null}
                               disabled={isFormArchived}
                               InputLabelProps={{
                                   shrink: false,
                               }}/>
                    <Stack flexDirection="row"
                           justifyContent="space-between"
                           gap={3}>
                        <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                          isFormArchived={isFormArchived}/>
                    </Stack>
                </>
                : <>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    {!readonly
                        ? <TextField fullWidth value={value} required={enableValidation}
                                     onChange={e => e.target.value.length < 100
                                         ? onChange({id, value: e.target.value})
                                         : {}}
                        />
                        : <Typography sx={{
                            width: {xs: '80vw', sm: '60vw', md: '45vw', lg: '45vw', xl: '45vw'},
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word'
                        }}>
                            {value}
                        </Typography>
                    }
                </>
            }
        </Box>
    );
};

SingleLine.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    validation: PropTypes.object,
    description: PropTypes.string,
    onChange: PropTypes.func,
    enableValidation: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    validationError: PropTypes.string,
    readonly: PropTypes.bool,
};

export default SingleLine;