const TOKEN_STATUSES = {
    active: 0,
    revoked: 1
};

const TOKEN_STATUSES_NAMES = {
    [TOKEN_STATUSES.active]: 'Active',
    [TOKEN_STATUSES.revoked]: 'Revoked'
};

export {
    TOKEN_STATUSES,
    TOKEN_STATUSES_NAMES
};