import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const placeholderSignature = 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2264%22%20height%3D%2264%22%20style%3D%22fill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bstroke-linejoin%3Around%3Bstroke-miterlimit%3A2%22%20viewBox%3D%220%200%201280%201280%22%3E%3Cpath%20d%3D%22M0%200h1280v1280H0z%22%20style%3D%22fill%3A%23e5e9ed%22%2F%3E%3Cpath%20d%3D%22M910%20410H370v470h540V410Zm-57.333%2057.333v355.334H427.333V467.333h425.334Z%22%20style%3D%22fill%3A%23cad3db%22%2F%3E%3Cpath%20d%3D%22M810%20770H480v-60l100-170%20130%20170%20100-65v125Z%22%20style%3D%22fill%3A%23cad3db%22%2F%3E%3Ccircle%20cx%3D%22750%22%20cy%3D%22550%22%20r%3D%2250%22%20style%3D%22fill%3A%23cad3db%22%20transform%3D%22translate(10%2010)%22%2F%3E%3C%2Fsvg%3E';

const Signature = ({id, value, onChange, isEditMode, isSubmitted}) => {
    const [sigPad, setSigPad] = useState({});
    const [signatureDataUrl, setSignatureDataUrl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setSignatureDataUrl(value);
    }, [value]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        sigPad?.clear();
    };

    const handleAddSignature = () => {
        if (sigPad == null || sigPad.isEmpty()) {
            return;
        }

        // change result image size to 100x100 size
        const canvas = sigPad.getCanvas();
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const originalCanvas = canvas.cloneNode(true);
        originalCanvas.getContext('2d').putImageData(imageData, 0, 0);
        canvas.width = 100;
        canvas.height = 100;
        ctx.scale(0.25, 0.25);
        ctx.drawImage(originalCanvas, 0, 0);

        const signatureUrl = sigPad.getTrimmedCanvas().toDataURL('image/svg+xml');
        setSignatureDataUrl(signatureUrl);
        onChange({
            id,
            value: signatureUrl
        });
        handleCloseDialog();
    };

    const handleClearSignature = () => sigPad?.clear();

    return (
        <Box>
            {isEditMode
                ? <>
                    <img alt={'signature'} src={placeholderSignature}/>
                </>
                : <>
                    <Box display={'flex'}>
                        <img alt={'signature'} src={signatureDataUrl ?? placeholderSignature}
                             style={{cursor: isSubmitted ? '' : 'pointer'}}
                             onClick={() => isSubmitted ? {} : setOpenDialog(true)}/>
                        {!isSubmitted &&
                            <Button onClick={() => setOpenDialog(true)}>
                                {value ? 'Re-enter Signature' : 'Add Signature'}
                            </Button>}
                    </Box>

                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>
                            Add Signature
                            <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box width={'400px'} height={'400px'}>
                                <SignaturePad ref={(ref) => setSigPad(ref)} canvasProps={{width: 400, height: 400}}/>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="primary"
                                    onClick={() => handleClearSignature()}>Clear</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => handleAddSignature()}>Save</Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </Box>
    );
};

Signature.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isSubmitted: PropTypes.bool,
};

export default Signature;