import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SchoolSetupForm from './SchoolSetupForm';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import SchoolMembersSetup from './SchoolMembersSetup';
import {showDetailedSnackbar, showSnackbar} from '../../../AppLayout/ApplicationSlice';
import AdmicityTabs from '../../../shared-components/AdmicityTabs';
import {Box, Button} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
    useCreateSchoolMutation,
    useGetSchoolQuery,
    useUpdateSchoolMutation
} from '../../../api/services/schoolManagementService';

const SchoolSetup = ({mode, title}) => {
    const {id} = useParams();
    const isEditMode = mode === 'edit';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        data: schoolInfo = {
            name: '',
            dfeNumber: '',
            notes: '',
            domain: ''
        },
        isError,
    } = useGetSchoolQuery(id, {skip: !isEditMode});
    const [saveSchool, {
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = !isEditMode
        ? useCreateSchoolMutation()
        : useUpdateSchoolMutation();

    useEffect(() => {
        if (isError) {
            dispatch(showSnackbar({message: 'Failed retrieve school info', severity: 'error'}));
        }
    }, [isError]);

    useEffect(() => {
        if (isFormSubmitError || isFormSubmitSuccess) {
            if (isFormSubmitSuccess) {
                dispatch(showSnackbar({
                    message: 'School saved successfully.',
                    severity: 'success'
                }));
                navigate('/management');
            }
            if (isFormSubmitError) {
                if (submitError.data.validationErrors) {
                    dispatch(showDetailedSnackbar({
                        severity: 'error',
                        title: 'Failed to save school.',
                        messages: submitError.data.validationErrors.map(e => e.errorMessage)
                    }));
                } else {
                    dispatch(showSnackbar({
                        message: 'Something went wrong.',
                        severity: 'error'
                    }));
                }
            }
        }
    }, [isFormSubmitSuccess, isFormSubmitError]);

    const handleSaveForm = async (payload) => {
        await saveSchool(payload);
    };

    const tabs = [
        {
            label: 'General',
            component: <SchoolSetupForm
                onSubmit={handleSaveForm}
                editMode
                schoolInfo={schoolInfo}
            />
        },
        {
            label: 'Users',
            component: <SchoolMembersSetup
                onSubmit={handleSaveForm}
                schoolInfo={schoolInfo}
            />
        }
    ];

    return <>
        <Box paddingTop={2}>
            <Button
                variant="text"
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={() => navigate('/management')}
            >
                Back
            </Button>
            <Typography
                variant="h5"
                py={2}>
                {isEditMode ? `${schoolInfo.name} — ${title}` : title}
            </Typography>
        </Box>
        {
            isEditMode
                ? <AdmicityTabs tabs={tabs}/>
                : <SchoolSetupForm
                    schoolInfo={schoolInfo}
                    onSubmit={handleSaveForm}
                />
        }
    </>;
};

SchoolSetup.propTypes = {
    mode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default SchoolSetup;