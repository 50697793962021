import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../../axios';
import {showSnackbar} from '../../AppLayout/ApplicationSlice';

const initialState = {
    schoolList: {
        items: [],
        totalCount: 0,
        isLoading: false
    },
    currentGroup: {}
};

export const retrieveSchoolList = createAsyncThunk(
    'getSchoolList',
    async (body, thunkAPI) => {
        try {
            const response = await api.post('/school/list', body);
            return response.data;
        } catch (error) {
            thunkAPI.dispatch(showSnackbar({
                message: 'Failed to retrieve school list',
                severity: 'error'
            }));
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const schoolManagementPageSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {
        resetState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveSchoolList.pending, (state) => {
                state.schoolList = {...state.schoolList, isLoading: true};
            })
            .addCase(retrieveSchoolList.fulfilled, (state, action) => {
                state.schoolList = {
                    items: action.payload.items ?? [],
                    totalCount: action.payload.totalCount,
                    isLoading: false
                };
            })
            .addCase(retrieveSchoolList.rejected, (state) => {
                state.schoolList = {...state.schoolList, isLoading: false};
            })
    },
});

export default schoolManagementPageSlice.reducer;