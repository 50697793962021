import axios from 'axios';

axios.defaults.withCredentials = true;

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const setupAxiosInterceptor = (handleRefresh) => {
    let isRefreshing = false;
    let failedRequestsQueue = [];

    const processQueue = (error, token = null) => {
        failedRequestsQueue.forEach(promise => {
            if (error) {
                promise.reject(error);
            } else {
                promise.resolve(token);
            }
        });

        failedRequestsQueue = [];
    };

    api.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            if (error.response.status === 401 &&
                !originalRequest._retry &&
                !originalRequest.url.endsWith('/account/refresh')
            ) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    originalRequest._retry = true;

                    try {
                        await api.post('/account/refresh');
                        isRefreshing = false;
                        processQueue(null);
                        handleRefresh();

                        return api(originalRequest);
                    } catch (refreshError) {
                        processQueue(refreshError);
                        isRefreshing = false;

                        if (window.location.pathname !== '/login') {
                            window.location.href = '/login';
                        }

                        return Promise.reject(refreshError);
                    }
                }

                return new Promise((resolve, reject) => failedRequestsQueue.push({resolve, reject}))
                    .then(() => api(originalRequest))
                    .catch(err => Promise.reject(err));
            }

            if (error.response.status === 403) {
                window.location.href = '/';
            }

            return Promise.reject(error);
        }
    );
};

export default api;