import React, {useEffect, useState} from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {Box, InputAdornment, Popover, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import IconButton from '@mui/material/IconButton';
import {formatDate} from '../utility/dateUtil';
import CancelIcon from '@mui/icons-material/Close';

function AdmicityDatePicker({onChange, startDate, endDate, removeFilter}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateRange, setDateRange] = useState([
        {
            startDate,
            endDate,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        setDateRange([{startDate, endDate, key: 'selection'}])
    }, [startDate, endDate]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onChange(dateRange);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const buildValue = () => dateRange[0]?.startDate && dateRange[0]?.endDate
        ? `${formatDate(dateRange[0]?.startDate)}-${formatDate(dateRange[0]?.endDate)}`
        : '';

    const handleChangeDate = (dates) => {
        const start = new Date(dates.startDate);
        const end = new Date(dates.endDate);

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 0);

        setDateRange([{startDate: start.toISOString(), endDate: end.toISOString(), key: dates.key}])
    }

    return (
        <Box>
            <TextField
                id="outlined-basic"
                size={'small'}
                variant="outlined"
                onClick={handleClick}
                value={buildValue()}
                title={buildValue()}
                sx={{
                    '& .MuiInputBase-input': {
                        fontWeight: 400,
                        fontSize: '0.875rem',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={startDate && endDate ? removeFilter : handleClick}
                                sx={{
                                    padding: '0px 0px',
                                }}
                            >
                                {!startDate && !endDate ? <CalendarTodayIcon/> : <CancelIcon/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleClose();
                    }
                }}
            >
                <DateRange
                    onChange={item => handleChangeDate(item.selection)}
                    ranges={dateRange}
                />
            </Popover>
        </Box>
    );
}

AdmicityDatePicker.propTypes = {
    onChange: PropTypes.func,
    removeFilter: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default AdmicityDatePicker;