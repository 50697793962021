export const STUDENT_PROFILE_CONFIRMATION_TYPES = {
    Consents: 1,
    Contacts: 2,
    Basic: 3,
    Medical: 4,
    Sen: 5,
    Dietary: 6,
    FreeSchoolMeals: 7,
    Welfare: 8,
    Cultural: 9,
    TravelMode: 10
}
