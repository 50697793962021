import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import {generateUUID} from '../../../../utility/uuidUtil';
import PropTypes from 'prop-types';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {getComponentByType} from './FormBuilderComponents';
import {FORM_BUILDER_COMPONENTS, FormBuilderComponentTypes} from './ComponentList';
import ComponentBuilder from './Components/ComponentBuilder';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import ShowFieldWhen from './ShowFieldWhen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import ConfirmRemoveDialog from './ConfirmRemoveDialog';
import {useGetRelatedFormQuestionAssignmentQuery} from '../../../../api/services/formAssignmentsService';
import Tooltip from '@mui/material/Tooltip';

export const availableComponents = [
    FormBuilderComponentTypes.Dropdown,
    FormBuilderComponentTypes.SingleChoice,
    FormBuilderComponentTypes.CheckboxQuestion,
    FormBuilderComponentTypes.MultipleChoice
];

const FormBuilder = ({
                         schema,
                         setSchema,
                         submitMode = false,
                         handleSubmit,
                         data,
                         readOnly,
                         isFormArchived,
                         placeholderValues,
                         accessLevel,
                         formId
                     }) => {
    const [previewMode, setPreviewMode] = useState(false);
    const [submitData, setSubmitData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [currentComponent, setCurrentComponent] = useState(undefined);
    const [relatedComponents, setRelatedComponents] = useState([]);
    const [componentToConfirmRemove, setComponentToConfirmRemove] = useState(undefined);

    const {data: relatedFormQuestionAssignData} = useGetRelatedFormQuestionAssignmentQuery(formId, {skip: submitMode || !formId});

    useEffect(() => {
        setRelatedComponents(schema.filter(e => availableComponents.includes(e.type) && e.id !== currentComponent?.id));
    }, [schema]);

    const handleChangeEdit = ({
                                  index,
                                  description,
                                  validation,
                                  enableValidation,
                                  option,
                                  optionId,
                                  isAdd,
                                  isRemove,
                                  files,
                                  listKey,
                                  startFrom,
                                  endAt,
                                  startLabel,
                                  endLabel
                              }) => {
        const newForm = JSON.parse(JSON.stringify(schema));
        if (description !== undefined) {
            newForm[index].description = description;
        }
        if (validation !== undefined) {
            newForm[index].validation = validation;
        }
        if (enableValidation !== undefined) {
            newForm[index].enableValidation = enableValidation;
        }
        if (option !== undefined && !listKey) {
            let opt = newForm[index].options.find(e => e.id === optionId);
            opt.description = option
        }
        if (isAdd && !listKey) {
            newForm[index].options = [...newForm[index].options, {
                id: generateUUID()
            }]
        }
        if (isRemove && !listKey) {
            const relatedComponent = schema.find(e => e.relates && e.relates.targetState === optionId);
            if (relatedComponent) {
                setComponentToConfirmRemove({
                    component: relatedComponent,
                    handleRemove: () => removeOptionModal(index, optionId)
                });
            } else {
                newForm[index].options = newForm[index].options.filter(e => e.id !== optionId)
            }
        }
        if (listKey) {
            if (isRemove) {
                newForm[index][listKey] = newForm[index][listKey].filter((e, index) => index !== optionId)
            } else if (isAdd) {
                newForm[index][listKey] = [...newForm[index][listKey], {title: '', id: generateUUID()}]
            } else {
                newForm[index][listKey][optionId].title = option;
            }
        }
        if (files !== undefined) {
            newForm[index].attachments = files;
        }
        if (startFrom !== undefined) {
            newForm[index].startFrom = startFrom;
        }
        if (endAt !== undefined) {
            newForm[index].endAt = endAt;
        }
        if (startLabel !== undefined) {
            newForm[index].startLabel = startLabel;
        }
        if (endLabel !== undefined) {
            newForm[index].endLabel = endLabel;
        }
        setSchema(newForm);
    };

    const handleShowFieldWhen = (id, relatesTo, targetState) => {
        handleDialogClose();
        const newForm = JSON.parse(JSON.stringify(schema));
        let updatedComponent = newForm.find(e => e.id === id);

        if (relatesTo && targetState !== undefined) {
            updatedComponent.relates = {relatesTo, targetState};
        } else {
            delete updatedComponent.relates;
        }

        setSchema(newForm);
    };

    const handleChangeSubmit = ({id, value, optionIndex, optionValue, radioValue}) => {
        if (readOnly) return;

        const newSubmitData = {...submitData};
        if (value !== undefined) {
            newSubmitData[id] = value;

        } else if (optionIndex !== undefined && optionValue !== undefined) {
            if (!newSubmitData[id]) {
                newSubmitData[id] = [];
            }

            newSubmitData[id] = optionValue ? [...newSubmitData[id], optionIndex] : newSubmitData[id].filter(o => o !== optionIndex)
            if (newSubmitData[id].length === 0) {
                newSubmitData[id] = undefined;
            }
        } else if (radioValue !== undefined) {
            newSubmitData[id] = radioValue;
        }

        validate(id, value)

        setSubmitData((data) => ({...data, ...newSubmitData}));
    };

    const validate = (id, value) => {
        const field = schema.find((e) => e.id === id);
        if (field?.validation?.min > 0) {
            const newValidationErrors = {...validationErrors};
            newValidationErrors[id] = value.length < field.validation.min ? `Text must be at least ${field.validation.min} characters long` : undefined;
            setValidationErrors(newValidationErrors)
        }
    }

    const removeComponent = (position) => {
        let componentToRemove = schema.find((e, index) => index === position);
        const relatedComponents = schema.filter(e => e.relates && e.relates.relatesTo === componentToRemove.id);

        if (relatedComponents.length > 0) {
            setComponentToConfirmRemove({
                components: relatedComponents,
                handleRemove: () => removeComponentModal(componentToRemove.id)
            })
        } else {
            setSchema(schema.filter((e, index) => index !== position))
        }
    }

    const removeComponentModal = (id) => {
        const newForm = JSON.parse(JSON.stringify(schema));
        newForm.forEach(e => {
            if (e.relates && e.relates.relatesTo === id) {
                delete e.relates;
            }
        })
        setSchema(newForm.filter((e) => e.id !== id))
        setComponentToConfirmRemove(undefined)
    }

    const removeOptionModal = (index, optionId) => {
        const newForm = JSON.parse(JSON.stringify(schema));
        newForm.forEach(e => {
            if (e.relates && e.relates.targetState === optionId) {
                delete e.relates;
            }
        })
        newForm[index].options = newForm[index].options.filter(e => e.id !== optionId)
        setSchema(newForm);
        setComponentToConfirmRemove(undefined)
    }

    const moveComponent = (position, swapPosition) => {
        let newArray = [...schema];
        let temp = newArray[position];
        newArray[position] = newArray[swapPosition];
        newArray[swapPosition] = temp;
        setSchema(newArray);
    }

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        moveComponent(result.source.index, result.destination.index);
    };

    const addComponent = (type) => setSchema([...schema, {
        ...getComponentByType(type), enableValidation: type === 'signature', id: generateUUID()
    }]);

    const checkFailedValidation = (field) => {
        if (field.relates) {
            const relatedComponent = schema.find(e => field.relates.relatesTo === e.id);
            if (Array.isArray(submitData[relatedComponent.id])
                ? !submitData[relatedComponent.id].includes(field.relates.targetState)
                : submitData[relatedComponent.id] !== field.relates.targetState
            ) {
                return false;
            }
        }

        if (field.type === 'tickBoxGrid' || field.type === 'multipleChoiceGrid') {
            const data = submitData[field.id];
            if (!data) return true;

            for (let rowIndex = 0; rowIndex < field.rows.length; rowIndex++) {
                const key = field.rows[rowIndex];

                if (!data[key.id] || data[key.id].length === 0) {
                    return true;
                }
            }
            return false;
        }

        if (field.type === 'checkboxQuestion') {
            return !submitData[field.id]
        }

        return submitData[field.id] === undefined || submitData[field.id] === '';
    };

    const handleDialogClose = () => {
        setCurrentComponent(undefined);
    };

    const buildEditRows = (f, index) => {
        const relatedComponent = relatedFormQuestionAssignData?.find(e => e.relatedComponentId === f.id);
        return <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <Typography>{index + 1}. {f.label}</Typography>
                <Box display={'flex'} alignItems={'center'}>
                    {!isFormArchived &&
                        <Tooltip
                            title={relatedComponent
                                ? `This question cannot be deleted as it is currently used in the ${relatedComponent?.relatedFormName} form.`
                                : null}>
                            <IconButton>
                                <DeleteIcon
                                    onClick={() => relatedComponent ? {} : removeComponent(index)}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {!isFormArchived && relatedComponents.filter(c => c.id !== f.id).length > 0 &&
                        <IconButton>
                            <VisibilityIcon color={f.relates ? 'primary' : ''} onClick={() => setCurrentComponent(f)}/>
                        </IconButton>
                    }
                    {index !== 0 && !isFormArchived &&
                        <IconButton>
                            <ArrowUpwardOutlinedIcon onClick={() => moveComponent(index, index - 1)}/>
                        </IconButton>
                    }
                    {index !== schema.length - 1 && !isFormArchived &&
                        <IconButton>
                            <ArrowDownwardOutlinedIcon onClick={() => moveComponent(index, index + 1)}/>
                        </IconButton>
                    }
                </Box>
            </Box>
        </>
    }

    const checkIfVisible = (relates) => {
        if (relates) {
            const relatedComponent = readOnly ? data[relates.relatesTo] : submitData[relates.relatesTo];
            return Array.isArray(relatedComponent)
                ? relatedComponent.includes(relates.targetState)
                : relatedComponent === relates.targetState || (relates.targetState === false && relatedComponent === undefined)
        }
        return true;
    }
    return (<>
        <Box display={'flex'} justifyContent={'end'}>{!submitMode &&
            <Button onClick={() => setPreviewMode(!previewMode)} sx={{width: '100px'}}>
                {previewMode ? 'Edit' : 'Preview'}
            </Button>}
        </Box>
        <Box display={'flex'} fullWidth justifyContent={'center'}>
            <Paper variant={'outlined'}
                   sx={{flex: 2, p: 2, mx: submitMode ? 0 : previewMode ? '25%' : '20%', mb: 2, mt: submitMode ? 2 : 0}}
                   mx={previewMode ? '20%' : 0}>
                {!submitMode && !previewMode
                    ? <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {schema?.map((f, index) =>
                                        <Draggable key={index} draggableId={index.toString()}
                                                   index={index}
                                                   isDragDisabled={isFormArchived}>
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <Paper sx={{m: '0 20px 10px 20px', p: 3}} variant="outlined">
                                                        {buildEditRows(f, index)}
                                                        <ComponentBuilder handleChangeSubmit={handleChangeSubmit}
                                                                          index={index} readOnly={readOnly}
                                                                          handleChangeEdit={handleChangeEdit}
                                                                          previewMode={previewMode} field={f}
                                                                          data={data} submitMode={submitMode}
                                                                          submitData={submitData}
                                                                          isFormArchived={isFormArchived}
                                                                          validationErrors={validationErrors}
                                                                          accessLevel={accessLevel}
                                                                          relatedComponent={relatedFormQuestionAssignData
                                                                              ?.find(e => e.relatedComponentId === f.id)}/>
                                                    </Paper>
                                                </Box>)}
                                        </Draggable>)}
                                    {provided.placeholder}
                                </Box>)}
                        </Droppable>
                    </DragDropContext>
                    : schema?.filter(e => checkIfVisible(e.relates))
                        .map((f, index) => <Box key={`formItem-${index}`} px={2} py={1}>
                            <ComponentBuilder handleChangeSubmit={handleChangeSubmit}
                                              index={index} readOnly={readOnly}
                                              handleChangeEdit={handleChangeEdit}
                                              previewMode={previewMode} field={f}
                                              data={data} submitMode={submitMode}
                                              submitData={submitData} isFormArchived={isFormArchived}
                                              validationErrors={validationErrors}
                                              placeholderValues={placeholderValues}/>
                        </Box>)}
                {!readOnly && !previewMode && <Divider/>}
                <Box display={'flex'} alignItems={'center'} justifyContent={submitMode ? 'end' : 'start'} gap={1}
                     flexWrap={'wrap'} pt={1}>
                    {!submitMode && !previewMode
                        ? FORM_BUILDER_COMPONENTS.map((component, index) => <Button
                            disabled={isFormArchived}
                            key={`${component.type}-${index}`}
                            variant={'outlined'}
                            onClick={() => addComponent(component.type)}>
                            {component.description}
                        </Button>)
                        : readOnly || previewMode
                            ? null
                            : <Button onClick={() => handleSubmit(submitData, {})}
                                      disabled={schema.some(field => field.enableValidation && (checkFailedValidation(field) || validationErrors[field.id]))}>
                                Submit
                            </Button>}
                </Box>
            </Paper>
        </Box>
        <AdmicityDialog
            handleClose={handleDialogClose}
            title={'Show Field When'}
            actions={[]}
            open={Boolean(currentComponent)}
            maxWidth={'md'}
        >
            <ShowFieldWhen component={currentComponent} handleShowFieldWhen={handleShowFieldWhen}
                           availableComponents={relatedComponents.filter(c => c.id !== currentComponent?.id)}/>
        </AdmicityDialog>
        <ConfirmRemoveDialog
            open={Boolean(componentToConfirmRemove)}
            handleClose={() => setComponentToConfirmRemove(undefined)}
            handleConfirm={componentToConfirmRemove?.handleRemove}
            relatedComponents={componentToConfirmRemove?.components}/>
    </>);
};

FormBuilder.propTypes = {
    schema: PropTypes.array,
    setSchema: PropTypes.func,
    submitMode: PropTypes.bool,
    handleSubmit: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    accessLevel: PropTypes.number,
    placeholderValues: PropTypes.object,
    formId: PropTypes.number
};

export default FormBuilder;