import React from 'react';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import IconButton from '@mui/material/IconButton';

/* eslint-disable react/prop-types */
const ToggleColorPickerButton = React.forwardRef(({onClick, color}, ref) => (
    <IconButton onClick={onClick} ref={ref}>
        <FormatColorTextIcon fontSize={'small'} style={{color}}/>
    </IconButton>
));

ToggleColorPickerButton.displayName = 'ToggleColorPickerButton'
export default ToggleColorPickerButton;