import useUser from '../../../../utility/hooks/useUser';

const permissions = {
    hide: 0,
    view: 1,
    edit: 2,
};

const PermissionCheck = ({permissionModule, render}) => {
    const {user} = useUser();

    const hasPermission = () => {
        if (user.permissions.includes(`Permission.${permissionModule}.Edit`)) {
            return permissions.edit;
        } else if (user.permissions.includes(`Permission.${permissionModule}.View`)) {
            return permissions.view;
        }

        return permissions.hide;
    };

    return {
        [permissions.view]: () => render(true),
        [permissions.edit]: () => render(false),
        [permissions.hide]: () => null
    }[hasPermission()]();
};

export default PermissionCheck;