import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import PropTypes from 'prop-types';

const RedirectRoute = ({path}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(id ? `/${path}/${id}` : `/${path}`);
    }, [id, navigate]);

    return null;
};

RedirectRoute.propTypes = {
    path: PropTypes.string
};

export default RedirectRoute;