import {ROLES} from '../../constants/roles';
import {apiService} from '../apiService';

const getFormResponsesBase = {
    transformErrorResponse: (_) => ({message: 'Failed to retrieve form requests'}),
    transformResponse: (response) => ({
        ...response,
        items: response.items.map(({data, ...rest}) => ({
            data: JSON.parse(data),
            ...rest
        }))
    })
};

export const formRequestsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getFormRequests: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/requests`,
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getFormRequestsByParent: builder.query({
                query: ({pageNumber, pageSize}) => ({
                    url: '/parent/forms/requests',
                    params: {pageNumber, pageSize},
                }),
                ...getFormResponsesBase
            }),
            getFormRequest: builder.query({
                query: ({requestId, role}) => ({
                    url: role === ROLES.PARENT
                        ? `/parent/forms/requests/${requestId}`
                        : `/forms/requests/${requestId}`
                }),
                transformResponse: (response) => {
                    const {id, name, schema, data, status, schoolName, studentForename, studentFullName} = response;
                    return {
                        formId: id,
                        formName: name,
                        schema: schema ? JSON.parse(schema) : {},
                        data: data ? JSON.parse(data) : {},
                        placeholderValues: {schoolName, studentForename, studentFullName},
                        status
                    };
                },
                transformErrorResponse: (error) => {
                    const errorMessages = {
                        500: 'Internal server error.',
                        404: 'Could not find the requested form.'
                    };

                    return {
                        statusText: error.status,
                        message: errorMessages[error.status] ?? 'Something went wrong.'
                    };
                }
            }),
            submitForm: builder.mutation({
                query: (body) => ({
                    url: `/forms/${body.id}/submit`,
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: (error) =>
                    error.status === 409
                        ? 'Form already submitted. Please refresh the page to view the submitted information.'
                        : 'Failed to submit form.'
            }),
            submitPublicForm: builder.mutation({
                query: (body) => ({
                    url: '/forms/public/submit-form',
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: () => 'Failed to submit form.'
            }),
            getFormsByStudent: builder.query({
                query: (body) => ({
                    url: '/forms/requests',
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
        }),
});

export const {
    useGetFormRequestsQuery,
    useGetFormRequestsByParentQuery,
    useGetFormRequestQuery,
    useSubmitFormMutation,
    useSubmitPublicFormMutation,
    useGetFormsByStudentQuery,
} = formRequestsService;
