const APPLICANT_STATUSES = [
    {
        value: 0,
        label: 'All'
    },
    {
        value: 1,
        label: 'Completed'
    },
    {
        value: 2,
        label: 'Not Completed'
    },
    {
        value: 3,
        label: 'None of the parent accounts have been created'
    }
]

export default APPLICANT_STATUSES;