import React, {useEffect, useState} from 'react';
import {Backdrop, Box, Button, CircularProgress, Tab, Tabs} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {retrieveCurrentSchool, retrieveProfile} from './ProfilesSlice';
import Typography from '@mui/material/Typography';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {tabs} from './const/ProfileDetailTabs';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useUser from '../../../utility/hooks/useUser';
import {createSelector} from '@reduxjs/toolkit';
import {QueryStatus} from '@reduxjs/toolkit/query';
import {getRouteMatchPath} from '../../../utility/routeUtil';
import routes from '../../../routes/routes';
import {resetTableState} from '../../../store/tableSlice';

const selectQueries = state => state.api.queries;

const selectIsSomeQueryPending = createSelector(
    [selectQueries],
    (queries) => Object.values(queries).some(query => query.status === QueryStatus.pending)
);

const ProfileDetails = () => {
    const selectedProfile = useSelector(state => state.profilesInfo.selectedProfile);
    const updatingSuccessful = useSelector(state => state.profilesInfo.updatingSuccessful);
    const {studentId} = useParams();
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user} = useUser();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [profileTabs, setProfileTabs] = useState([]);
    const isSomeQueryPending = useSelector(selectIsSomeQueryPending);
    const {pathname} = useLocation();
    const path = getRouteMatchPath(routes, pathname);

    useEffect(() => {
        dispatch(retrieveCurrentSchool());
        setProfileTabs(tabs.filter(tab => tab.shouldRender(user.permissions, selectedProfile.isApplicant)));
        return () => {
            dispatch(resetTableState(path));
        };
    }, [dispatch]);

    useEffect(() => {
        if (profileTabs.length > 0) {
            const index = profileTabs.findIndex(tab => location.includes(tab.route));
            const tabIndex = index === -1 ? 0 : index;

            setCurrentTabIndex(tabIndex);
            navigate(profileTabs[tabIndex].route);
        }
    }, [profileTabs.length]);

    useEffect(() => {
        dispatch(retrieveProfile({studentId}));
    }, [updatingSuccessful]);

    const handleChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
        navigate(profileTabs[newValue].route);
    };

    const buildConfirmMessage = (profile) => {
        let needsConfirm = [];

        if (profile.confirmedAt === null) {
            needsConfirm.push('Basic');
        }
        if (profile.consentsConfirmedAt === null) {
            needsConfirm.push('Consents');
        }
        if (profile.contactsConfirmedAt === null) {
            needsConfirm.push('Contacts');
        }
        if (profile.culturalConfirmedAt === null) {
            needsConfirm.push('Cultural');
        }
        if (profile.medicalConfirmedAt === null) {
            needsConfirm.push('Medical');
        }
        if (profile.senConfirmedAt === null) {
            needsConfirm.push('Special Educational Needs');
        }
        if (profile.dietaryConfirmedAt === null) {
            needsConfirm.push('Dietary');
        }
        if (profile.freeSchoolMealsConfirmedAt === null) {
            needsConfirm.push('Free School Meals');
        }
        if (profile.welfareConfirmedAt === null) {
            needsConfirm.push('Welfare');
        }
        if (profile.travelModeConfirmedAt === null) {
            needsConfirm.push('Travel Mode')
        }

        return needsConfirm.length !== 0 && 'Details that still require confirmation: ' + needsConfirm.join(', ');
    };

    return (
        <>
            <Box>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate('/profiles')}
                >
                    Back
                </Button>
                <Typography variant="h4" py={2}>Personal Details
                    for {selectedProfile.name} {selectedProfile.surname}</Typography>
                <Typography variant="h7" color="red" py={2}>{buildConfirmMessage(selectedProfile)}</Typography>
            </Box>
            {
                user.permissions
                    ? <Tabs
                        value={currentTabIndex}
                        onChange={handleChange}
                        variant="scrollable"
                        textColor="inherit"
                    >
                        {
                            profileTabs.map((tab, index) => (
                                <Tab
                                    key={index}
                                    tabIndex={index}
                                    label={tab.label}
                                    component={Link}
                                    to={tab.route}
                                />
                            ))
                        }
                    </Tabs>
                    : ''
            }
            <Box
                position="relative"
                minHeight={40}
                marginTop={2}
                display="flex"
                flexGrow={1}
                flexDirection="column"
            >
                <Outlet context={{key: path}}/>
                <Backdrop
                    sx={{
                        position: 'absolute',
                        top: 50,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgb(250, 250, 251)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                    open={isSomeQueryPending}
                >
                    <CircularProgress size={40}/>
                </Backdrop>
            </Box>
        </>);
};

export default ProfileDetails;
