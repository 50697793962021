const FORM_ASSIGNMENT_TYPE = {
    group: 0,
    form: 1
};

const FORM_ASSIGNMENT_TYPE_NAMES = {
    [FORM_ASSIGNMENT_TYPE.group]: 'Group',
    [FORM_ASSIGNMENT_TYPE.form]: 'Form'
};

export {
    FORM_ASSIGNMENT_TYPE,
    FORM_ASSIGNMENT_TYPE_NAMES
};