import './App.css';
import {useRoutes} from 'react-router-dom';
import routes from './routes/routes';
import {useEffect} from 'react';
import {setupAxiosInterceptor} from './axios';
import useUser from './utility/hooks/useUser';

const App = () => {
    const {onRefresh} = useUser();

    useEffect(() => {
        setupAxiosInterceptor(onRefresh);
    }, [onRefresh]);

    return useRoutes(routes);
};

export default App;
