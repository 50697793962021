import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, CircularProgress, Container, Divider, styled, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {FileDownload, UploadFile} from '@mui/icons-material';
import {SEN_STATUSES} from '../const/SENStatus';
import DocumentList from '../Components/DocumentList';
import {downloadProfileEhcpDocument, downloadProfileSenDocument,} from '../../../../api/services/filesService';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import {useGetSenNeedsQuery, useManageSenNeedsMutation} from '../../../../api/services/studentService';
import {useDispatch, useSelector} from 'react-redux';
import {showSnackbar} from '../../../../AppLayout/ApplicationSlice';
import {retrieveProfile} from '../ProfilesSlice';
import ConfirmationWrapper from '../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../const/StudentProfileConfirmationTypes';
import PropTypes from 'prop-types';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import IconButton from '@mui/material/IconButton';
import Uploader from '../../../SchoolManagement/Setup/Uploader';

const SenFields = styled(Container)({
    display: 'flex', flexDirection: 'column', justifyContent: 'start'
});

const SenRow = styled(Box)({
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
});

const DownloadIcon = styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const SenAndDisabilitiesTab = ({readonly}) => {
    const [senRows, setSenRows] = useState([]);
    const [senDocumentRows, setSenDocumentRows] = useState([]);
    const [senInvestigating, setSenInvestigating] = useState({});
    const {studentId} = useParams();
    const [isAddMode, setIsAddMode] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [newDocuments, setNewDocuments] = useState([]);
    const [deletedDocuments, setDeletedDocuments] = useState([]);
    const [newFileSummary, setNewFileSummary] = useState('');
    const [senStatus, setSenStatus] = useState(undefined);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const [openValidationDialog, setOpenValidationDialog] = useState(false);
    const [ehcpFile, setEhcpFile] = useState(null);
    const [selectedEhcpFile, setSelectedEhcpFile] = useState([]);
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const {
        data: senNeeds = {options: []},
        isLoading: isSenNeedsLoading,
        refetch: refetchSenNeedsData
    } = useGetSenNeedsQuery(studentId);
    const [updateSenNeeds] = useManageSenNeedsMutation();
    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (!isSenNeedsLoading) {
            setSenRows(senNeeds.senNeedsData);
            setSenDocumentRows(senNeeds.senDocuments);
            setSenInvestigating(senNeeds.senNeedInvestigating);
            setSenStatus(Object.values(SEN_STATUSES).includes(senNeeds.senStatus) ? senNeeds.senStatus : SEN_STATUSES.K);
        }
    }, [senNeeds, senNeeds.senNeedsData]);

    const addFile = (file) => {
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);

        if (fileSizeInMB > 15) {
            dispatch(showSnackbar({message: 'The total size of the file exceeds 15 MB.', severity: 'error'}));
        } else {
            setNewFile(file);
            setIsAddMode(true);
        }
    };

    const addNewFile = () => {
        setSenDocumentRows([{name: newFile.name, summary: newFileSummary, isNew: true}, ...senDocumentRows]);
        setNewDocuments([{summary: newFileSummary, file: newFile}, ...newDocuments]);
        setNewFile(null);
        setIsAddMode(false);
        setNewFileSummary('');
    };

    const deleteDocument = (document) => {
        if (document.id) {
            setDeletedDocuments([document.id, ...deletedDocuments]);
            setSenDocumentRows(senDocumentRows.filter(d => d.id !== document.id));
        } else {
            setNewDocuments(newDocuments.filter(d => d.file.name !== document.name));
            setSenDocumentRows(senDocumentRows.filter(d => d.name !== document.name));
        }
    };

    const cancelCreating = () => {
        setNewFile(null);
        setIsAddMode(false);
        setNewFileSummary('');
    };

    const updateSen = async ({note, isActive, id}) => {
        let sens = JSON.parse(JSON.stringify(senRows));
        let updatedSen = sens.find(row => row.id === id);
        updatedSen.isEdited = true;
        if (updatedSen.senNeedData) {

            if (note !== undefined) {
                updatedSen.senNeedData.note = note;
            }
            if (isActive) {
                updatedSen.senNeedData.isActive = isActive;
            } else if (isActive !== undefined && !isActive) {
                if (senNeeds.senNeedsData.find(e => e.id === id).senNeedData) {
                    updatedSen.senNeedData.isActive = false;
                } else {
                    delete updatedSen.isEdited;
                    updatedSen.senNeedData = null;
                }
            }
        } else {
            updatedSen.senNeedData = {note, isActive: true};
        }
        setSenRows(sens);
    };

    const handleCheckInInvestigating = (checked) => {
        if (checked) {
            setSenInvestigating({note: '', isChecked: true, isNew: true});
        } else {
            setSenInvestigating({isChecked: false});
        }
    };

    const handleChangeInInvestigatingComment = (value) => {
        let updatedSenInvestigating = JSON.parse(JSON.stringify(senInvestigating));
        updatedSenInvestigating.note = value;
        updatedSenInvestigating.isEdited = true;
        setSenInvestigating(updatedSenInvestigating);
    };

    const documentsMap = [
        {
            documents: senDocumentRows,
            allowDelete: true,
            onDownload: async documentId => await downloadFile(downloadProfileSenDocument, {documentId, studentId}),
            onDelete: deleteDocument
        }
    ];

    const isNothingChanged = () => {
        const isSenRowsInitial = senRows.filter(row => row.isEdited).length === 0;
        const isSenStatusInitial = senStatus === senNeeds.senStatus;
        const isEhcpFileInitial = !ehcpFile;
        const isSenNeedInvestigatingInitial = senNeeds.senNeedInvestigating?.note === senInvestigating?.note ||
            senNeeds.senNeedInvestigating?.isChecked === senInvestigating?.isChecked;
        const isNewDocumentsClear = newDocuments.length === 0;
        const isDeletedDocumentsClear = deletedDocuments.length === 0;

        return isSenRowsInitial && isSenStatusInitial && isSenNeedInvestigatingInitial && isNewDocumentsClear && isDeletedDocumentsClear && isEhcpFileInitial;
    };

    const isFieldsSynced = () => senNeeds.senStatus || senNeeds.senNeedInvestigating.isChecked ||
        senNeeds.senNeedsData.filter(row => row.senNeedData !== null).length !== 0;

    const handleSave = async () => {
        if (senRows.some(row => !!row.senNeedData && row.senNeedData.isActive) && senDocumentRows.length === 0) {
            setOpenValidationDialog(true);
            return;
        }

        const updatedRows = senRows
            .filter(row => row.isEdited)
            .map(row => {
                return {
                    name: row.name,
                    senNeedId: row.id,
                    ...row.senNeedData
                };
            });

        const senNeedInvestigating = {
            isChecked: senInvestigating.isChecked,
            senInvestigatingId: senInvestigating.id,
            note: senInvestigating.note
        };

        await updateSenNeeds({
            studentId,
            senNeeds: updatedRows,
            senStatus,
            senNeedInvestigating,
            addedSenDocuments: newDocuments,
            deletedSenDocuments: deletedDocuments,
            ehcpFile
        }).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));

        setNewDocuments([]);
        setDeletedDocuments([]);
        setEhcpFile(null);
    };

    const isDisabledSenChecked = () => senRows?.some(senNeed => senNeed.isDisabled && senNeed.senNeedData && senNeed.senNeedData.isActive);

    const isSenNotSelected = () => senStatus === SEN_STATUSES.K && !senRows.some(e => e.senNeedData?.isActive) && !senInvestigating.isChecked;

    const isEhcpFileNotSelected = () => senStatus === SEN_STATUSES.E && ehcpFile === null;

    const buildSenInInvestigatingRow =
        <Box key={senInvestigating?.isChecked}>
            <SenRow>
                <Typography fontWeight="bold">Please tick this box your child has any suspected Special Educational
                    Needs or Disabilities that are in the process of being investigated/assessed and provide a short
                    summary</Typography>
                <Checkbox
                    checked={senInvestigating?.isChecked}
                    onChange={event => handleCheckInInvestigating(event.target.checked)}
                    disabled={readonly}
                />
            </SenRow>
            {senInvestigating.isChecked
                ? <Box>
                    <Typography variant="body2">Summary</Typography>
                    <TextField
                        multiline sx={{width: '100%'}} value={senInvestigating.note}
                        onChange={event =>
                            event.target.value.length < 255 && handleChangeInInvestigatingComment(event.target.value)}
                        disabled={readonly}
                    />
                    <Box py={1} display={'flex'} justifyContent={'end'}>
                    </Box>
                </Box>
                : <></>
            }
        </Box>;

    const buildSenRows = () =>
        senRows?.map((senNeed) => (!senNeed.isDisabled || (senNeed.isDisabled && senNeed.senNeedData.isActive))
            ? <>
                <Box key={senNeed.name}>
                    <SenRow>
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Typography>{senNeed.name}</Typography>
                            {senNeed.isDisabled &&
                                <Typography color={'error'} variant={'body2'}>This Special Education Needs option is
                                    no
                                    longer available. Kindly deselect it, please.</Typography>}
                        </Box>
                        <Checkbox
                            checked={!!senNeed.senNeedData && senNeed.senNeedData.isActive}
                            onChange={event => updateSen({
                                isActive: event.target.checked,
                                name: senNeed.name,
                                senNeedId: senNeed.id,
                                id: senNeed.id
                            })}
                            disabled={readonly}
                        />
                    </SenRow>
                    {!!senNeed.senNeedData && senNeed.senNeedData.isActive
                        ? <Box>
                            <Typography variant="body2">Description</Typography>
                            <TextField
                                multiline sx={{width: '100%'}} value={senNeed.senNeedData.note}
                                onChange={event =>
                                    event.target.value.length < 255 && updateSen({
                                        note: event.target.value,
                                        id: senNeed.id
                                    })}
                                disabled={readonly}
                            />

                        </Box>
                        : <></>
                    }
                </Box>
            </>
            : <></>
        );

    const addNewFileComponent = () => <>
        {!isAddMode && <Button component="label" variant="contained" startIcon={<UploadFile/>} style={{width: 200}}>
            Add document
            <input type="file" hidden onChange={event => addFile(event.target.files[0])}/>
        </Button>}
        {newFile && isAddMode &&
            <Box>
                <Typography fontWeight="bold">{newFile.name}</Typography>
                <Typography variant="body1" py={1}>Summary*</Typography>
                <TextField multiline sx={{width: '100%'}} value={newFileSummary}
                           onChange={(e) => setNewFileSummary(e.target.value)}/>
                <Box width={'100%'} display={'flex'} justifyContent={'end'} gap={1} py={1}>
                    <Button variant="outlined" onClick={() => cancelCreating()} style={{width: '100px'}}>
                        Cancel
                    </Button>
                    <Button disabled={!newFileSummary.trim()} variant="contained" onClick={() => addNewFile()}
                            style={{width: '100px'}}>
                        Add
                    </Button>
                </Box>

            </Box>
        }
        <Divider sx={{py: 1}}/>
    </>;

    const handleEhcpFileDelete = async () => {
        if (ehcpFile !== null) {
            setEhcpFile(null);
        }
    };

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedEhcpFile([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
    };

    const handleEhcpFileUpload = async () => {
        setIsUploading(true);
        setEhcpFile(selectedEhcpFile[0]);
        handleDialogClose();
    };

    const handleEhcpFileSelected = (file) => {
        if (file[0]) {
            setSelectedEhcpFile([file[0]]);
        }
    };

    const handleEhcpFileDeleteFromUploadContext = () => {
        setSelectedEhcpFile([]);
    };

    const buildEhcpUploadFile =
        <>
            <Box py={1} display={'flex'} justifyContent={'space-between'}>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                >
                    {
                        readonly
                            ? ''
                            : <Button
                                variant="contained"
                                startIcon={<UploadFile/>}
                                onClick={() => setOpenAddDocumentsDialog(true)}
                            >
                                Upload current EHCP
                            </Button>
                    }
                    {(ehcpFile || senNeeds.ehcpFile) &&
                        <>
                            <Typography>
                                {ehcpFile ? ehcpFile?.name : senNeeds.ehcpFile?.name}
                            </Typography>
                            {senNeeds.ehcpFile?.id && ehcpFile === null &&
                                <IconButton onClick={async () => await downloadFile(
                                    downloadProfileEhcpDocument,
                                    {
                                        documentId: senNeeds.ehcpFile?.id,
                                        studentId
                                    })
                                }>
                                    <DownloadIcon></DownloadIcon>
                                </IconButton>
                            }
                        </>
                    }
                </Box>
                {
                    readonly
                        ? ''
                        : <Button variant="outlined"
                                  onClick={() => handleEhcpFileDelete()}
                                  sx={{
                                      width: '100px',
                                      visibility: (!senNeeds.ehcpFile?.fileId && ehcpFile === null) ? 'hidden' : 'visible'
                                  }}
                        >
                            Delete
                        </Button>
                }
            </Box>
        </>;

    return <>
        {isSenNeedsLoading
            ? ''
            : <SenFields item xs={12} sm={6} px={3}>
                <ConfirmationWrapper
                    readonly={readonly}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the special education needs profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: senNeeds.confirmedAt,
                        confirmedBy: senNeeds.confirmedBy,
                        enableSaveButton: !(isNothingChanged() || isDisabledSenChecked() || isSenNotSelected() || isEhcpFileNotSelected()) ||
                            (newDocuments.length > 0 || deletedDocuments.length > 0) ||
                            (senDocumentRows?.length > 0 && !isNothingChanged()),
                        enableConfirmButton: isFieldsSynced() || senDocumentRows?.length > 0,
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES.Sen,
                        handleSave,
                        onConfirmSuccess: async () => await refetchSenNeedsData()
                    }}
                >
                    <Typography variant="h5" py={2}>Special Educational Needs</Typography>
                    <Box>
                        {senStatus !== SEN_STATUSES.E &&
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                                <Typography fontWeight="bold" variant={'body1'}>Please tick this box if your child has
                                    no
                                    Special Educational Needs or Disabilities that either you or his/her current school
                                    are
                                    aware of</Typography>
                                <Checkbox
                                    checked={senStatus === SEN_STATUSES.N}
                                    disabled={
                                        senStatus !== SEN_STATUSES.N &&
                                        (readonly ||
                                            (senInvestigating && senInvestigating.isChecked) ||
                                            senStatus === SEN_STATUSES.E ||
                                            (senStatus === SEN_STATUSES.K && senRows.some(row => row.senNeedData && row.senNeedData.isActive)))}
                                    onChange={e => setSenStatus(e.target.checked ? SEN_STATUSES.N : SEN_STATUSES.K)}
                                />
                            </Box>
                        }
                        {senStatus !== SEN_STATUSES.N &&
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                                <Typography fontWeight="bold" variant={'body1'}>My child has an Education, Health and
                                    Care Plan (EHCP)</Typography>
                                <Checkbox
                                    checked={senStatus === SEN_STATUSES.E}
                                    disabled={
                                        senStatus !== SEN_STATUSES.E &&
                                        (readonly ||
                                            (senInvestigating && senInvestigating.isChecked) ||
                                            senStatus === SEN_STATUSES.N ||
                                            (senStatus === SEN_STATUSES.K && senRows.some(row => row.senNeedData && row.senNeedData.isActive)))}
                                    onChange={e => setSenStatus(e.target.checked ? SEN_STATUSES.E : SEN_STATUSES.K)}
                                />
                            </Box>
                        }
                        {senStatus === SEN_STATUSES.E && buildEhcpUploadFile}
                        {senStatus === SEN_STATUSES.K && buildSenInInvestigatingRow}
                        {senStatus === SEN_STATUSES.K &&
                            <>
                                <Typography variant="body1" py={2}>
                                    If your child has Special Educational Needs or
                                    Disabilities that have been identified by his/her current school or diagnosed by an
                                    appropriately qualified professional, please let us know by choosing one of more
                                    options from the list below (tick all that apply). Please also provide a short
                                    summary of your child’s condition and use the upload button to submit any
                                    documentation relating to your child’s diagnosis or the support provided by his/her
                                    current school.
                                </Typography>
                                {buildSenRows()}
                                <Divider sx={{my: 2}}/>
                                <Typography variant="h5" sx={{pb: 2}}>SEN Documents</Typography>
                                {
                                    readonly
                                        ? ''
                                        : addNewFileComponent()
                                }
                                <DocumentList
                                    readonly={readonly}
                                    documents={documentsMap}
                                />
                            </>
                        }
                    </Box>
                    <AdmicityDialog
                        handleClose={() => setOpenValidationDialog(false)}
                        title={'SEN Document is not uploaded'}
                        open={openValidationDialog}
                        actions={[
                            {label: 'Ok', onClick: () => setOpenValidationDialog(false)},
                        ]}
                    >
                        Please upload SEN document before saving.
                    </AdmicityDialog>
                    <AdmicityDialog
                        handleClose={handleDialogClose}
                        title={'Add EHCP document'}
                        open={openAddDocumentsDialog}
                        actions={[
                            {label: 'Cancel', onClick: handleDialogClose},
                            {
                                label: 'Upload',
                                onClick: handleEhcpFileUpload,
                                disabled: isUploading || selectedEhcpFile?.length === 0 || isUploadSizeExceedsLimit,
                                startIcon: isUploading ? <CircularProgress color="inherit" size={16}/> : undefined
                            }
                        ]}
                        maxWidth={'md'}
                    >
                        <Uploader
                            totalUploadSizeInMb={25}
                            onFilesSelect={handleEhcpFileSelected}
                            onFileDelete={handleEhcpFileDeleteFromUploadContext}
                            onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                            value={selectedEhcpFile}
                            readonly={isUploading}
                            signleFile={true}
                        />
                    </AdmicityDialog>
                </ConfirmationWrapper>
            </SenFields>
        }
    </>;
};

SenAndDisabilitiesTab.propTypes = {
    readonly: PropTypes.bool
};
export default SenAndDisabilitiesTab;
