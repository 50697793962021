import React from 'react';
import {Box, Checkbox} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import EditMultiFieldsBuilder from './Shared/EditMultiFieldsBuilder';

const MultipleChoice = ({
                            index,
                            id,
                            value,
                            onChange,
                            description,
                            isEditMode,
                            enableValidation,
                            options,
                            isFormArchived,
                            relatedComponent
                        }) => {
    return (
        <Box>
            {isEditMode
                ? <EditMultiFieldsBuilder onChange={onChange} enableValidation={enableValidation}
                                          description={description} index={index} options={options}
                                          isFormArchived={isFormArchived} relatedComponent={relatedComponent}/>
                : <Box key={`${description}-${value}`}>
                    <Typography>{description}{enableValidation ? '*' : ''}</Typography>
                    {options.map((option) => <Box display={'flex'} gap={1}
                                                  key={`${description}-option-${option.id}`}
                                                  alignItems={'center'}>
                            <Checkbox value={value?.includes(option.id)} checked={value?.includes(option.id)}
                                      onChange={e => onChange({
                                          id,
                                          optionValue: e.target.checked,
                                          optionIndex: option.id
                                      })}/>
                            <Typography sx={{wordWrap: 'break-word', maxWidth: '800px'}}>{option.description}</Typography>
                        </Box>
                    )}
                </Box>
            }
        </Box>
    );
};

MultipleChoice.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    relatedComponent: PropTypes.object
};

export default MultipleChoice;