import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

export default function PublicJotForm() {
    const {formId} = useParams();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
        script.async = true;
        script.onload = () => {
            window.jotformEmbedHandler(`iframe[id='JotFormIFrame-${formId}']`, 'https://form.jotform.com/');
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [formId]);

    return (
        <iframe
            id={`JotFormIFrame-${formId}`}
            onLoad={() => window.parent.scrollTo(0, 0)}
            allow="geolocation; microphone; camera; fullscreen"
            src={`https://form.jotform.com/${formId}`}
            frameBorder="0"
            style={{minWidth: '100%', maxWidth: '100%', height: '539px', border: 'none'}}
            scrolling="no"
        />
    );
}
