import {apiService} from '../apiService';

export const groupService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getGroupFilters: builder.query({
                query: () => ({
                    url: '/group/filters'
                }),
            }),
        }),
});

export const {
    useGetGroupFiltersQuery
} = groupService;
