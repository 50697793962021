import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './store/store'
import {Provider} from 'react-redux'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {BrowserRouter} from 'react-router-dom';
import AppLayout from './AppLayout/AppLayout';
import {UserProvider} from './contexts/UserContext';
import { ConfirmProvider } from 'material-ui-confirm';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <BrowserRouter>
                    <UserProvider>
                        <ConfirmProvider>
                            <AppLayout>
                                <App/>
                            </AppLayout>
                        </ConfirmProvider>
                    </UserProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </Provider>
    </>
);
