import {apiService} from '../apiService';

export const formAssignmentsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getFormAssignments: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/assignments`,
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form assignments'}),
                providesTags: (_) => [{type: 'FormAssignments', id: 'LIST'}]
            }),
            createFormAssignment: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormAssignments', id: 'LIST'}],
            }),
            getFormQuestionAssignment: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}/question-assignment`,
                    method: 'GET'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form QUESTION assignments'}),
                providesTags: (_) => [{type: 'FormQuestionAssignments', id: 'LIST'}]
            }),

            getRelatedFormQuestionAssignment: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}/question-assignment/related`,
                    method: 'GET'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form QUESTION assignments'}),
                providesTags: (_) => [{type: 'FormQuestionAssignments', id: 'LIST'}]
            }),
            createFormQuestionAssignment: builder.mutation({
                query: (body) => ({
                    url: '/forms/question',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormQuestionAssignments', id: 'LIST'}],
            }),
            deleteFormQuestionAssignment: builder.mutation({
                query: (id) => ({
                    url: `/forms/question/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: [{type: 'FormQuestionAssignments', id: 'LIST'}],
            }),
            deleteFormAssignments: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment',
                    method: 'DELETE',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormAssignments', id: 'LIST'}],
            })
        }),
});

export const {
    useGetFormAssignmentsQuery,
    useCreateFormAssignmentMutation,
    useCreateFormQuestionAssignmentMutation,
    useDeleteFormQuestionAssignmentMutation,
    useGetFormQuestionAssignmentQuery,
    useGetRelatedFormQuestionAssignmentQuery,
    useDeleteFormAssignmentsMutation
} = formAssignmentsService;
