import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';

export default function SubmitPublicFormResult() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');

    return (
        <Container maxWidth="sm">
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                {error
                    ? <Box textAlign="center">
                        <ErrorIcon color="error" style={{fontSize: 80, marginBottom: 8}}/>
                        <Typography variant="h6" color="error">Submission Error</Typography>
                        <Typography color="error" variant="h6">{error}</Typography>
                        <Typography>Please try again or contact support if the problem persists.</Typography>
                    </Box>
                    : <Box textAlign="center">
                        <CheckCircleIcon color="success" style={{fontSize: 80, marginBottom: 8}}/>
                        <Typography variant="h6">Submission Successful</Typography>
                        <Typography>
                            Thank you for submitting the form. Your information has been successfully received and is
                            currently being processed.
                        </Typography>
                    </Box>
                }
            </Box>
        </Container>
    );
}
