import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useGetFormQuery} from '../../../../api/services/formsService';
import React, {useCallback, useMemo} from 'react';
import {FormBuilderComponentTypes} from '../FormBuilder/ComponentList';
import {Box, Button, Link, Stack, Typography} from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {getFormRequestStatusInfo} from '../../RequestAndResponses/common/common';
import {
    downloadFormDocument,
    downloadFormResponsesCsvReport,
    downloadFormResponsesXlsxReport
} from '../../../../api/services/filesService';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {useGetFormRequestsQuery} from '../../../../api/services/formRequestsService';
import {FORM_REQUEST_STATUSES, FORM_REQUEST_STATUSES_NAMES} from '../../../../constants/formRequestStatuses';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {formatDate} from '../../../../utility/dateUtil';
import {FORM_ACCESS_LEVELS} from '../../../../constants/formAccessLevels';
import DateRangePicker from '../../../../shared-components/Table/V2/components/columnFilters/DateRangePicker';
import FILTER_OPERATIONS from '../../../../constants/filterOperations';
import useTableState from '../../../../utility/hooks/useTableState';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
/* eslint-disable react/prop-types */

const extractDataFromComponentByType = (componentDefinition) =>
    ({
        [FormBuilderComponentTypes.SingleChoice]: (id) => [{
            [componentDefinition.id]: componentDefinition.options.find(x => x.id === id)?.description
        }],
        [FormBuilderComponentTypes.Dropdown]: (id) => [{
            [componentDefinition.id]: componentDefinition.options.find(x => x.id === id)?.description
        }],
        [FormBuilderComponentTypes.MultipleChoice]: (ids) =>
            ids
                ? componentDefinition.options
                    .filter(x => ids.includes(x.id))
                    .map(x => ({[x.id]: true}))
                : [],
        [FormBuilderComponentTypes.TickBoxGrid]: (values) =>
            values
                ? Object.entries(values).map(([rowId, selectedColumnIds]) => (
                    {
                        [rowId]: componentDefinition.columns
                            .filter(x => selectedColumnIds.includes(x.id))
                            .map(x => x.title)
                    }
                ))
                : [],
        [FormBuilderComponentTypes.MultipleChoiceGrid]: (values) =>
            values
                ? Object.entries(values).map(([rowId, selectedColumnId]) => ({
                    [rowId]: componentDefinition.columns
                        .find(x => x.id === selectedColumnId)
                        ?.title
                }))
                : [],
        [FormBuilderComponentTypes.Signature]: (value) => [{
            [componentDefinition.id]: value ? 'Signed' : ''
        }],
        [FormBuilderComponentTypes.Attachment]: () => [{
            [componentDefinition.id]: componentDefinition.attachments
        }],
    })[componentDefinition.type] ??
    function (value) {
        return [{[componentDefinition.id]: value}];
    };

const baseColumns = {
    privateFormColumns: [
        {
            accessorKey: 'formName',
            header: 'Form name',
            size: 180,
            enableColumnFilter: false
        },
        {
            accessorKey: 'student.forename',
            header: 'First name',
            size: 180
        },
        {
            accessorKey: 'student.surname',
            header: 'Last name',
            size: 180
        },
        {
            accessorKey: 'contacts',
            header: 'Assignee',
            size: 180,
            minSize: 250,
            maxSize: 500,
            enableColumnFilter: false,
            Cell: ({cell}) =>
                <Stack direction="column" gap={1}>
                    {
                        ((cell.getValue() ?? []).map((contact, index) => (
                                <Stack
                                    key={`assignee_${index}`}
                                    flexDirection="row"
                                    onClick={e => e.stopPropagation()}
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <AccountCircleRoundedIcon
                                        color="text.secondary"
                                        fontSize="large"
                                        sx={{
                                            color: 'text.disabled',
                                            marginRight: 1
                                        }}/>
                                    <Stack direction="column">
                                        <Typography
                                            variant="body1">{`${contact.forename} ${contact.surname}`}</Typography>
                                        <Typography variant="caption">{contact.email}</Typography>
                                    </Stack>
                                </Stack>
                            ))
                        )
                    }
                </Stack>,
        },
        {
            accessorKey: 'editedBy',
            header: 'Submitted By',
            enableColumnFilter: false,
            size: 180
        },
        {
            accessorKey: 'editedAt',
            header: 'Submitted At',
            Filter: DateRangePicker,
            filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 180,
            filterFn: FILTER_OPERATIONS.EQUALS,
            filterVariant: 'select',
            filterSelectOptions: Object.values(FORM_REQUEST_STATUSES).map(statusId => ({
                value: statusId,
                label: FORM_REQUEST_STATUSES_NAMES[statusId]
            })),
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>
        }
    ],
    publicFormColumns: [
        {
            accessorKey: 'formName',
            header: 'Form name',
            size: 180,
            enableColumnFilter: false
        },
        {
            accessorKey: 'editedAt',
            header: 'Submitted At',
            Filter: DateRangePicker,
            filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 180,
            filterFn: FILTER_OPERATIONS.EQUALS,
            filterVariant: 'select',
            filterSelectOptions: Object.values(FORM_REQUEST_STATUSES).map(statusId => ({
                value: statusId,
                label: FORM_REQUEST_STATUSES_NAMES[statusId]
            })),
            Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>
        }
    ]
};

const FormResponses = () => {
    const {key} = useOutletContext();
    const {
        pagination,
        filters,
        columnVisibility,
        onFiltersChange,
        onPaginationChange,
        onColumnVisibilityChange
    } = useTableState(key);
    const {id} = useParams();
    const {
        data: form = {},
        isLoading: isLoadingFormData
    } = useGetFormQuery(id);
    const {
        data: responses = {},
        isLoading: isLoadingFormResponses,
        isFetching: isFetchingFormResponses
    } = useGetFormRequestsQuery({
        formId: id,
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
        filters: filters.filters,
        responseFilters: filters.responseFilters,
    }, {skip: form.schema === undefined});
    const navigate = useNavigate();
    const downloadFile = useFileDownloader();

    const generateColumnPropsByComponentDefinition = useCallback((componentDefinition) =>
        ({
            [FormBuilderComponentTypes.CheckboxQuestion]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Cell: ({cell}) => cell.getValue() ? <CheckBoxRoundedIcon color="primary"/> : '',
                    filterVariant: 'select',
                    filterSelectOptions: [{value: true, label: 'Selected'}]
                }
            ],
            [FormBuilderComponentTypes.Dropdown]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.options.map(x => ({value: x.id, label: x.description}))
                }
            ],
            [FormBuilderComponentTypes.SingleChoice]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.options.map(x => ({value: x.id, label: x.description}))
                }
            ],
            [FormBuilderComponentTypes.MultipleChoice]: () => componentDefinition.options
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.description})`,
                    Cell: ({cell}) => cell.getValue() ? <CheckBoxRoundedIcon color="primary"/> : '',
                    filterVariant: 'select',
                    filterSelectOptions: [{value: x.id, label: 'Selected'}]
                })),
            [FormBuilderComponentTypes.TickBoxGrid]: () => componentDefinition.rows
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.title})`,
                    Cell: ({cell}) => cell.getValue()?.join('; '),
                    filterVariant: 'multi-select',
                    filterSelectOptions: componentDefinition.columns.map(c => ({value: c.id, label: c.title})),
                    filterFn: FILTER_OPERATIONS.MULTISELECT
                })),
            [FormBuilderComponentTypes.MultipleChoiceGrid]: () => componentDefinition.rows
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.title})`,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.columns.map(c => ({value: c.id, label: c.title}))
                })),
            [FormBuilderComponentTypes.LinearScale]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.description,
                filterVariant: 'range-slider',
                filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
                muiFilterSliderProps: {
                    marks: true,
                    min: componentDefinition.startFrom,
                    max: componentDefinition.endAt,
                    step: 1
                }
            }],
            [FormBuilderComponentTypes.Attachment]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.label,
                enableColumnFilter: false
            }],
            [FormBuilderComponentTypes.Signature]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.label,
                enableColumnFilter: false
            }],
            [FormBuilderComponentTypes.Attachment]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.label,
                    enableColumnFilter: false,
                    Cell: ({cell}) => <Stack direction="column">
                        {
                            cell.getValue()?.map(x => (
                                <Link
                                    key={x.name}
                                    component="button"
                                    variant="body2"
                                    onClick={async () => await downloadFile(downloadFormDocument, {documentId: x.id})}
                                >
                                    {x.name}
                                </Link>
                            ))
                        }
                    </Stack>
                }
            ],
        })[componentDefinition.type] ??
        function () {
            return [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.description,
                enableColumnFilter: false,
            }];
        }, []);

    const formSchemaComponentTypes = useMemo(() =>
        (form.schema?.reduce((components, x) => {
            if (x.type !== FormBuilderComponentTypes.AdditionalNotes) {
                components[x.id] = x;
            }
            return components;
        }, {})) ?? {}, [form.schema]);

    const handleColumnFiltersApply = (filters, filtersFns) => {
        const columnFilters = filters.reduce((acc, x) => {
                const operation = filtersFns[x.id];

                if (baseColumns.publicFormColumns.some(c => c.accessorKey === x.id) ||
                    baseColumns.privateFormColumns.some(c => c.accessorKey === x.id)) {
                    acc.filters.push({
                        propertyName: x.id,
                        value: x.value,
                        operation
                    });
                } else {
                    acc.responseFilters.push({
                        propertyName: x.id,
                        value: x.value,
                        jsonParentPaths: dynamicColumns.paths[x.id] ?? [],
                        operation,
                    });
                }

                return acc;
            }, {filters: [], responseFilters: []}
        );

        onFiltersChange(columnFilters);
    };

    const handleColumnVisibilityChange = value => {
        const result = Object.entries(value).reduce((acc, [accessorKey, hide]) => {
                if (baseColumns.publicFormColumns.some(c => c.accessorKey === accessorKey) ||
                    baseColumns.privateFormColumns.some(c => c.accessorKey === accessorKey)) {
                    acc.default[accessorKey] = hide;
                } else {
                    acc.dynamic[id][accessorKey] = hide;
                }

                return acc;
            },
            {
                default: {},
                dynamic: {
                    [id]: {}
                }
            }
        );

        onColumnVisibilityChange({...columnVisibility, ...result});
    };

    const dynamicColumns = useMemo(() => {
        const dynamicColumns = Object.values(formSchemaComponentTypes).reduce((acc, x) => {
            const columns = generateColumnPropsByComponentDefinition(x)();

            acc.columns.push(...columns);
            columns.forEach(c => {
                if (c.accessorKey !== x.id) {
                    acc.paths[c.accessorKey] = [x.id];
                }
            });

            return acc;
        }, {columns: [], paths: {}});

        return {
            columns: dynamicColumns.columns.length
                ? [
                    ...(
                        {
                            [FORM_ACCESS_LEVELS.private]: baseColumns.privateFormColumns,
                            [FORM_ACCESS_LEVELS.public]: baseColumns.publicFormColumns,
                        }[form.accessLevel] ?? []
                    ),
                    ...dynamicColumns.columns // Ensure you're spreading the columns array
                ]
                : [
                    ...(
                        {
                            [FORM_ACCESS_LEVELS.private]: baseColumns.privateFormColumns,
                            [FORM_ACCESS_LEVELS.public]: baseColumns.publicFormColumns,
                        }[form.accessLevel] ?? []
                    )
                ],
            paths: dynamicColumns.paths
        };
    }, [form.accessLevel, formSchemaComponentTypes]);

    const data = useMemo(() => responses?.items
        ? responses.items.map(({data, ...rest}) => ({
                ...rest,
                ...Object.assign({},
                    ...Object.entries(formSchemaComponentTypes)
                        .flatMap(([componentId, componentDefinition]) =>
                            data
                                ? extractDataFromComponentByType(componentDefinition)(data[componentId]).filter(x => x)
                                : [])
                )
            })
        )
        : [], [responses.items]);

    const downloadCsvReport = async () =>
        await downloadFile(
            downloadFormResponsesCsvReport,
            {
                formId: id,
                filters: filters.filters,
                responseFilters: filters.responseFilters,
            });

    const downloadXlsxReport = async () =>
        await downloadFile(
            downloadFormResponsesXlsxReport,
            {
                formId: id,
                filters: filters.filters,
                responseFilters: filters.responseFilters,
            });

    return (
        <>
            <Box sx={{marginBottom: 2}}>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate('/forms')}
                >
                    Back to forms
                </Button>
            </Box>
            <AdmicityTableV2
                columns={dynamicColumns.columns}
                data={data}
                initialState={{
                    columnFilters: Object.values(filters)
                        .filter(x => x)
                        .flatMap(x => x.map(f => ({id: f.propertyName, value: f.value})))
                }}
                isLoading={isLoadingFormResponses || isLoadingFormData}
                isRefetching={isFetchingFormResponses}
                totalItemCount={responses.totalCount}
                pagination={pagination}
                columnVisibility={{
                    ...columnVisibility.default,
                    ...(columnVisibility.dynamic?.[id]?.values ?? {})
                }}
                onPaginationModelChange={onPaginationChange}
                onColumnVisibilityChange={handleColumnVisibilityChange}
                onColumnFiltersApply={handleColumnFiltersApply}
                rowActions={[
                    {
                        label: 'View form request',
                        action: params => navigate(`${params.id}`)
                    }
                ]}
                toolbarIconActions={
                    [{
                        icon: <FileDownloadRoundedIcon/>,
                        title: 'Download report',
                        type: 'select',
                        hideIfEmpty: true,
                        menuItems: [
                            {
                                label: 'Download CSV',
                                onClick: downloadCsvReport
                            },
                            {
                                label: 'Download XLSX',
                                onClick: downloadXlsxReport
                            }
                        ]
                    }]
                }
                tableProps={{
                    enableSorting: false,
                    defaultColumn: {
                        size: 300,
                        maxSize: 1500,
                        minSize: 180,
                    }
                }}
            />
        </>
    );
};

export default FormResponses;