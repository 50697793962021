import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AdmicityGroupFilterSelect from '../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityTable from '../../../shared-components/AdmicityTable';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import {
    useCreateFormAssignmentMutation,
    useCreateFormQuestionAssignmentMutation,
    useDeleteFormAssignmentsMutation,
    useDeleteFormQuestionAssignmentMutation,
    useGetFormAssignmentsQuery,
    useGetFormQuestionAssignmentQuery
} from '../../../api/services/formAssignmentsService';
import withPagination from '../../../hoc/pagination/withPagination';
import {useGetGroupFiltersQuery} from '../../../api/services/groupService';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {showSnackbar} from '../../../AppLayout/ApplicationSlice';
import {useDispatch} from 'react-redux';
import {FORM_ASSIGNMENT_TYPE, FORM_ASSIGNMENT_TYPE_NAMES} from '../../../constants/FormAssignmentType';
import {useGetAssignmentFormsQuery, useLazyGetFormQuery} from '../../../api/services/formsService';
import {determinePossibleOperations} from './FormBuilder/ShowFieldWhen';
import {availableComponents} from './FormBuilder/FormBuilder';

const tableProps = {
    columns: [
        {
            field: 'displayName',
            headerName: 'Name',
            editable: false,
            sortable: false,
            flex: 2,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: ({value}) => value ? 'Active' : 'Inactive'
        }
    ]
};

const PrivateFormSettings = (
    {
        formId,
        isFormLive,
        strictRecipientsFiltering,
        onAddRecipient,
        onAddFormQuestionRecipient,
        onSetStrictRecipientsFiltering
    }) => {
    const [filterId, setFilterId] = useState('');
    const [assignmentType, setAssignmentType] = useState(FORM_ASSIGNMENT_TYPE.group);
    const [selectedForm, setSelectedForm] = useState(undefined);
    const [selectedFormSchema, setSelectedFormSchema] = useState(undefined);
    const [relatedComponent, setRelatedComponent] = useState(undefined);
    const [targetState, setTargetState] = useState(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const dispatch = useDispatch();
    const {data} = useGetGroupFiltersQuery();
    const {data: formQuestionAssignData} = useGetFormQuestionAssignmentQuery(formId);
    const {data: forms = []} = useGetAssignmentFormsQuery(formId);
    const [getForm] = useLazyGetFormQuery();

    const [createFormAssignment] = useCreateFormAssignmentMutation();
    const [createFormQuestionAssignment] = useCreateFormQuestionAssignmentMutation();

    const [deleteFormAssignments, {
        isSuccess: isDeleteFormAssignmentSuccess,
        isError: isDeleteFormAssignmentError
    }] = useDeleteFormAssignmentsMutation();
    const [deleteFormQuestionAssignments, {
        isError: isDeleteFormQuestionAssignmentError
    }] = useDeleteFormQuestionAssignmentMutation();

    const [selectedAssignmentIds, setSelectedAssignmentIds] = useState([]);

    useEffect(() => {
        return () => {
            setFilterId('');
        };
    }, []);

    useEffect(() => {
        if (formQuestionAssignData?.id) {
            getForm(formQuestionAssignData.relatedFormId)
                .then(({data: form}) => {
                    setAssignmentType(FORM_ASSIGNMENT_TYPE.form);
                    setSelectedForm(formQuestionAssignData.relatedFormId);
                    setSelectedFormSchema(form.schema);
                    setRelatedComponent(form.schema.find(e => e.id === formQuestionAssignData.relatedComponentId));
                    setTargetState(formQuestionAssignData.targetState);
                })
        } else {
            setSelectedFormSchema(undefined);
            setRelatedComponent(undefined);
            setTargetState(undefined);
        }
    }, [formQuestionAssignData]);

    useEffect(() => {
        if (isDeleteFormAssignmentError || isDeleteFormAssignmentSuccess || isDeleteFormQuestionAssignmentError) {
            if (isDeleteFormAssignmentError || isDeleteFormQuestionAssignmentError) {
                dispatch(showSnackbar({message: 'Failed to delete form assignment', severity: 'error'}));
            }
            if (isDeleteFormAssignmentSuccess) {
                setSelectedAssignmentIds([]);
            }
        }
    }, [isDeleteFormAssignmentSuccess, isDeleteFormAssignmentError, isDeleteFormQuestionAssignmentError]);

    const handleAddRecipient = async () => {
        await createFormAssignment({filterId, formId});
        setFilterId('');
    };

    const Table = withPagination(AdmicityTable,
        useGetFormAssignmentsQuery,
        {formId},
        {},
        (count) => {
            setFilterCount(count);
            if (onAddRecipient) {
                onAddRecipient(count);
            }
        });

    const handleSelectForm = (id) => {
        setSelectedFormSchema(undefined);
        setSelectedForm(id);
        getForm(id)
            .then(({data: form}) => {
                setSelectedFormSchema(form.schema);
            })
    }

    const buildDisplayName = () => {
        const formName = forms?.find(e => e.id === selectedForm);
        return `${formName?.name} - ${relatedComponent?.description}`
    }

    const questionAssignNotChanged = () => {
        return selectedForm === formQuestionAssignData?.relatedFormId &&
            relatedComponent?.id === formQuestionAssignData?.relatedComponentId &&
            targetState === formQuestionAssignData?.targetState
    }

    return (
        <>
            <FormControl
                required
                disabled={isFormLive}
                sx={{
                    flex: 1,
                    '&.MuiFormControl-root': {
                        padding: 2
                    }
                }}
            >
                <FormLabel>Assign to</FormLabel>
                <RadioGroup
                    row
                    key={assignmentType}
                    value={assignmentType}
                    onChange={e => setAssignmentType(parseInt(e.target.value))}>
                    {
                        Object.entries(FORM_ASSIGNMENT_TYPE_NAMES).map(([key, value]) => (
                            <FormControlLabel
                                key={`${key}-${assignmentType}`}
                                value={key}
                                control={<Radio size="small"/>}
                                label={value}
                                disabled={filterCount > 0 || formQuestionAssignData?.id}
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
            {assignmentType === FORM_ASSIGNMENT_TYPE.group
                ? <>
                    <Box padding={2}>
                        <Box
                            display="flex"
                            alignItems={'center'}
                            flexWrap={'wrap'}
                        >
                            <AdmicityGroupFilterSelect
                                sx={{
                                    marginRight: 2
                                }}
                                data={data?.modules}
                                value={filterId}
                                onChange={setFilterId}
                            />
                            <div>
                                <Button
                                    onClick={handleAddRecipient}
                                    variant="contained"
                                    disabled={!filterId}
                                >
                                    Add recipients
                                </Button>
                            </div>
                        </Box>
                        <FormControlLabel
                            sx={{
                                marginTop: 1
                            }}
                            control={<Checkbox
                                checked={strictRecipientsFiltering}
                                onChange={async e => await onSetStrictRecipientsFiltering(e.target.checked)}
                                disabled={isFormLive}
                            />}
                            label="Must appear in all these groups"
                        />

                    </Box>
                    <Table
                        columns={tableProps.columns}
                        checkboxSelection
                        rowSelectionModel={selectedAssignmentIds}
                        handleRowSelection={setSelectedAssignmentIds}
                        toolbarActions={
                            selectedAssignmentIds?.length > 0
                                ? [
                                    {
                                        type: 'button',
                                        title: 'Delete',
                                        icon: <DeleteForeverRoundedIcon/>,
                                        onClick: async () =>
                                            await deleteFormAssignments({
                                                formAssignmentIds: selectedAssignmentIds,
                                                formId
                                            })
                                    }
                                ]
                                : []
                        }
                        noRowsOverlay={{
                            icon: <PersonOffRoundedIcon/>,
                            text: 'No recipients has been added'
                        }}
                        sx={{
                            borderBottom: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderRadius: 0
                        }}
                    />
                </>
                : <Box padding={2}>
                    <Box
                        display="flex"
                        alignItems={'center'}
                        gap={2}
                    >
                        <TextField
                            sx={{width: '60%'}}
                            required
                            select
                            key={selectedForm}
                            value={selectedForm}
                            label="Form"
                            onChange={e => handleSelectForm(e.target.value)}>
                            {
                                forms?.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)
                            }
                        </TextField>
                        <Button
                            onClick={async () => {
                                await deleteFormQuestionAssignments(formQuestionAssignData.id);
                                onAddFormQuestionRecipient(false);
                                setSelectedForm(undefined);
                            }}
                            variant="outlined"
                            disabled={!formQuestionAssignData?.id}
                        >
                            Delete assign
                        </Button>
                        <Button
                            onClick={async () => {
                                await createFormQuestionAssignment({
                                    targetState: targetState.toString(),
                                    formId,
                                    relatedFormId: selectedForm,
                                    relatedComponentId: relatedComponent.id,
                                    displayName: buildDisplayName()
                                })
                                onAddFormQuestionRecipient(true)
                            }}
                            variant="contained"
                            disabled={!relatedComponent || targetState === undefined || questionAssignNotChanged()}
                        >
                            {formQuestionAssignData ? 'Update assign' : 'Save assign'}
                        </Button>
                    </Box>
                    <Divider sx={{pt: 3}}/>
                    <Box pt={3} display={'flex'} flexDirection={'column'} gap={3}>
                        <TextField
                            key={relatedComponent?.id}
                            sx={{width: '50%'}}
                            select
                            value={relatedComponent}
                            disabled={!selectedFormSchema}
                            label={'Question'}
                            required
                            onChange={e => {
                                setRelatedComponent(e.target.value);
                                setTargetState(undefined);
                            }}
                        >
                            {selectedFormSchema?.filter(option => availableComponents.includes(option.type))
                                .map(option =>
                                    <MenuItem key={option.id} value={option}>
                                        {option.label} - {option.description}
                                    </MenuItem>)}
                        </TextField>
                        <TextField
                            key={targetState}
                            sx={{width: '50%'}}
                            select
                            value={targetState}
                            label={'Equals'}
                            required
                            disabled={!relatedComponent}
                            onChange={e => setTargetState(e.target.value)}
                        >
                            {relatedComponent && determinePossibleOperations(relatedComponent)?.map(operation =>
                                <MenuItem key={operation.value} value={operation.value}>
                                    {operation.title}
                                </MenuItem>)}
                        </TextField>
                    </Box>
                </Box>
            }
        </>
    );
};

PrivateFormSettings.defaultProps = {
    isFormLive: false,
};

PrivateFormSettings.propTypes = {
    formId: PropTypes.number,
    isFormLive: PropTypes.bool,
    strictRecipientsFiltering: PropTypes.bool,
    onAddRecipient: PropTypes.func,
    onAddFormQuestionRecipient: PropTypes.func,
    onSetStrictRecipientsFiltering: PropTypes.func
};

export default PrivateFormSettings;
