export const formatDate = (date) => {
    const options = {year: 'numeric', month: 'short', day: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options);
}

export const formatDateTime = (date) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    return new Date(date).toLocaleString('en-US', options);
}

export const isDateExpired = dateToCheck => {
    const date = new Date(dateToCheck);
    const currentDate = new Date();

    return date < currentDate;
};
