import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from '@mui/material';
import PropTypes from 'prop-types';

const ConfirmRemoveDialog = ({handleClose, handleConfirm, open, relatedComponents}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}>
            <DialogTitle>
                Confirm that you want to remove this component
            </DialogTitle>
            <DialogContent>
                <DialogContentText pb={1}>
                    This component is associated with a display conditions:
                </DialogContentText>
                {relatedComponents?.map(c =>
                    <DialogContentText pb={1} key={c.description}>
                        {`${c.label} - ${c.description}`}
                    </DialogContentText>
                )}
                <DialogContentText>
                    Removing this component will also eliminate all associated relationships.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);
};

ConfirmRemoveDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    relatedComponents: PropTypes.array.isRequired
};

export default ConfirmRemoveDialog;