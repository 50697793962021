import {apiService} from '../apiService';

export const lookupsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getDietaryNeedOptions: builder.query({
                query: () => ({
                    url: '/lookup/special-dietary-needs'
                })
            }),
            getWelfareOptions: builder.query({
                query: () => ({
                    url: '/lookup/welfare'
                })
            }),
            getMedicalConsentOptions: builder.query({
                query: () => ({
                    url: '/lookup/medical-consents'
                }),
            }),
            getTravelOptions: builder.query({
                query: () => ({
                    url: '/lookup/travels'
                }),
            })
        }),
});

export const {
    useGetDietaryNeedOptionsQuery,
    useGetWelfareOptionsQuery,
    useGetMedicalConsentOptionsQuery,
    useGetTravelOptionsQuery
} = lookupsService;
