import React, {useState} from 'react';
import {Alert, AlertTitle, Stack, Step, StepLabel, Stepper, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import useTextField from '../../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../../utility/validationUtil';
import PropTypes from 'prop-types';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const MisIntegrationCreateTokenDialog = ({open, onTokenCreate, onClose}) => {
    const newTokenField = useTextField({
        initialValue: '',
        validate: (value) => validateFieldLength(value, 250)
    });
    const [activeStep, setActiveStep] = useState(0);
    const [tokenResponse, setTokenResponse] = useState({});

    const copyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(tokenResponse.token);
        } catch (e) {
        }
    };

    const handleDialogClose = () => {
        onClose();
        setActiveStep(0);
        setTokenResponse({});
        newTokenField.reset();
    };

    const handleTokenCreate = () =>
        onTokenCreate({name: newTokenField.value})
            .unwrap()
            .then(e => {
                setTokenResponse({token: e.accessToken});
                setActiveStep(1);
            })
            .catch(e => {
                setTokenResponse({token: '', error: `Failed to create a new token. Reason: ${e.error.detail}`});
                setActiveStep(1);
            });

    const steps = [
        {
            label: 'Set up token',
            component: <>
                <Typography
                    variant={'caption'}
                >Generate a new token for SIMS integration</Typography>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="Token Name"
                    fullWidth
                    variant="standard"
                    {...newTokenField}
                />
            </>,
            actions: [
                {label: 'Cancel', onClick: handleDialogClose},
                {
                    label: 'Save',
                    disabled: newTokenField.error || !newTokenField.value,
                    onClick: handleTokenCreate
                }
            ]
        },
        {
            label: 'Result',
            component: !tokenResponse.error
                ? <>
                    <Stack direction={'row'}>
                        <TextField
                            sx={{paddingRight: 1}}
                            autoFocus
                            margin="dense"
                            label="Token"
                            fullWidth
                            value={tokenResponse.token}
                        />
                        <Tooltip title={'Copy to clipboard'}>
                            <IconButton sx={{margin: 'auto'}} onClick={copyToClipBoard}>
                                <ContentCopyRoundedIcon/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Alert
                        sx={{marginTop: 2}}
                        severity="warning"
                    >
                        <AlertTitle>Warning</AlertTitle>
                        Please copy your token now. Be aware that this token is not saved in our system, making it
                        irretrievable in the future
                    </Alert>
                </>
                : <Alert
                    sx={{marginTop: 2}}
                    severity="error"
                >
                    <AlertTitle>Warning</AlertTitle>
                    {tokenResponse.error}
                </Alert>,
            actions: [{label: 'Cancel', onClick: handleDialogClose}]
        }
    ];

    return (
        <AdmicityDialog
            handleClose={handleDialogClose}
            title={'Create New Access Token'}
            open={open}
            actions={steps[activeStep].actions}
        >
            <Stepper
                sx={{
                    width: '100%',
                    paddingBottom: 1
                }}
                activeStep={activeStep}
            >
                {steps.map(({label}) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {
                steps[activeStep].component
            }
        </AdmicityDialog>
    );
};

MisIntegrationCreateTokenDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onTokenCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default MisIntegrationCreateTokenDialog;