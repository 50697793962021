import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import RichEditor from './Shared/RichEditor';
import DOMPurify from 'dompurify';
import {useSelector} from 'react-redux';
import {ROLES} from '../../../../../constants/roles';
import {getRole} from '../../../../../utility/jwtUtil';

const replaceIfNotNull = (originalText, placeholder, value) => {
    if (value !== null && value !== undefined) {
        const regex = new RegExp(placeholder, 'g');
        return originalText.replace(regex, value);
    }
    return originalText;
}

const AdditionalNotes = ({
                             index,
                             id,
                             description,
                             onChange,
                             isEditMode,
                             isFormArchived,
                             placeholderValues,
                             readOnly,
                             accessLevel
                         }) => {
    const {account} = useSelector(state => state.application);
    const [text, setText] = useState('');

    useEffect(() => {
        let resultText = description;

        const placeholders = readOnly
            ? placeholderValues
            : {parent: `${account.firstName} ${account.lastName}`, ...placeholderValues}

        if ((getRole() !== ROLES.SCHOOL_ADMIN && getRole() !== ROLES.MEMBER_OF_STAFF) || readOnly) {
            resultText = description;
            resultText = replaceIfNotNull(resultText, '@Parent', placeholders?.parent);
            resultText = replaceIfNotNull(resultText, '@SchoolName', placeholders?.schoolName);
            resultText = replaceIfNotNull(resultText, '@StudentForename', placeholders?.studentForename);
            resultText = replaceIfNotNull(resultText, '@StudentFullName', placeholders?.studentFullName);

            onChange({id, value: placeholders})
        }
        setText(DOMPurify.sanitize(resultText));
    }, [account]);

    return (
        <Box>
            {isEditMode
                ? <Box sx={{maxWidth: '52vw', cursor: 'pointer'}}>
                    <RichEditor value={description} onChange={onChange} id={id} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel}/>
                </Box>
                : <div dangerouslySetInnerHTML={{__html: text}}/>
            }
        </Box>
    );
};

AdditionalNotes.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    accessLevel: PropTypes.int,
    placeholderValues: PropTypes.object
};

export default AdditionalNotes;