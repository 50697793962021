import React, {useEffect, useState} from 'react';
import {TreeItem, TreeView, useTreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

const mapDataToTree = items => {
    const root = [];
    const stack = [{children: root, level: 0, path: []}];
    const paths = {};
    const moduleItems = items.flatMap(({filters, ...module}) => {
        const _filters = filters.map(filter => ({...filter, parentId: module.id}));
        return [{...module, parentId: null}, ..._filters];
    });

    for (const item of moduleItems) {
        const node = {...item, children: [], path: []};

        while (stack[stack.length - 1].level >= item.level) {
            stack.pop();
        }

        const parent = stack[stack.length - 1];
        node.path = [...parent.path, {name: item.name, selectable: item.selectable}];
        paths[item.id] = node.path;

        parent.children.push(node);
        stack.push(node);
    }
    return {
        tree: root,
        paths
    };
};

/* eslint-disable */
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        isSelectable = true
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        if (isSelectable) {
            handleSelection(event);
        }
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected && isSelectable,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            <div onClick={handleExpansionClick} className={classes.iconContainer}>{icon}</div>
            <Typography
                onClick={handleSelectionClick}
                className={classes.label}
                sx={{
                    paddingTop: 1,
                    paddingBottom: 1
                }}
            >
                {label}
            </Typography>
        </div>
    );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const {isSelectable, ...otherProps} = props;
    return <TreeItem
        ContentComponent={(props) => <CustomContent {...props} isSelectable={isSelectable}/>}
        {...otherProps}
        ref={ref}/>;
});
/* eslint-disable */

const AdmicityGroupFilterTree = ({data, selected, sx, onChange, expandMinLevel = 0}) => {
    const {tree, paths} = data ? mapDataToTree(data) : {tree: undefined, paths: undefined};
    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        const expandedNodes = expandMinLevel && data
            ? (
                expandMinLevel === 1
                    ? data.map(f => f.id)
                    : data.reduce((ids, module) => {
                        ids = [module.id, ...ids, ...module.filters.filter(f => f.level <= expandMinLevel).map(f => f.id)];
                        return ids;
                    }, [])
            )
            : [];
        setExpanded(expandedNodes);
    }, [data]);

    const renderTreeNode = (node) =>
        node
            ? <CustomTreeItem
                key={node.id}
                nodeId={node.id}
                label={node.name}
                onClick={(event) => event.stopPropagation()}
                isSelectable={node.selectable}
            >
                {Array.isArray(node.children)
                    ? node.children.sort((a,b) =>
                    {
                        if (a.name.startsWith("Year ") && b.name.startsWith("Year ")) {
                            const numA = parseInt(a.name.match(/\d+/)[0]);
                            const numB = parseInt(b.name.match(/\d+/)[0]);
                            return numA - numB;
                        } else {
                            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
                        }
                    }).map((node) => renderTreeNode(node))
                    : null}
            </CustomTreeItem>
            : '';

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeId) => {
        onChange(nodeId, paths[nodeId]);
    };

    return (
        <TreeView
            key={selected}
            sx={{...sx}}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            expanded={expanded}
            selected={selected}
        >
            {tree?.map(node => renderTreeNode(node))}
        </TreeView>
    );
};

AdmicityGroupFilterTree.defaultProps = {
    sx: {}
};

AdmicityGroupFilterTree.propTypes = {
    data: PropTypes.array,
    selected: PropTypes.string,
    sx: PropTypes.object,
    onChange: PropTypes.func,
    expandMinLevel: PropTypes.number
};

export default AdmicityGroupFilterTree;