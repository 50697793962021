import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showSnackbar} from '../../AppLayout/ApplicationSlice';
import {setPaginationModel} from './paginationSlice';

const withPagination = (
    Component,
    fetchFunction,
    fetchFunctionExtraProps = {},
    fetchFunctionSettings = {},
    onDataFetch = () => {}
) => {
    function TableHOC(props) {
        const dispatch = useDispatch();
        const paginationModel = useSelector(state => state.pagination.paginationModel);
        const {data, isLoading, isFetching, isError, error} = fetchFunction({
            ...paginationModel,
            ...fetchFunctionExtraProps
        }, fetchFunctionSettings);

        useEffect(() => {
            if (isError) {
                dispatch(showSnackbar({message: error?.message ?? '', severity: 'error'}));
            }
            if (data?.items) {
                onDataFetch(data.items.length)
            }
        }, [data, isError]);

        const handlePaginationModelChange = (pageNumber, pageSize) => {
            const model = {pageNumber, pageSize};

            dispatch(setPaginationModel(model));
        };

        return (
            <Component
                {...props}
                isLoading={isLoading || isFetching}
                data={data?.items ?? []}
                totalItemCount={data?.totalCount ?? 0}
                pageSize={paginationModel.pageSize}
                pageNumber={paginationModel.pageNumber}
                handlePaginationModelChange={handlePaginationModelChange}
            />
        );
    }

    return TableHOC;
};

export default withPagination;
