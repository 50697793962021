import React, { useState, useEffect } from 'react';
import {CompactPicker} from 'react-color';
import { Box } from '@mui/material';

/* eslint-disable react/prop-types */
const ColorPicker = ({ applyColorCommand, onClose, anchorEl }) => {
    const [color, setColor] = useState('#000000');
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        if (anchorEl) {
            const rect = anchorEl.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            });
        }
    }, [anchorEl]);

    const handleColorChange = (color) => {
        setColor(color.hex);
        applyColorCommand(color.hex);
    };

    return (
        <Box
            style={{
                position: 'absolute',
                display: 'inline-block',
                top: `${position.top}px`,
                left: `${position.left}px`,
                zIndex: 1000
            }}
            onMouseLeave={onClose}
        >
            <CompactPicker color={color} onChangeComplete={handleColorChange} />
        </Box>
    );
};

export default ColorPicker;