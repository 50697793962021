const FORM_REQUEST_STATUSES = {
    created: 1,
    sent: 2,
    failedToSend: 3,
    submitted: 4
};

const FORM_REQUEST_STATUSES_NAMES = {
    [FORM_REQUEST_STATUSES.created]: 'Created',
    [FORM_REQUEST_STATUSES.sent]: 'Waiting for submission',
    [FORM_REQUEST_STATUSES.failedToSend]: 'Failed To Send',
    [FORM_REQUEST_STATUSES.submitted]: 'Submitted'
};

export {
    FORM_REQUEST_STATUSES,
    FORM_REQUEST_STATUSES_NAMES
};