import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../axios';
import NOTIFICATION_TYPE from '../constants/notificationTypes';
import {ROLES} from '../constants/roles';

const initialState = {
    notification: {
        type: NOTIFICATION_TYPE.STANDARD,
        open: false,
        severity: 'info',
        duration: 3000,
        snackbar: {
            message: ''
        },
        detailedSnackbar: {
            title: '',
            messages: []
        }
    },
    account: {
        firstName: '',
        lastName: '',
        schoolName: '',
        role: ROLES.NONE
    },
    breadcrumbs: undefined
};

export const logout = createAsyncThunk(
    'logout',
    async (thunkAPI) => {
        try {
            await api.post('/account/revoke');
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const getUser = createAsyncThunk(
    'getUser',
    async (thunkAPI) => {
        try {
            return (await api.get('/account')).data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        showSnackbar: (state, action) => {
            state.notification = {
                ...state.notification,
                type: NOTIFICATION_TYPE.STANDARD,
                open: true,
                severity: action.payload.severity,
                snackbar: {...state.notification.snackbar, message: action.payload.message}
            }
        },
        showDetailedSnackbar: (state, action) => {
            state.notification = {
                ...state.notification,
                type: NOTIFICATION_TYPE.DETAILED,
                open: true,
                severity: action.payload.severity,
                detailedSnackbar: {
                    ...state.notification.detailedSnackbar,
                    title: action.payload.title,
                    messages: action.payload.messages
                }
            }
        },
        hideSnackbar: (state) => {
            state.notification.open = false;
        },
        resetBreadcrumbs: state => {
            state.breadcrumbs = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logout.fulfilled, (state) => {
                state.account = initialState.account;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.account = {...state, ...action.payload};
            });
    }
});

export const {
    showSnackbar,
    hideSnackbar,
    showDetailedSnackbar,
    resetBreadcrumbs
} = applicationSlice.actions;

export default applicationSlice.reducer;
